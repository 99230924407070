import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static required = true
  static type = 'number'
}

// MARK: - Class

export default class TextFieldNumberViewModel extends TextFieldViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {string} */
  _model
  /** @type {string} */
  _requiredKey
  /** @type {string} */
  _exactLengthKey

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   * @param {string} model
   * @param {string} labelKey
   * @param {string} requiredKey
   * @param {string} lengthKey
   * @param {string} type
   */
  constructor (localization, validation, model, labelKey, requiredKey, lengthKey, type = Constants.type) {
    super(localization.getText(labelKey),
      Constants.required,
      type
    )

    this._localization = localization
    this._validation = validation
    this._model = model
    this._requiredKey = requiredKey
    this._exactLengthKey = lengthKey
  }

  // MARK: - Getter

  /**
   * @param {string} model
   * @param {int} exactLength
   * @return {array}
   */
  static validation (model, exactLength) {
    return {
      [model]: {
        required,
        minLength: minLength(exactLength),
        maxLength: maxLength(exactLength),
        numeric
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation[this._model].$dirty) return errors
    !this._validation[this._model].required && errors.push(this._localization.getText(this._requiredKey))
    !this._validation[this._model].minLength && errors.push(this._localization.getText(this._exactLengthKey))
    !this._validation[this._model].maxLength && errors.push(this._localization.getText(this._exactLengthKey))
    !this._validation[this._model].numeric && errors.push(this._localization.getText(this._exactLengthKey))
    return errors
  }
}
