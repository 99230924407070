import DashboardViewModel from '@/presentation/scene/dashboard/view-model/dashboardViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import ContentPresenter from '@/presentation/utils/contentPresenter'

export default class DashboardPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {DashboardViewModel} */
  _viewModel

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    super()
    this._localization = new Localization(vuetify)
    this._viewModel = new DashboardViewModel(this._localization)
  }

  // MARK: - Getter

  /** @return {DashboardViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.subscriptionExpireSoon.showLoader = value
    this._viewModel.informations.showLoader = value
    this._viewModel.reservations.showLoader = value
    this._viewModel.subscriptions.showLoader = value
  }

  /** @param {boolean} value */
  set showRefreshFromInteractor (value) {
    this._viewModel.showRefresh = value
  }

  /** @param {DashboardDomainModel} value */
  set dashboardFromInteractor (value) {
    const showAskPushRegistration = value.askPushRegistration
    const showSubscriptionExpireSoon = value.subscriptionExpiresSoon
    const showSubscriptionExpired = !value.validSubscriptionEnding
    const showTopInformation = showAskPushRegistration || showSubscriptionExpireSoon || showSubscriptionExpired
    this._viewModel.showAskPushRegistration = showAskPushRegistration
    this._viewModel.showSubscriptionExpireSoon = showSubscriptionExpireSoon
    this._viewModel.showSubscriptionExpired = showSubscriptionExpired

    let showInformations = false
    if (value.informations) {
      showInformations = (value.informations.length > 0)
    }
    this._viewModel.showInformations = showInformations
    this._viewModel.informations.title.showTopSpace = showTopInformation
    this._viewModel.informations.setValues(value)

    this._viewModel.reservations.setValues(value)
    this._viewModel.reservations.title.showTopSpace = showTopInformation || showInformations

    this._viewModel.subscriptions.setValues(value)
  }

  /** @param {boolean} value */
  set showNotificationRegistrationFromInteractor (value) {
    this._viewModel.showAskPushRegistration = value
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.error = presentationError.toInformation
  }
}
