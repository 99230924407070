<template>
  <div>
    <!-- App Bar -->
    <custom-app-bar :title="presenter.viewModel.title"/>

    <!-- Form -->
    <form>
      <!-- Current password text field text field -->
      <custom-text-field
        :view-model="presenter.viewModel.currentPasswordTextField"
        :vuelidate="$v.password"
      />

      <!-- Current password text field space -->
      <custom-space :view-model="presenter.viewModel.currentPasswordBottomSpace"/>

      <!-- New password text field -->
      <custom-text-field
        :view-model="presenter.viewModel.newPasswordTextField"
        :vuelidate="$v.newPassword"
      />

      <!-- New password space -->
      <custom-space :view-model="presenter.viewModel.newPasswordBottomSpace"/>

      <!-- Confirm password text field -->
      <custom-text-field
        :view-model="presenter.viewModel.confirmPasswordTextField"
        :vuelidate="$v.confirmPassword"
      />

      <!-- Confirm password space -->
      <custom-space :view-model="presenter.viewModel.confirmPasswordBottomSpace"/>

      <!-- Save button -->
      <custom-button
        :view-model="presenter.viewModel.saveButton"
        @click="controller.saveTapped()"
      />
    </form>

    <!-- Save space -->
    <custom-space :view-model="presenter.viewModel.saveBottomSpace"/>

    <!-- Back button -->
    <custom-button
      :view-model="presenter.viewModel.backButton"
      @click="controller.backTapped()"
    />

    <!-- Loader -->
    <custom-loader :show="presenter.viewModel.showLoader"/>

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.error"/>

  </div>
</template>

<script>
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import TextFieldComponent from '@/app/common/component/textfield/TextFieldComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LoaderComponent from '@/app/common/component/loader/LoaderComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import PresentationInjection from '@/presentation/scene/password-update/passwordUpdatePresentationInjection'
import Presenter from '@/presentation/scene/password-update/passwordUpdatePresenter'

export default {
  name: 'PasswordUpdateVue',

  data: () => ({
    /** @type PasswordUpdateController */
    controller: null,
    /** @type PasswordUpdatePresenter */
    presenter: null,

    // TextFields (NOTE: Same names are used on TextFieldViewModel, Presenter and Interactor)
    password: null,
    newPassword: null,
    confirmPassword: null
  }),

  components: {
    customAppBar: AppBarComponent,
    customTextField: TextFieldComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent,
    customLoader: LoaderComponent,
    customAlert: AlertComponent
  },

  validations: Presenter.textFieldsValidations,

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$v, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>
