import ContainerViewModel from '@/presentation/view-model/container/containerViewModel'

// MARK: - Constants

class Constants {
  static fluid = true
  static classes = 'pa-0'
}

// MARK: - Class

export default class ListContainerViewModel extends ContainerViewModel {
  // MARK: - Constructor

  constructor () {
    super(Constants.fluid,
      Constants.classes)
  }
}
