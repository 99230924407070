// MARK: - Constants

const TINY = 0
const LITTLE = 1
const CLASSIC = 2
const BIG = 3

class Constants {
  static tiny = 'tiny-space'
  static little = 'little-space'
  static classic = 'space'
  static big = 'big-space'
}

// MARK: - Enum

export default {
  TINY,
  LITTLE,
  CLASSIC,
  BIG,

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  classesKey (value) {
    switch (value) {
      case TINY:
        return Constants.tiny
      case LITTLE:
        return Constants.little
      case BIG:
        return Constants.big
      default:
        return Constants.classic
    }
  }
}
