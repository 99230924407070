import PresentationCard from '@/presentation/common/enum/presentationCard'

// MARK: - Constants

const NORMAL = 0
const IMPORTANT = 1
const VERY_IMPORTANT = 2

// MARK: - Enum

export default {
  NORMAL,
  IMPORTANT,
  VERY_IMPORTANT,

  /**
   * @param {int} value corresponding to this enum
   * @return {int} corresponding to PresentationCard
   */
  toCard (value) {
    switch (value) {
      case IMPORTANT:
        return PresentationCard.YELLOW
      case VERY_IMPORTANT:
        return PresentationCard.RED
      default:
        return PresentationCard.DEFAULT
    }
  }
}
