import ProfileAPIBody from '@/data/api/body/profileAPIBody'
import Date from '@/data/utils/date'

export default class ProfileDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _name
  /** @type {string} */
  _lastname
  /** @type {string} */
  _email
  /** @type {string} */
  _birthday
  /** @type {string} */
  _phone
  /** @type {boolean} */
  _subscribe

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} name
   * @param {string} lastname
   * @param {string} email
   * @param {moment.Moment} birthday
   * @param {string} phone
   * @param {boolean} subscribe
   */
  constructor (name, lastname, email, birthday, phone, subscribe) {
    this._name = name
    this._lastname = lastname
    this._email = email
    this._birthday = Date.stringForApi(birthday)
    this._phone = phone
    this._subscribe = subscribe
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {ProfileAPIBody}
   */
  get toAPIBody () {
    return new ProfileAPIBody(this._name, this._lastname, this._email, this._birthday, this._phone, this._subscribe)
  }
}
