import LogoutDomainInjection from '@/domain/scene/logout/logoutDomainInjection'
import LogoutController from '@/presentation/scene/logout/logoutController'
import LogoutPresenter from '@/presentation/scene/logout/logoutPresenter'

export default class LogoutPresentationInjection {
  // MARK: - Properties

  /** @type {LogoutController} */
  _controller
  /** @type {LogoutPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {VueRouter} router
   */
  constructor (vuetify, router) {
    this._presenter = new LogoutPresenter(vuetify, router)

    // Domain
    const domainInjection = new LogoutDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new LogoutController(interactor)
  }

  // MARK: - Getter

  /** @return {LogoutController} */
  get controller () {
    return this._controller
  }

  /** @return {LogoutPresenter} */
  get presenter () {
    return this._presenter
  }
}
