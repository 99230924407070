import FormLoaderViewModel from '@/presentation/view-model/form/formLoaderViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static color = Color.main
  static showLoader = false
}

// MARK: - Class

export default class FormViewModel {
  // MARK: - Properties

  /** @type {string} */
  _label
  /** @type {string} */
  _color
  /** @type {boolean} */
  _showLoader
  /** @type {FormLoaderViewModel} */
  _loader

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} label
   * @param {string} color
   * @param {boolean} showLoader
   * @param {FormLoaderViewModel} loader
   */
  constructor (label,
    color = Constants.color,
    showLoader = Constants.showLoader,
    loader = new FormLoaderViewModel()) {
    this._label = label
    this._color = color
    this._showLoader = showLoader
    this._loader = loader
  }

  // MARK: - Getter

  /** @return {string} */
  get label () {
    return this._label
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {boolean} */
  get showForm () {
    return !this._showLoader
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {FormLoaderViewModel} */
  get loader () {
    return this._loader
  }

  // MARK: - Setter

  /** @param {string} value */
  set label (value) {
    this._label = value
  }

  /** @param {string} value */
  set color (value) {
    this._color = value
  }

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }
}
