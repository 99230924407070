<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M2,2 L2,9 L9,9 L9,2 L2,2 Z M1.22222222,0 L9.77777778,0 C10.4527925,0 11,0.547207528 11,1.22222222 L11,9.77777778 C11,10.4527925 10.4527925,11 9.77777778,11 L1.22222222,11 C0.547207528,11 0,10.4527925 0,9.77777778 L0,1.22222222 C0,0.547207528 0.547207528,0 1.22222222,0 Z M14.2222222,0 L22.7777778,0 C23.4527925,0 24,0.547207528 24,1.22222222 L24,9.77777778 C24,10.4527925 23.4527925,11 22.7777778,11 L14.2222222,11 C13.5472075,11 13,10.4527925 13,9.77777778 L13,1.22222222 C13,0.547207528 13.5472075,0 14.2222222,0 Z M15,9 L22,9 L22,2 L15,2 L15,9 Z M14.2222222,13 L22.7777778,13 C23.4527925,13 24,13.5472075 24,14.2222222 L24,22.7777778 C24,23.4527925 23.4527925,24 22.7777778,24 L14.2222222,24 C13.5472075,24 13,23.4527925 13,22.7777778 L13,14.2222222 C13,13.5472075 13.5472075,13 14.2222222,13 Z M15,22 L22,22 L22,15 L15,15 L15,22 Z M1.22222222,13 L9.77777778,13 C10.4527925,13 11,13.5472075 11,14.2222222 L11,22.7777778 C11,23.4527925 10.4527925,24 9.77777778,24 L1.22222222,24 C0.547207528,24 0,23.4527925 0,22.7777778 L0,14.2222222 C0,13.5472075 0.547207528,13 1.22222222,13 Z M2,22 L9,22 L9,15 L2,15 L2,22 Z" id="path-1"></path>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationDashboardIcon'
})
</script>
