import { Bus, Events } from '@/presentation/main'

export default class ContentPresenter {
  // MARK: - From Interactor

  /** @param {boolean} value */
  set showNavigationFromInteractor (value) {
    // Send status for App
    Bus.$emit(Events.navigationChanged, value)
  }

  /** @param {boolean} value */
  set centerContentFromInteractor (value) {
    // Send status for App
    Bus.$emit(Events.centerContentChanged, value)
  }
}
