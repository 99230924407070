import HallDomainModel from '@/domain/model/hallDomainModel'
import CategoryDomainModel from '@/domain/model/categoryDomainModel'

export default class SubscriptionsDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {string} */
  _contract
  /** @type {HallDomainModel} */
  _hall
  /** @type {CategoryDomainModel} */
  _category
  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {number} */
  _session
  /** @type {number} */
  _remainingSession
  /** @type {number} */
  _limitInWeek
  /** @type {number} */
  _price
  /** @type {boolean} */
  _medicalCertificate

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SubscriptionsDomainModel} dataModel
   */
  constructor (dataModel) {
    this._id = dataModel.id
    this._contract = dataModel.contract
    this._hall = new HallDomainModel(dataModel.hall)
    this._category = new CategoryDomainModel(dataModel.category)
    this._startAt = dataModel.startAt
    this._endAt = dataModel.endAt
    this._session = dataModel.session
    this._remainingSession = dataModel.remainingSession
    this._limitInWeek = dataModel.limitInWeek
    this._price = dataModel.price
    this._medicalCertificate = dataModel.medicalCertificate
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {string} */
  get contract () {
    return this._contract
  }

  /** @return {HallDomainModel} */
  get hall () {
    return this._hall
  }

  /** @return {CategoryDomainModel} */
  get category () {
    return this._category
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {number} */
  get session () {
    return this._session
  }

  /** @return {number} */
  get remainingSession () {
    return this._remainingSession
  }

  /** @return {number} */
  get limitInWeek () {
    return this._limitInWeek
  }

  /** @return {number} */
  get price () {
    return this._price
  }

  /** @return {boolean} */
  get medicalCertificate () {
    return this._medicalCertificate
  }
}
