export default class DetailsAPIResponse {
  // MARK: - Properties

  /** @type {int|null} */
  _currentPage
  /** @type {int|null} */
  _numberOfPages
  /** @type {string|null} */
  _expiredAt

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any|null} response
   */
  constructor (response) {
    this._currentPage = response.currentPage
    this._numberOfPages = response.numberOfPages
    this._expiredAt = response.expiredAt
  }

  // MARK: - Getter

  /** @return {boolean} */
  get isValid () {
    return this._expiredAt !== null
  }

  /** @return {boolean} */
  get isLastPage () {
    if (this._numberOfPages && this._currentPage) {
      return this._currentPage >= this._numberOfPages
    } else {
      return true
    }
  }

  /** @return {string} */
  get expiredAt () {
    return this._expiredAt
  }
}
