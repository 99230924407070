export default class PushNotificationManager {
  // MARK: - Properties

  /** @return {PushNotificationManager|null} */
  static _shared

  /** @type {boolean} */
  _canShowButton

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._canShowButton = false

    if (this._manager) {
      // const self = this
      this._manager.push(() => {
        this._manager.init({
          appId: process.env.VUE_APP_ONESIGNAL,
          // requiresUserPrivacyConsent: true,
          autoResubscribe: true,
          autoRegister: false,
          httpPermissionRequest: {
            enable: process.env.NODE_ENV !== 'production'
          },
          persistNotification: false,
          allowLocalhostAsSecureOrigin: process.env.NODE_ENV !== 'production',
          notifyButton: {
            enable: false
          },
          welcomeNotification: {
            disable: true
          }
        })

        this._manager.on('subscriptionChange', isSubscribed => {
          if (isSubscribed) {
            window.location.reload()
          }
        })

        this._manager.on('notificationPermissionChange', permissionChange => {
          const currentPermission = permissionChange.to
          if (currentPermission === 'granted') {
            window.location.reload()
          }
        })
      })
    }
  }

  // MARK: - Getter

  /** @return {PushNotificationManager} */
  static get shared () {
    return this._shared
  }

  get _manager () {
    return window.OneSignal || null
  }

  // MARK: - Setter

  /** @param {PushNotificationManager|null} value */
  static set shared (value) {
    this._shared = value
  }

  // MARK: - Methods

  /**
   * Register user push notification on OneSignal
   */
  registration () {
    if (this._manager) {
      this._manager.push(() => {
        this._manager.registerForPushNotifications()
      })
    }
  }

  /**
   * Unregister user push notification on OneSignal
   */
  unregistration () {
    const self = this
    setTimeout(function () {
      if (self._manager) {
        self._manager.push(() => {
          self._manager.removeExternalUserId()
        })
      }
    }, 500)
  }

  /**
   * Send user id to OneSignal
   * @param {string} userId
   */
  sendUserId (userId) {
    const self = this
    setTimeout(function () {
      if (self._manager) {
        self._manager.push(() => {
          self._manager.setExternalUserId(userId)
        })
      }
    }, 500)
  }

  /**
   * App can receive Notification ?
   * @return {Promise<boolean>}
   */
  get canRegister () {
    const self = this
    return new Promise(function (resolve) {
      if (self._manager) {
        self._manager.push(() => {
          // Set new timeout because sometime isPushNotificationsEnabled never responds
          const timeout = setTimeout(function () {
            // If the user accepts, let's create a notification
            if (Notification.permission === 'granted') {
              // We try to register user !
              self.registration()
            }

            resolve(false)
          }, 2000)

          self._manager.isPushNotificationsEnabled(function (isEnabled) {
            const isSupported = self._manager.isPushNotificationsSupported()
            clearTimeout(timeout)
            resolve(isSupported && !isEnabled)
          })
        })
      } else {
        resolve(false)
      }
    })
  }
}
