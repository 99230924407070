import { render, staticRenderFns } from "./ReservationsVue.vue?vue&type=template&id=45f07680&scoped=true&"
import script from "./ReservationsVue.vue?vue&type=script&lang=js&"
export * from "./ReservationsVue.vue?vue&type=script&lang=js&"
import style0 from "./ReservationsVue.vue?vue&type=style&index=0&id=45f07680&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f07680",
  null
  
)

export default component.exports