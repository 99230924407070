import ReservationSessionPresentationParameter from '@/presentation/scene/reservation/parameter/reservationSessionPresentationParameter'
import ReservationDomainParameter from '@/domain/scene/reservation/parameter/reservationDomainParameter'
import PresentationStatus from '@/presentation/common/enum/presentationStatus'

export default class ReservationPresentationParameter {
  // MARK: - Properties

  /** @type {int} corresponding to PresentationStatus */
  _status
  /** @type {ReservationSessionPresentationParameter} */
  _session
  /** @type {string|null} */
  _reservationId

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationDomainParameter} domainParameter
   */
  constructor (domainParameter) {
    this._status = PresentationStatus.fromDomain(domainParameter.status)
    this._session = new ReservationSessionPresentationParameter(domainParameter.session)
    this._reservationId = domainParameter.reservationId
  }

  // MARK: - Conversion

  /**
   * Convert object to domain parameter
   * @return {ReservationDomainParameter}
   */
  get toDomainParameter () {
    return new ReservationDomainParameter(this._status,
      this._session.toDomainParameter,
      this._reservationId
    )
  }
}
