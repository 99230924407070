import TextFieldEmailViewModel from '@/presentation/view-model/textfield/textFieldEmailViewModel'
import ButtonClassicResizeViewModel from '@/presentation/view-model/button/buttonClassicResizeViewModel'
import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import PasswordInitCodeTextFieldViewModel
  from '@/presentation/scene/password-init/view-model/passwordInitCodeTextFieldViewModel'
import TextFieldPasswordConfirmViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordConfirmViewModel'
import TextFieldPasswordNewViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordNewViewModel'

// MARK: - Constants

class Constants {
  static TextKeys = {
    title: 'password'
  }

  static Buttons = {
    validateTitleKey: 'init_password',
    connexionTitleKey: 'log_in'
  }
}

// MARK: - Class

export default class PasswordInitViewModel {
  // MARK: - Properties

  /** @type {any} */
  _validation
  /** @type {string} */
  _title
  /** @type {TextFieldEmailViewModel} */
  _emailTextField
  /** @type {SpaceViewModel} */
  _emailBottomSpace
  /** @type {PasswordInitCodeTextFieldViewModel} */
  _codeTextField
  /** @type {SpaceViewModel} */
  _codeBottomSpace
  /** @type {TextFieldPasswordNewViewModel} */
  _newPasswordTextField
  /** @type {SpaceViewModel} */
  _newPasswordBottomSpace
  /** @type {TextFieldPasswordConfirmViewModel} */
  _confirmPasswordTextField
  /** @type {SpaceViewModel} */
  _confirmPasswordBottomSpace
  /** @type {ButtonClassicResizeViewModel} */
  _validateButton
  /** @type {SpaceViewModel} */
  _validateBottomSpace
  /** @type {ButtonSmallViewModel} */
  _connexionButton
  /** @type {boolean} */
  _showLoader
  /** @type {PresentationInformation|null} */
  _information

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (vuetify, localization, validation) {
    this._validation = validation

    this._title = localization.getText(Constants.TextKeys.title)
    this._emailTextField = new TextFieldEmailViewModel(localization, validation)
    this._emailBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._codeTextField = new PasswordInitCodeTextFieldViewModel(localization, validation)
    this._codeBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._newPasswordTextField = new TextFieldPasswordNewViewModel(localization, validation)
    this._newPasswordBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._confirmPasswordTextField = new TextFieldPasswordConfirmViewModel(localization, validation)
    this._confirmPasswordBottomSpace = new SpaceViewModel(PresentationSpace.BIG)
    this._validateButton = new ButtonClassicResizeViewModel(vuetify, localization, Constants.Buttons.validateTitleKey)
    this._validateBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._connexionButton = new ButtonSmallViewModel(localization, Constants.Buttons.connexionTitleKey)
    this._showLoader = false
    this._information = null
  }

  // MARK: - Getter

  /** @return {array} */
  static get textFieldsValidations () {
    return Object.assign(
      TextFieldEmailViewModel.validation,
      PasswordInitCodeTextFieldViewModel.validation,
      TextFieldPasswordNewViewModel.validation,
      TextFieldPasswordConfirmViewModel.validation
    )
  }

  /** @type {string} */
  get title () {
    return this._title
  }

  /** @type {TextFieldEmailViewModel} */
  get emailTextField () {
    return this._emailTextField
  }

  /** @type {SpaceViewModel} */
  get emailBottomSpace () {
    return this._emailBottomSpace
  }

  /** @type {PasswordInitCodeTextFieldViewModel} */
  get codeTextField () {
    return this._codeTextField
  }

  /** @type {SpaceViewModel} */
  get codeBottomSpace () {
    return this._codeBottomSpace
  }

  /** @type {TextFieldPasswordNewViewModel} */
  get newPasswordTextField () {
    return this._newPasswordTextField
  }

  /** @type {SpaceViewModel} */
  get newPasswordBottomSpace () {
    return this._newPasswordBottomSpace
  }

  /** @type {TextFieldPasswordConfirmViewModel} */
  get confirmPasswordTextField () {
    return this._confirmPasswordTextField
  }

  /** @type {SpaceViewModel} */
  get confirmPasswordBottomSpace () {
    return this._confirmPasswordBottomSpace
  }

  /** @type {ButtonClassicResizeViewModel} */
  get validateButton () {
    return this._validateButton
  }

  /** @type {SpaceViewModel} */
  get validateBottomSpace () {
    return this._validateBottomSpace
  }

  /** @type {ButtonSmallViewModel} */
  get connexionButton () {
    return this._connexionButton
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {PresentationInformation|null} */
  get information () {
    return this._information
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }

  /** @param {PresentationInformation} value */
  set information (value) {
    this._information = value
  }
}
