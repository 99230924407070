import ReservationViewModel from '@/presentation/scene/reservation/view-model/reservationViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import PresentationSuccess from '@/presentation/common/success/presentationSuccess'
import PresentationSuccessType from '@/presentation/common/enum/presentationSuccess'
import ContentPresenter from '@/presentation/utils/contentPresenter'
import PresentationStatus from '@/presentation/common/enum/presentationStatus'
import String from '@/presentation/utils/string'
import { Bus, Events } from '@/presentation/main'

export default class ReservationPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {ReservationViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router
  /** @type {ReservationDomainModel|null */
  _reservation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    super()
    this._localization = new Localization(vuetify)
    this._validation = validation
    this._viewModel = new ReservationViewModel(this._localization, validation)
    this._router = router
    this._reservation = null
  }

  // MARK: - Getter

  /** @return {ReservationViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return ReservationViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showFromInteractor (value) {
    this._viewModel.show = value
  }

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  showTextFieldsErrorFromInteractor () {
    this._validation.pattern.$touch()
  }

  /** @param {ReservationDomainModel} value */
  set reservationFromInteractor (value) {
    this._reservation = value
    this._viewModel.reservation = value
  }

  prepareReturnToParentFromInteractor () {
    // Send status for App
    Bus.$emit(Events.closeReservationAsked)

    // Remove reservation object
    this._reservation = null
  }

  prepareSuccessMessageFromInteractor () {
    if (this._reservation) {
      const status = PresentationStatus.fromDomain(this._reservation.status)
      let type
      switch (status) {
        case PresentationStatus.WAITING:
          type = PresentationSuccessType.RESERVATION_WAITING
          break
        case PresentationStatus.CANCEL:
          type = PresentationSuccessType.RESERVATION_CANCEL
          break
        default:
          type = PresentationSuccessType.RESERVATION_RESERVE
          break
      }

      const success = new PresentationSuccess(this._localization, type)
      this._viewModel.information = success.toInformation
    }
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.information = presentationError.toInformation
  }

  resetTexfFieldsFromInteractor () {
    this._validation.pattern.$model = String.empty
    this._validation.$reset()
  }
}
