import ReservationDomainModel from '@/domain/scene/reservation/reservationDomainModel'

export default class ReservationDomainParameter {
  // MARK: - Properties

  /** @type {int} corresponding to DomainStatus */
  _status
  /** @type {ReservationSessionDomainParameter} */
  _session
  /** @type {string|null} */
  _reservationId

  // MARK: - Constructor

  /**
   * @constructor
   * @param {int} status corresponding to DomainStatus
   * @param {ReservationSessionDomainParameter} session
   * @param {string|null} reservationId
   */
  constructor (status, session, reservationId = null) {
    this._status = status
    this._session = session
    this._reservationId = reservationId
  }

  // MARK: - Getter

  /** @return {int} */
  get status () {
    return this._status
  }

  /** @return {ReservationSessionDomainParameter} */
  get session () {
    return this._session
  }

  /** @return {string|null} */
  get reservationId () {
    return this._reservationId
  }

  // MARK: - Conversion

  /**
   * Convert parameter to model
   * @return {ReservationDomainModel}
   */
  get toDomainModel () {
    return new ReservationDomainModel(this._session.id, this._session.startAt, this._status)
  }
}
