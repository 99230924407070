// MARK: - Constants

class Constants {
  static containerClasses = 'text-center'
  static small = false
  static text = false
  static justifyCenter = false
  static block = false
  static show = true
}

// MARK: - Class

export default class ButtonViewModel {
  // MARK: - Properties

  /** @type {string} */
  _title
  /** @type {string} */
  _color
  /** @type {string} */
  _classes
  /** @type {string} */
  _containerClasses
  /** @type {boolean} */
  _small
  /** @type {boolean} */
  _text
  /** @type {boolean} */
  _justifyCenter
  /** @type {boolean} */
  _block
  /** @type {boolean} */
  _show

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} title
   * @param {string} color
   * @param {string} classes
   * @param {boolean} small
   * @param {boolean} text
   * @param {boolean} justifyCenter
   * @param {string} containerClasses
   * @param {boolean} block
   * @param {boolean} show
   */
  constructor (title, color, classes,
    small = Constants.small,
    text = Constants.text,
    justifyCenter = Constants.justifyCenter,
    containerClasses = Constants.containerClasses,
    block = Constants.block,
    show = Constants.show) {
    this._title = title
    this._color = color
    this._classes = classes
    this._small = small
    this._text = text
    this._justifyCenter = justifyCenter
    this._containerClasses = containerClasses
    this._block = block
    this._show = show
  }

  // MARK: - Getter

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {string} */
  get containerClasses () {
    return this._containerClasses
  }

  /** @return {boolean} */
  get small () {
    return this._small
  }

  /** @return {boolean} */
  get text () {
    return this._text
  }

  /** @return {boolean} */
  get justifyCenter () {
    return this._justifyCenter
  }

  /** @return {boolean} */
  get block () {
    return this._block
  }

  /** @return {boolean} */
  get show () {
    return this._show
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set show (value) {
    this._show = value
  }

  /** @param {string} value */
  set color (value) {
    this._color = value
  }
}
