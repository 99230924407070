export default class CategoryDataModel {
  /** @type {string} */
  _name

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._name = response.name
  }

  // MARK: - Getter

  /** @return {string} */
  get name () {
    return this._name
  }
}
