import { Bus, Events } from '@/presentation/main'

export default class ProfileController {
  // MARK: - Properties

  /** @type {ProfileInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ProfileInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  vueDestroyed () {
    this._clearBus()
  }

  // MARK: - Methods

  birthdayChanged () {
    this._interactor.birthdayChangedFromController()
  }

  /**
   * @param {boolean} subscribe
   */
  saveTapped (subscribe) {
    this._interactor.saveTappedFromController(subscribe)
  }

  passwordUpdateTapped () {
    this._interactor.passwordUpdateTappedFromController()
  }

  legalNoticesTapped () {
    this._interactor.legalNoticesTappedFromController()
  }

  logoutTapped () {
    this._interactor.logoutTappedFromController()
  }

  // MARK: - Methods

  refreshTapped () {
    this._interactor.refreshTappedFromController()
  }

  // MARK: - Bus

  _loadBus () {
    // Check when forgot will be closed
    const self = this
    Bus.$on(Events.closeLogoutAsked, redirectToLogin => {
      self._interactor.closeLogoutAskedFromController(redirectToLogin)
    })

    Bus.$on(Events.closeLegalNoticesAsked, _ => {
      self._interactor.closeLegalNoticesAskedFromController()
    })
  }

  _clearBus () {
    Bus.$off(Events.closeLogoutAsked)
    Bus.$off(Events.closeLegalNoticesAsked)
  }
}
