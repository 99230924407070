import String from '@/common/utils/string'

// MARK: - Constants

const SESSIONS = 'sessions'
const RESERVATIONS = 'reservations'
const DASHBOARD = 'dashboard'
const SUBSCRIPTIONS = 'subscriptions'
const PROFILE = 'profile'
const TOKEN = 'token'
const REFRESH_TOKEN = 'refresh.token'
const ASK_INSTALL_APP = 'ask.install.app'

class Constants {
  static target = 'et'
  static app = 'client'
  static expiration = 'expiration'
}

// MARK: - Enum

export default {
  SESSIONS,
  RESERVATIONS,
  DASHBOARD,
  SUBSCRIPTIONS,
  PROFILE,
  TOKEN,
  REFRESH_TOKEN,
  ASK_INSTALL_APP,

  /** @return {string} */
  _prefix (value) {
    return Constants.target + String.dot + Constants.app + String.dot + value
  },

  /** @return {string} */
  key (value) {
    return this._prefix(value)
  },

  /** @return {string} */
  expirationKey (value) {
    return this._prefix(value) + String.dot + Constants.expiration
  }
}
