import Vue from 'vue'
import Router from 'vue-router'
import Route from '@/app/utils/route'

Vue.use(Router)

// MARK: - Constants

class Constants {
  static mode = 'history'
}

// MARK: - Router

export default new Router({
  mode: Constants.mode,
  routes: Route.allForRouter
})
