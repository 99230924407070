<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M20.0000623,11.0762866 C20.0000623,10.5240018 20.4477775,10.0762866 21.0000623,10.0762866 C21.552347,10.0762866 22.0000623,10.5240018 22.0000623,11.0762866 L22.0000621,12.0068583 C21.9972767,16.8784128 18.7905855,21.1678926 14.1189498,22.5491494 C9.44731406,23.9304062 4.42359501,22.0744017 1.7721127,17.9876313 C-0.879369607,13.9008609 -0.526988104,8.55686121 2.63816372,4.8536377 C5.80331554,1.15041418 11.027246,-0.0298638476 15.4770621,1.95285834 C15.9815346,2.17763814 16.2082703,2.76881393 15.9834905,3.27328641 C15.7587107,3.77775889 15.1675349,4.0044946 14.6630624,3.7797148 C11.0223038,2.15748756 6.74817888,3.12316958 4.15850921,6.15307973 C1.56883953,9.18298988 1.28052739,13.5553533 3.44992201,16.8990745 C5.61931663,20.2427957 9.72963221,21.7613449 13.5518796,20.6312257 C17.374127,19.5011065 19.9977835,15.9915321 20.0000623,12.0062866 L20.0000623,11.0762866 Z M11.0000623,12.592073 L21.2929555,2.29917979 C21.6834798,1.9086555 22.3166448,1.9086555 22.7071691,2.29917979 C23.0976933,2.68970408 23.0976933,3.32286906 22.7071691,3.71339335 L11.7071691,14.7133934 C11.3166448,15.1039176 10.6834798,15.1039176 10.2929555,14.7133934 L7.29295549,11.7133934 C6.9024312,11.3228691 6.9024312,10.6897041 7.29295549,10.2991798 C7.68347978,9.9086555 8.31664476,9.9086555 8.70716905,10.2991798 L11.0000623,12.592073 Z" id="path-1"></path>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationReservationsIcon'
})
</script>
