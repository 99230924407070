import SkeletonViewModel from '@/presentation/view-model/skeleton/skeletonViewModel'

// MARK: - Constants

class Constants {
  static type = 'image'
  static height = '53px'
  static classes = 'mb-7'
}

// MARK: - Class

export default class FormLoaderViewModel extends SkeletonViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    super(Constants.type,
      Constants.height,
      Constants.classes)
  }
}
