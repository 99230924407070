// MARK: - Constants

class Constants {
  static empty = ''
  static space = ' '
  static dot = '.'
  static bracketIn = '('
  static bracketOut = ')'
  static linebreak = '\n'
}

// MARK: - Class

export default class String {
  // MARK: - Properties

  static empty = Constants.empty
  static space = Constants.space
  static dot = Constants.dot
  static linebreak = Constants.linebreak

  // MARK: - Conversion

  static toBracketsInto (text) {
    return Constants.bracketIn + text + Constants.bracketOut
  }

  // MARK: - Other

  /**
   * Check if string is empty
   * @param {string} value
   * @return {boolean}
  **/
  static isEmpty (value) {
    return (!value || value.length === 0)
  }

  /**
   * Convert array to string with space split
   * @param {string[]} values
   * @return {string}
   */
  static fromArray (values) {
    return values.join(String.space)
  }
}
