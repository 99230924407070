import DashboardColCardMessageViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardMessageViewModel'
import DashboardInformationViewModel from '@/presentation/scene/dashboard/view-model/dashboardInformationViewModel'
import DashboardReservationsViewModel from '@/presentation/scene/dashboard/view-model/dashboardReservationsViewModel'
import DashboardSubscriptionsViewModel from '@/presentation/scene/dashboard/view-model/dashboardSubscriptionsViewModel'
import ListContainerViewModel from '@/presentation/view-model/container/listContainerViewModel'
import DashboardMessageType from '@/presentation/scene/dashboard/enum/dashboardMessageType'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import SceneRefreshViewModel from '@/presentation/view-model/scene/sceneRefreshViewModel'

// MARK: - Constants

class Constants {
  static titleKey = 'dashboard_title'
}

// MARK: - Class

export default class DashboardViewModel extends SceneRefreshViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {string} */
  _title
  /** @type {ListContainerViewModel} */
  _container
  /** @type {RowViewModel} */
  _row
  /** @type {boolean} */
  _showAskPushRegistration
  /** @type {DashboardColCardMessageViewModel} */
  _askPushRegistration
  /** @type {boolean} */
  _showSubscriptionExpireSoon
  /** @type {DashboardColCardMessageViewModel} */
  _subscriptionExpireSoon
  /** @type {boolean} */
  _showSubscriptionExpired
  /** @type {DashboardColCardMessageViewModel} */
  _subscriptionExpired
  /** @type {boolean} */
  _showInformations
  /** @type {DashboardInformationViewModel} */
  _informations
  /** @type {DashboardReservationsViewModel} */
  _reservations
  /** @type {DashboardSubscriptionsViewModel} */
  _subscriptions
  /** @type {PresentationInformation|null} */
  _error

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super(localization)

    this._localization = localization
    this._title = localization.getText(Constants.titleKey)
    this._container = new ListContainerViewModel()
    this._row = new RowViewModel()

    this._showAskPushRegistration = false
    const askPushRegistrationType = DashboardMessageType.ASK_PUSH_REGISTRATION
    this._askPushRegistration = new DashboardColCardMessageViewModel(DashboardMessageType.toCard(askPushRegistrationType), null, true)
    this._askPushRegistration.message = localization.getText(DashboardMessageType.messageKey(askPushRegistrationType))

    this._showSubscriptionExpireSoon = false
    const subscriptionExpireSoonType = DashboardMessageType.SUBSCRIPTION_EXPIRE_SOON
    this._subscriptionExpireSoon = new DashboardColCardMessageViewModel(DashboardMessageType.toCard(subscriptionExpireSoonType))
    this._subscriptionExpireSoon.message = localization.getText(DashboardMessageType.messageKey(subscriptionExpireSoonType))

    this._showSubscriptionExpired = false

    const subscriptionExpiredType = DashboardMessageType.SUBSCRIPTION_EXPIRED
    this._subscriptionExpired = new DashboardColCardMessageViewModel(DashboardMessageType.toCard(subscriptionExpiredType))
    this._subscriptionExpired.message = localization.getText(DashboardMessageType.messageKey(subscriptionExpiredType))

    this._showInformations = false
    this._informations = new DashboardInformationViewModel(localization)
    this._reservations = new DashboardReservationsViewModel(localization)
    this._subscriptions = new DashboardSubscriptionsViewModel(localization)

    this._error = null
  }

  // MARK: - Getter

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {ListContainerViewModel} */
  get container () {
    return this._container
  }

  /** @return {RowViewModel} */
  get row () {
    return this._row
  }

  /** @return {boolean} */
  get showAskPushRegistration () {
    return this._showAskPushRegistration || this._askPushRegistration.showLoader
  }

  /** @return {DashboardColCardMessageViewModel} */
  get askPushRegistration () {
    return this._askPushRegistration
  }

  /** @return {boolean} */
  get showSubscriptionExpireSoon () {
    return this._showSubscriptionExpireSoon || this._subscriptionExpireSoon.showLoader
  }

  /** @return {DashboardColCardMessageViewModel} */
  get subscriptionExpireSoon () {
    return this._subscriptionExpireSoon
  }

  /** @return {boolean} value */
  get showSubscriptionExpired () {
    return this._showSubscriptionExpired
  }

  /** @return {DashboardColCardMessageViewModel} */
  get subscriptionExpired () {
    return this._subscriptionExpired
  }

  /** @return {boolean} */
  get showInformations () {
    return this._showInformations || this._informations.showLoader
  }

  /** @return {DashboardInformationViewModel} */
  get informations () {
    return this._informations
  }

  /** @return {DashboardReservationsViewModel} */
  get reservations () {
    return this._reservations
  }

  /** @return {DashboardSubscriptionsViewModel} */
  get subscriptions () {
    return this._subscriptions
  }

  /** @return {PresentationInformation|null} */
  get error () {
    return this._error
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showAskPushRegistration (value) {
    this._showAskPushRegistration = value
  }

  /** @param {boolean} value */
  set showSubscriptionExpireSoon (value) {
    this._showSubscriptionExpireSoon = value
  }

  /** @param {boolean} value */
  set showSubscriptionExpired (value) {
    this._showSubscriptionExpired = value
  }

  /** @param {boolean} value */
  set showInformations (value) {
    this._showInformations = value
  }

  /** @param {PresentationInformation} value */
  set error (value) {
    this._error = value
  }
}
