import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static basePath = 'reservations'
  static Path = {
    getAll: Constants.basePath + '/client',
    post: Constants.basePath,
    put: Constants.basePath + '/'
  }
}

// MARK: - Class

export default class ReservationAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _worker

  // MARK: - Constructor

  constructor () {
    const configuration = new ApiConfiguration(true)
    this._worker = new ApiWorker(configuration)
  }

  // MARK: - Get

  /**
   * Call API to get client reservations
   * @param {int} page of the request
   * @return {Promise}
   */
  getAll (page) {
    return this._worker.getRequest(Constants.Path.getAll, page)
  }

  // MARK: - Post

  /**
   * Call API to reserve session
   * @param {ReservationAPIBody} parameter
   * @return {Promise}
   */
  add (parameter) {
    return this._worker.postRequest(Constants.Path.post, parameter)
  }

  // MARK: - Put

  /**
   * Call API to update reservation
   * @param {string} reservationId reservation id
   * @param {ReservationAPIBody} parameter
   * @return {Promise}
   */
  update (reservationId, parameter) {
    return this._worker.putRequest(Constants.Path.put + reservationId, parameter)
  }
}
