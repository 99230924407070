import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static Path = {
    init: 'password/init',
    forgot: 'password/forgot',
    update: 'password/update'
  }
}

// MARK: - Class

export default class PasswordAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _workerWithAuth
  /** @type {ApiWorker} */
  _workerWithoutAuth

  // MARK: - Constructor

  constructor () {
    // Worker with authentication
    const configurationWithAuth = new ApiConfiguration(true)
    this._workerWithAuth = new ApiWorker(configurationWithAuth)

    // Worker without authentication
    const configurationWithoutAuth = new ApiConfiguration(false)
    this._workerWithoutAuth = new ApiWorker(configurationWithoutAuth)
  }

  // MARK: - Post

  /**
   * Call API to init client password
   * @param {PasswordInitAPIBody} parameter
   * @return {Promise}
   */
  init (parameter) {
    return this._workerWithoutAuth.postRequest(Constants.Path.init, parameter, false)
  }

  /**
   * Call API to ask new client password
   * @param {PasswordForgotAPIBody} parameter
   * @return {Promise}
   */
  forgot (parameter) {
    return this._workerWithoutAuth.postRequest(Constants.Path.forgot, parameter, false)
  }

  /**
   * Call API to update client password
   * @param {PasswordUpdateAPIBody} parameter
   * @return {Promise}
   */
  update (parameter) {
    return this._workerWithAuth.putRequest(Constants.Path.update, parameter, true)
  }
}
