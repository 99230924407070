export default class DAOWrapper {
  // MARK: - Properties

  _key
  _localStorage

  // MARK: - Constructor

  constructor (key) {
    this._key = key
    this._localStorage = window.localStorage
  }

  // MARK: - Getter

  get get () {
    return this._localStorage.getItem(this._key)
  }

  // MARK: - Setter

  save (value) {
    this._localStorage.setItem(this._key, value)
  }

  remove () {
    this._localStorage.removeItem(this._key)
  }
}
