export default class ReservationSessionDomainParameter {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {moment.Moment} */
  _startAt

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} id
   * @param {moment.Moment} startAt
   */
  constructor (id, startAt) {
    this._id = id
    this._startAt = startAt
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }
}
