import SheetTextViewModel from '@/presentation/view-model/sheet/text/sheetTextViewModel'

// MARK: - Constants

class Constants {
  static classes = 'pt-4 mb-4'
}

// MARK: - Class

export default class SheetTitleViewModel extends SheetTextViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} title
   */
  constructor (localization, title) {
    super(localization, title, Constants.classes)
  }

  // MARK: - Setter

  /** @param {string} value */
  set value (value) {
    this.text = value
  }
}
