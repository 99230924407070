import ReservationDataRepository from '@/data/repository/reservation/reservationDataRepository'
import ReservationInteractor from '@/domain/scene/reservation/reservationInteractor'

export default class ReservationDomainInjection {
  // MARK: - Properties

  /** @type {ReservationInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate
   * @param {ReservationPresenter} presenter
   */
  constructor (validation, presenter) {
    const repository = new ReservationDataRepository()
    this._interactor = new ReservationInteractor(repository, validation, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {ReservationInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
