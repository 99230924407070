import TextFieldNumberViewModel from '@/presentation/view-model/textfield/textFieldNumberViewModel'

// MARK: - Constants

class Constants {
  static model = 'code'
  static labelKey = 'password_code'
  static requiredKey = 'password_code_required'
  static exactLengthKey = 'error_password_code'
  static exactLength = 4
}

// MARK: - Class

export default class PasswordInitCodeTextFieldViewModel extends TextFieldNumberViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization,
      validation,
      Constants.model,
      Constants.labelKey,
      Constants.requiredKey,
      Constants.exactLengthKey
    )
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return TextFieldNumberViewModel.validation(Constants.model, Constants.exactLength)
  }
}
