import SkeletonViewModel from '@/presentation/view-model/skeleton/skeletonViewModel'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static indexes = 1
  static type = 'image'
  static height = '70px'
  static withClasses = 'mb-2'
  static withoutClasses = String.empty
}

// MARK: - Class

export default class CardLoaderViewModel extends SkeletonViewModel {
  // MARK: - Properties

  /** @type {int} */
  _indexes

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor (indexes = Constants.indexes, height = Constants.height) {
    super(
      Constants.type,
      height,
      (indexes > 1) ? Constants.withClasses : Constants.withoutClasses
    )
    this._indexes = indexes
  }

  // MARK: - Getter

  /** @return {int} */
  get indexes () {
    return this._indexes
  }
}
