import ApiResponseErrorType from '@/data/api/response-error/apiResponseErrorType'
import String from '@/common/utils/string'
import ApiResponseErrorModel from '@/data/api/response-error/model/apiResponseErrorModel'

// MARK: - Constants

class Constants {
  static StatusCode = {
    token: 401,
    badCredentials: 403,
    accountDisabled: 410,
    notFound: 404
  }
}

// MARK: - Enum

export default class ApiResponseError {
  // MARK: - Properties

  /** @type {int} */
  _type
  /** @type {string|null} */
  _message

  // MARK: - Constructor

  constructor (statusCode, data = null) {
    switch (statusCode) {
      case Constants.StatusCode.token:
        this._type = ApiResponseErrorType.TOKEN
        break
      case Constants.StatusCode.badCredentials:
        this._type = ApiResponseErrorType.BAD_CREDENTIALS
        break
      case Constants.StatusCode.accountDisabled:
        this._type = ApiResponseErrorType.ACCOUNT_DISABLED
        break
      case Constants.StatusCode.notFound:
        this._type = ApiResponseErrorType.NOT_FOUND
        break
      default:
        this._type = ApiResponseErrorType.UNKNOW
        break
    }

    // Check if error contains violations
    if (data) {
      const error = new ApiResponseErrorModel(data)
      if (error.isValid) {
        if (error.violations && error.violations.length > 0) {
          this._message = error.violations.map(e => {
            return e.message
          }).join(String.linebreak)
        } else {
          this._message = error.detail
        }
      }
    }
  }

  /**
   * @constructor
   * @return {ApiResponseError}
   */
  static fromInvalidToken () {
    return new ApiResponseError(Constants.StatusCode.token)
  }

  // MARK: - Getter

  /** @return {int} */
  get type () {
    return this._type
  }

  /** @return {string|null} */
  get message () {
    return this._message
  }

  /** @return {boolean} */
  get isTokenError () {
    return this._type === ApiResponseErrorType.TOKEN
  }
}
