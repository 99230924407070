import PasswordForgotDomainInjection from '@/domain/scene/password-forgot/passwordForgotDomainInjection'
import PasswordForgotController from '@/presentation/scene/password-forgot/passwordForgotController'
import PasswordForgotPresenter from '@/presentation/scene/password-forgot/passwordForgotPresenter'

export default class PasswordForgotPresentationInjection {
  // MARK: - Properties

  /** @type {PasswordForgotController} */
  _controller
  /** @type {PasswordForgotPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    this._presenter = new PasswordForgotPresenter(vuetify, validation, router)

    // Domain
    const domainInjection = new PasswordForgotDomainInjection(validation, this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new PasswordForgotController(interactor)
  }

  // MARK: - Getter

  /** @return {PasswordForgotController} */
  get controller () {
    return this._controller
  }

  /** @return {PasswordForgotPresenter} */
  get presenter () {
    return this._presenter
  }
}
