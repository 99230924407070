import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'
import DateFormat from '@/common/utils/dateFormat'
import { helpers, required } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static labelKey = 'birthday'
  static required = true
  static type = 'text'
  static requiredKey = 'birthday_required'
  static validKey = 'error_client_field_birthday_format'

  static Regex = {
    dayPart: /^(\d\d)(\d)$/g,
    dayAndMonthPart: /^(\d\d\/\d\d)(\d+)$/g,
    dayAndMonthAndYearPart: /[^\d/]/g,
    replace: '$1/$2'
  }
}

const maxDate = helpers.withParams({
  maxDate: DateFormat.currentString(DateFormat.DD_MM_YYYY)
}, value => DateFormat.isSameOrBeforeNow(value, DateFormat.DD_MM_YYYY))

// MARK: - Class

export default class ProfileBirthdayTextFieldViewModel extends TextFieldViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization.getText(Constants.labelKey),
      Constants.required,
      Constants.type
    )

    this._localization = localization
    this._validation = validation
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return {
      birthday: {
        required,
        maxDate
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.birthday.$dirty) return errors
    !this._validation.birthday.maxDate && errors.push(this._localization.getText(Constants.validKey))
    !this._validation.birthday.required && errors.push(this._localization.getText(Constants.requiredKey))
    return errors
  }

  // MARK: - Other

  updateAfterChange () {
    let text = this._validation.birthday.$model
    text = text.replace(Constants.Regex.dayPart, Constants.Regex.replace)
    text = text.replace(Constants.Regex.dayAndMonthPart, Constants.Regex.replace)
    text = text.replace(Constants.Regex.dayAndMonthAndYearPart)

    this._validation.birthday.$model = text
  }
}
