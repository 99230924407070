import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'

// MARK: - Constants

class Constants {
  static AppBar = {
    app: true,
    fixed: true,
    color: 'background'
  }

  static colClasses = 'pa-0'

  static TextClasses = {
    text: 'd-flex text-uppercase' + FontWeight.bold,
    centerText: 'justify-space-around text-h3',
    leftText: 'text-h5'
  }
}

// MARK: - Class

export default class AppBarViewModel {
  // MARK: - Properties

  /** @type {Framework} */
  _vuetify

  /** @type {boolean} */
  _app
  /** @type {boolean} */
  _fixed
  /** @type {string} */
  _color
  /** @type {RowViewModel} */
  _row
  /** @type {ColViewModel} */
  _col

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._vuetify = vuetify
    this._app = Constants.AppBar.app
    this._fixed = Constants.AppBar.fixed
    this._color = Constants.AppBar.color
    this._row = new RowViewModel()
    this._col = new ColViewModel(Constants.colClasses)
  }

  // MARK: - Getter

  /** @return {boolean} */
  get app () {
    return this._app
  }

  /** @return {boolean} */
  get fixed () {
    return this._fixed
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {RowViewModel} */
  get row () {
    return this._row
  }

  /** @return {ColViewModel} */
  get col () {
    return this._col
  }

  /** @return {string} */
  get textClasses () {
    const classes = Constants.TextClasses.text + String.space
    if (this._vuetify.breakpoint.mdAndUp) {
      return classes + Constants.TextClasses.centerText
    } else {
      return classes + Constants.TextClasses.leftText
    }
  }
}
