import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'
import ProfileTextTextFieldType from '@/presentation/scene/profile/enum/profileTextTextFieldType'
import { required } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static required = true
}

// MARK: - Class

export default class ProfileTextTextFieldViewModel extends TextFieldViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {string} */
  _model
  /** @type {string} */
  _requiredKey

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   * @param {ProfileTextTextFieldType} type
   */
  constructor (localization, validation, type) {
    super(localization.getText(ProfileTextTextFieldType.labelKey(type)),
      Constants.required
    )

    this._localization = localization
    this._validation = validation
    this._model = ProfileTextTextFieldType.model(type)
    this._requiredKey = ProfileTextTextFieldType.requiredKey(type)
  }

  // MARK: - Getter

  /**
   * @param {ProfileTextTextFieldType} type
   * @return {array}
   */
  static validation (type) {
    return {
      [ProfileTextTextFieldType.model(type)]: {
        required
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation[this._model].$dirty) return errors
    !this._validation[this._model].required && errors.push(this._localization.getText(this._requiredKey))
    return errors
  }
}
