// MARK: - Constants

class Constants {
  static nameKey = 'name'
  static lastnameKey = 'lastname'
  static emailKey = 'email'
  static birthdayKey = 'birthday'
  static phoneKey = 'phone'
  static subscribeKey = 'subscribe'
}

// MARK: - Class

export default class ProfileAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} name
   * @param {string} lastname
   * @param {string} email
   * @param {string} birthday
   * @param {string} phone
   * @param {boolean} subscribe
   */
  constructor (name, lastname, email, birthday, phone, subscribe) {
    this._body[Constants.nameKey] = name
    this._body[Constants.lastnameKey] = lastname
    this._body[Constants.emailKey] = email
    this._body[Constants.birthdayKey] = birthday
    this._body[Constants.phoneKey] = phone
    this._body[Constants.subscribeKey] = subscribe
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
