import State from '@/presentation/common/enum/presentationState'
import Color from '@/presentation/utils/color'

const RESERVE = 0
const WAITING = 1
const CANCEL = 2

class Constants {
  static ActionButtonTitleKey = {
    reserve: 'reserve',
    waiting: 'waiting_list',
    cancel: 'cancel'
  }

  static DescriptionKey = {
    reserve: 'reserve_session_description',
    waiting: 'waiting_session_description',
    cancel: 'cancel_session_description'
  }

  static FieldLabelKey = {
    reserve: 'information',
    cancel: 'reason'
  }

  static cancelRequiredKey = 'error_missing_pattern_for_cancel'
}

export default {
  RESERVE,
  WAITING,
  CANCEL,

  // MARK: - Constructor

  /**
   * @param {int} domainEnum
   * @return {int|null} corresponding to this enum
   */
  fromDomain (domainEnum) {
    switch (domainEnum) {
      case RESERVE:
        return RESERVE
      case WAITING:
        return WAITING
      case CANCEL:
        return CANCEL
      default:
        return null
    }
  },

  // MARK: - Methods

  /**
   * @param {int|null} value corresponding to this enum
   * @return {int}
   */
  state (value) {
    switch (value) {
      case RESERVE:
        return State.GREEN
      case WAITING:
        return State.YELLOW
      case CANCEL:
        return State.RED
      default:
        return State.WHITE
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  actionButtonTitleKey (value) {
    switch (value) {
      case WAITING:
        return Constants.ActionButtonTitleKey.waiting
      case CANCEL:
        return Constants.ActionButtonTitleKey.cancel
      default:
        return Constants.ActionButtonTitleKey.reserve
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  description (value) {
    switch (value) {
      case WAITING:
        return Constants.DescriptionKey.waiting
      case CANCEL:
        return Constants.DescriptionKey.cancel
      default:
        return Constants.DescriptionKey.reserve
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  fieldLabelKey (value) {
    switch (value) {
      case CANCEL:
        return Constants.FieldLabelKey.cancel
      default:
        return Constants.FieldLabelKey.reserve
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {boolean}
   */
  fieldRequired (value) {
    return value === CANCEL
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string|null}
   */
  fieldRequiredKey (value) {
    switch (value) {
      case CANCEL:
        return Constants.cancelRequiredKey
      default:
        return null
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  buttonBackgroundColor (value) {
    switch (value) {
      case WAITING:
        return Color.main
      case CANCEL:
        return Color.red
      default:
        return Color.green
    }
  }
}
