import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'
import { required } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static labelKey = 'password'
  static type = 'password'
  static autocomplete = 'current-password'
  static required = true
  static requiredKey = 'password_required'
}

// MARK: - Class

export default class TextFieldPasswordViewModel extends TextFieldViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   * @param {string} labelKey
   * @param {string} autocomplete
   */
  constructor (localization, validation, labelKey = Constants.labelKey, autocomplete = Constants.autocomplete) {
    super(localization.getText(labelKey),
      Constants.required,
      Constants.type,
      autocomplete
    )

    this._localization = localization
    this._validation = validation
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return {
      password: {
        required
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.password.$dirty) return errors
    !this._validation.password.required && errors.push(this._localization.getText(Constants.requiredKey))
    return errors
  }
}
