export default class PasswordUpdateController {
  // MARK: - Properties

  /** @type {PasswordUpdateInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {PasswordUpdateInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  // MARK: - Methods

  saveTapped () {
    this._interactor.saveTappedFromController()
  }

  backTapped () {
    this._interactor.backTappedFromController()
  }
}
