import ReservationAPIBody from '@/data/api/body/reservationAPIBody'

export default class ReservationDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _sessionId
  /** @type {int} */
  _status
  /** @type {string|null} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} sessionId
   * @param {int} status
   * @param {string|null} pattern
   */
  constructor (sessionId, status, pattern) {
    this._sessionId = sessionId
    this._status = status
    this._pattern = pattern
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {ReservationAPIBody}
   */
  get toAPIBody () {
    return new ReservationAPIBody(this._sessionId, this._status, this._pattern)
  }
}
