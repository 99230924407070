import { Bus, Events } from '@/presentation/main'

export default class ReservationController {
  // MARK: - Properties

  /** @type {ReservationInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  vueDestroyed () {
    this._clearBus()
  }

  // MARK: - Methods

  /** @param {boolean} value */
  showCalled (value) {
    this._interactor.showCalledFromController(value)
  }

  validateTapped () {
    this._interactor.validateTappedFromController()
  }

  cancelTapped () {
    this._interactor.cancelTappedFromController()
  }

  // MARK: - Bus

  _loadBus () {
    // Check when alert is closed
    const self = this
    Bus.$on(Events.alertClosed, _ => {
      self._interactor.closeThisSceneFromController()
    })
  }

  _clearBus () {
    Bus.$off(Events.alertClosed)
  }

  // MARK: - Setter

  /** @param {ReservationPresentationParameter|null} value */
  set parameter (value) {
    this._interactor.parameter = value.toDomainParameter
  }
}
