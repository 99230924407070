import SessionsForWeekInteractor from '@/domain/scene/sessions-for-week/sessionsForWeekInteractor'
import SessionsForWeekDataRepository from '@/data/repository/sessions-for-week/sessionsForWeekDataRepository'

export default class SessionsForWeekDomainInjection {
  // MARK: - Properties

  /** @type {SessionsForWeekInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SessionsForWeekPresenter} presenter
   */
  constructor (presenter) {
    const repository = new SessionsForWeekDataRepository()
    this._interactor = new SessionsForWeekInteractor(repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {SessionsForWeekInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
