<template>
    <v-app
      :style="{background: presenter.viewModel.backgroundColor}"
    >
      <!-- Install app banner -->
      <custom-banner
        :view-model="presenter.viewModel.installAppBanner"
        @click="controller.installAppTapped($event)"
      />

      <!-- Main -->
      <v-main>
        <custom-container-layout-flex :view-model="presenter.viewModel.container">
          <router-view/>
        </custom-container-layout-flex>
      </v-main>

      <!-- Navigation -->
      <custom-navigation v-if="presenter.viewModel.showNavigation"/>
    </v-app>
</template>

<script>

// Components
import BannerComponent from '@/app/common/component/banner/BannerComponent'
import ContainerLayoutFlexComponent from '@/app/common/component/container-layout-flex/ContainerLayoutFlexComponent'
import NavigationComponent from '@/app/common/component/navigation/NavigationComponent'
import PresentationInjection from '@/presentation/scene/app/appPresentationInjection'

export default {
  name: 'App',

  components: {
    customBanner: BannerComponent,
    customContainerLayoutFlex: ContainerLayoutFlexComponent,
    customNavigation: NavigationComponent
  },

  data: () => ({
    /** @type AppController */
    controller: null,
    /** @type AppPresenter */
    presenter: null
  }),

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'app-style.scss'
</style>
