import DomainError from '@/domain/error/domainError'
import PasswordForgotDomainEntry from '@/domain/scene/password-forgot/passwordForgotDomainEntry'

export default class PasswordForgotInteractor {
  // MARK: - Properties

  /** @type {PasswordDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {PasswordForgotPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {PasswordDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {PasswordForgotPresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {}

  // MARK: - From Controller

  /**
   * Show or hide vue
   * @param {boolean} value
   */
  showCalledFromController (value) {
    // Clear textfields value before show reservation sheet
    if (value) {
      this._presenter.resetTexfFieldsFromInteractor()
    }

    this._presenter.showFromInteractor = value
  }

  // Ask new password from repository
  validateTappedFromController () {
    // Form is valid ?
    if (!this._validation.$invalid) {
      // Close form
      this._presenter.showFromInteractor = false

      // Show loader
      this._presenter.showLoaderFromInteractor = true

      const self = this
      const entry = new PasswordForgotDomainEntry(this._validation)
      this._repository.forgot(entry.toData).then(function () {
        // Show success message
        self._presenter.prepareSuccessMessageFromInteractor()

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      }).catch(e => {
        // Send error to presenter
        self._presenter.prepareErrorFromInteractor = new DomainError(e)

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      })
    } else {
      // Show error on all invalids text fields
      this._presenter.showTextFieldsErrorFromInteractor()
    }
  }

  cancelTappedFromController () {
    // Close refresh
    this._closeThisSceneFromController()
  }

  closeThisSceneFromController () {
    // Close refresh
    this._closeThisSceneFromController()
  }

  // MARK: - Methods

  // Close refresh
  _closeThisSceneFromController () {
    this._presenter.prepareReturnToParentFromInteractor()
  }
}
