import SubscriptionsDomainInjection from '@/domain/scene/subscriptions/subscriptionsDomainInjection'
import SubscriptionsController from '@/presentation/scene/subscriptions/subscriptionsController'
import SubscriptionsPresenter from '@/presentation/scene/subscriptions/subscriptionsPresenter'

export default class SubscriptionsPresentationInjection {
  // MARK: - Properties

  /** @type {SubscriptionsController} */
  _controller
  /** @type {SubscriptionsPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._presenter = new SubscriptionsPresenter(vuetify)

    // Domain
    const domainInjection = new SubscriptionsDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new SubscriptionsController(interactor)
  }

  // MARK: - Getter

  /** @return {SubscriptionsController} */
  get controller () {
    return this._controller
  }

  /** @return {SubscriptionsPresenter} */
  get presenter () {
    return this._presenter
  }
}
