<template>
  <custom-col-card :viewModel="viewModel">

    <!-- Title -->
    <div :class="viewModel.titleClasses">
      {{ viewModel.title }}
    </div>

    <!-- Value -->
    <div :class="viewModel.valueClasses">
      {{ viewModel.value }}
    </div>

  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import DashboardColCardDetailsViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardDetailsViewModel'

export default {
  name: 'DashboardColCardDetailsComponent',

  components: {
    customColCard: ColCardComponent
  },

  props: {
    viewModel: {
      type: DashboardColCardDetailsViewModel,
      required: true
    }
  }
}
</script>
