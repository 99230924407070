import PresentationStatus from '@/presentation/common/enum/presentationStatus'
import PresentationState from '@/presentation/common/enum/presentationState'
import DateFormat from '@/common/utils/dateFormat'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import StateInformationViewModel from '@/presentation/view-model/state-information/stateInformationViewModel'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import CardContentItemViewModel from '@/presentation/view-model/card/cardContentItemViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'

// MARK: - Constants

class Constants {
  static Text = {
    sessionStart: 'session_start_time',
    placeAvailable: 'place_available_value'
  }

  static CssClass = {
    col: 'px-0 py-1',

    fontPattern: FontWeight.italic,
    fontPlaceAvailable: 'text-caption'
  }
}

// MARK: - Class

export default class SessionsForWeekColCardViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _key
  /** @type {StateInformationViewModel} */
  _stateInformation
  /** @type {CardContentItemViewModel} */
  _placesAvailable
  /** @type {CardContentItemViewModel} */
  _description
  /** @type {CardContentItemViewModel} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {SessionsForWeekDomainModel} domainModel
   */
  constructor (localization, domainModel) {
    const col = new ColViewModel(Constants.CssClass.col)
    const card = new CardViewModel(undefined, true)
    super(undefined, col, card)

    this._key = domainModel.id

    const containsReservation = !!domainModel.reservation
    let domainStatus
    if (containsReservation) {
      domainStatus = domainModel.reservation.status
    }
    const status = PresentationStatus.fromDomain(domainStatus)
    const state = PresentationStatus.state(status)
    const stateColor = PresentationState.color(state)

    // Header
    const startAtDayString = String.capitalizeFirstLetter(DateFormat.string(domainModel.startAt, DateFormat.DDDD))
    const startAtTimeString = DateFormat.string(domainModel.startAt, DateFormat.HH_MM)
    const startAtTitle = startAtDayString + String.space + localization.getText(Constants.Text.sessionStart, startAtTimeString)
    this._stateInformation = new StateInformationViewModel(stateColor, startAtTitle)

    // Place available
    const placesAvailableValue = domainModel.placesAvailable
    const placesAvailableKey = Constants.Text.placeAvailable
    const placesAvailablePlural = (placesAvailableValue > 1)
    const placesAvailable = localization.getPluralText(placesAvailableKey, placesAvailablePlural, [placesAvailableValue])
    this._placesAvailable = new CardContentItemViewModel(
      true,
      undefined,
      placesAvailable,
      undefined,
      Constants.CssClass.fontPlaceAvailable
    )

    // Description
    const description = domainModel.description
    this._description = new CardContentItemViewModel(!!description, PresentationSpace.CLASSIC, description)

    // Pattern
    let pattern
    if (containsReservation) {
      pattern = domainModel.reservation.pattern
    }
    const patternTopSpace = !description ? PresentationSpace.CLASSIC : undefined
    const patternClasses = String.fromArray([
      Constants.CssClass.fontPattern,
      stateColor + String.textColorSuffix
    ])
    this._pattern = new CardContentItemViewModel(!!pattern, patternTopSpace, pattern, null, patternClasses)
  }

  // MARK: - Getter

  /** @return {string} */
  get key () {
    return this._key
  }

  /** @return {StateInformationViewModel} */
  get stateInformation () {
    return this._stateInformation
  }

  /** @return {CardContentItemViewModel} */
  get placesAvailable () {
    return this._placesAvailable
  }

  /** @return {CardContentItemViewModel} */
  get description () {
    return this._description
  }

  /** @return {CardContentItemViewModel} */
  get pattern () {
    return this._pattern
  }
}
