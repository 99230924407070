<template>
  <custom-col-card
    :viewModel="viewModel"
    @click="clicked()">
    <!-- State value -->
    <custom-state-information :viewModel="viewModel.stateInformation"/>

    <!-- Place available -->
    <custom-content-item :view-model="viewModel.placesAvailable" />

    <!-- Description (optional) -->
    <custom-content-item :view-model="viewModel.description"/>

    <!-- Pattern (optional) -->
    <custom-content-item :view-model="viewModel.pattern"/>

  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import CardContentItemComponent from '@/app/common/component/card/CardContentItemComponent'
import StateInformationComponent from '@/app/common/component/state-information/StateInformationComponent'
import SessionsForWeekColCardViewModel from '@/presentation/scene/sessions-for-week/view-model/sessionsForWeekColCardViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'SessionsForWeekColCardComponent',

  components: {
    customColCard: ColCardComponent,
    customContentItem: CardContentItemComponent,
    customStateInformation: StateInformationComponent
  },

  props: {
    viewModel: {
      type: SessionsForWeekColCardViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
