export default class DashboardController {
  // MARK: - Properties

  /** @type {DashboardInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  // MARK: - Methods

  registerToPushTapped () {
    this._interactor.registerToPushTappedFromController()
  }

  refreshTapped () {
    this._interactor.refreshTappedFromController()
  }
}
