<template>
  <custom-col :viewModel="viewModel.col">
    <!-- Space -->
    <custom-space
      v-if="viewModel.showTopSpace"
      :view-model="viewModel.topSpace"
    />

    <!-- Text -->
    <div :class="viewModel.classes">
      {{ viewModel.text }}
    </div>
  </custom-col>
</template>

<script>
import ColComponent from '@/app/common/component/col/ColComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import DashboardSectionViewModel from '@/presentation/scene/dashboard/view-model/dashboardSectionViewModel'

export default {
  name: 'DashboardSectionComponent',

  components: {
    customCol: ColComponent,
    customSpace: SpaceComponent
  },

  props: {
    viewModel: {
      type: DashboardSectionViewModel,
      required: true
    }
  }
}
</script>
