<template>
  <custom-col-card
    :viewModel="viewModel"
    @click="clicked()"
  >

    <!-- Text link value -->
    <custom-text-link :view-model="viewModel.textlink"/>

  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import TextLinkComponent from '@/app/common/component/text-link/TextLinkComponent'
import DashboardColCardMessageViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardMessageViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'DashboardColCardMessageComponent',

  components: {
    customColCard: ColCardComponent,
    customTextLink: TextLinkComponent
  },

  props: {
    viewModel: {
      type: DashboardColCardMessageViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
