import PasswordAPITarget from '@/data/api/target/passwordAPITarget'
import DataError from '@/data/error/dataError'

export default class PasswordDataRepository {
  // MARK: - Properties

  /** @type {PasswordAPITarget} */
  _apiTarget

  // MARK: - Constructor

  constructor () {
    this._apiTarget = new PasswordAPITarget()
  }

  // MARK: - Getter

  /**
   * Init password
   * @param {PasswordInitDataEntry} parameter
    */
  init (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      self._apiTarget.init(parameter.toAPIBody).then(_ => {
        resolve()
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }

  /**
   * Ask new password
   * @param {PasswordForgotDataEntry} parameter
   */
  forgot (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      self._apiTarget.forgot(parameter.toAPIBody).then(_ => {
        resolve()
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }

  /**
   * Update current password
   * @param {PasswordUpdateDataEntry} parameter
   */
  update (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      self._apiTarget.update(parameter.toAPIBody).then(_ => {
        resolve()
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }
}
