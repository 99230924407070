import ColViewModel from '@/presentation/view-model/col/colViewModel'
import CardLoaderViewModel from '@/presentation/view-model/card/cardLoaderViewModel'
import String from '@/presentation/utils/string'
import PresentationCard from '@/presentation/common/enum/presentationCard'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'

// MARK: - Constants

class Constants {
  static defaultType = PresentationCard.DEFAULT
  static defaultShowLoader = false
}

// MARK: - Class

export default class ColCardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _key
  /** @type {boolean} */
  _showLoader
  /** @type {CardLoaderViewModel} */
  _loader
  /** @type {ColViewModel} */
  _col
  /** @type {CardViewModel} */
  _card

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} cardBackgroundColor
   * @param {ColViewModel} col
   * @param {CardViewModel|null} card
   */
  constructor (cardBackgroundColor = PresentationCard.backgroundColor(Constants.defaultType),
    col = new ColViewModel(),
    card = null) {
    this._key = String.uuid()
    this._showLoader = Constants.defaultShowLoader
    this._loader = new CardLoaderViewModel()
    this._col = col
    if (card) {
      this._card = card
    } else {
      this._card = new CardViewModel(cardBackgroundColor)
    }
  }

  // MARK: - Getter

  /** @return {string} */
  get key () {
    return this._key
  }

  /** @return {boolean} */
  get showContent () {
    return !this._showLoader
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {CardLoaderViewModel} */
  get loader () {
    return this._loader
  }

  /** @return {ColViewModel} */
  get col () {
    return this._col
  }

  /** @return {CardViewModel} */
  get card () {
    return this._card
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }
}
