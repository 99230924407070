import PasswordUpdateViewModel from '@/presentation/scene/password-update/passwordUpdateViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import Router from '@/presentation/utils/router'
import Route from '@/app/utils/route'
import ContentPresenter from '@/presentation/utils/contentPresenter'

export default class PasswordUpdatePresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {PasswordUpdateViewModel} */
  _viewModel
  /** @type {Router} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    super()
    this._localization = new Localization(vuetify)
    this._validation = validation
    this._viewModel = new PasswordUpdateViewModel(vuetify, this._localization, validation)
    this._router = new Router(router)
  }

  // MARK: - Getter

  /** @return {PasswordUpdateViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return PasswordUpdateViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  showTextFieldsErrorFromInteractor () {
    this._validation.password.$touch()
    this._validation.newPassword.$touch()
    this._validation.confirmPassword.$touch()
  }

  prepareProfileFromInteractorFromInteractor () {
    this._router.backTo(Route.profilePath)
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.error = presentationError.toInformation
  }
}
