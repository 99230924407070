import DashboardReservationSessionDomainModel from '@/domain/scene/dashboard/model/dashboardReservationSessionDomainModel'

export default class DashboardReservationDomainModel {
  // MARK: - Properties

  /** @type {DashboardReservationSessionDomainModel} */
  _session
  /** @type {int} */
  _status
  /** @type {string|null} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardReservationDataModel} dataModel
   */
  constructor (dataModel) {
    this._session = new DashboardReservationSessionDomainModel(dataModel.session)
    this._status = dataModel.status
    this._pattern = dataModel.pattern
  }

  // MARK: - Getter

  /** @return {DashboardReservationSessionDomainModel} */
  get session () {
    return this._session
  }

  /** @return {int} */
  get status () {
    return this._status
  }

  /** @return {string|null} */
  get pattern () {
    return this._pattern
  }
}
