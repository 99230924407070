<template>
  <custom-col :viewModel="viewModel.col">
    <!-- Card component -->
    <custom-card
      v-if="viewModel.showContent"
      :view-model="viewModel.card"
      @click="clicked()">
      <!-- Slot needed to put content here -->
      <slot></slot>
    </custom-card>

    <!-- Loader component -->
    <custom-card-loader
      v-if="viewModel.showLoader"
      :viewModel="viewModel.loader"
    />
  </custom-col>
</template>

<script>
import ColComponent from '@/app/common/component/col/ColComponent'
import CardLoaderComponent from '@/app/common/component/card/CardLoaderComponent'
import CardComponent from '@/app/common/component/card/CardComponent'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'ColCardComponent',

  components: {
    customCol: ColComponent,
    customCard: CardComponent,
    customCardLoader: CardLoaderComponent
  },

  props: {
    viewModel: {
      type: ColCardViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
