import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import PresentationCard from '@/presentation/common/enum/presentationCard'

// MARK: - Constants

class Constants {
  static defaultType = PresentationCard.DEFAULT
  static defaultText = String.dashSpace
  static valueClasses = 'text-h5 text-center ' + FontWeight.medium
  static descriptionClasses = 'text-subtitle-2 text-center' + FontWeight.italic
  static thousand = 'k'
  static colClasses = 'py-0 my-1'
}

// MARK: - Class

export default class DashboardColCardValueViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _value
  /** @type {string} */
  _valueClasses
  /** @type {string} */
  _description
  /** @type {string} */
  _descriptionClasses

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    const type = Constants.defaultType
    const col = new ColViewModel(Constants.colClasses, 6, 3, 3)
    super(PresentationCard.backgroundColor(type), col)

    this._value = Constants.defaultText
    this._valueClasses = Constants.valueClasses + String.space + PresentationCard.valueColor(type)
    this._description = Constants.defaultText
    this._descriptionClasses = Constants.descriptionClasses + String.space + PresentationCard.textColor()
  }

  // MARK: - Getter

  /** @return {string} */
  get value () {
    return this._value
  }

  /** @return {string} */
  get valueClasses () {
    return this._valueClasses
  }

  /** @return {string} */
  get description () {
    return this._description
  }

  /** @return {string} */
  get descriptionClasses () {
    return this._descriptionClasses
  }

  // MARK: - Setter

  /** @param {int} value */
  set value (value) {
    let completeValue
    if (value > 1000) {
      completeValue = (value / 1000).toFixed(1).toString() + Constants.thousand
    } else {
      completeValue = value.toString()
    }

    this._value = completeValue
  }

  /** @param {string} value */
  set description (value) {
    this._description = value.toLowerCase()
  }
}
