import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import FontWeight from '@/presentation/utils/fontWeight'

// MARK: - Constants

class Constants {
  static Classes = {
    name: FontWeight.medium,
    value: 'float-right' + FontWeight.regular
  }

  static space = PresentationSpace.TINY
}

// MARK: - Class

export default class CardContentItemViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _show
  /** @type {SpaceViewModel} */
  _space
  /** @type {boolean} */
  _showName
  /** @type {string} */
  _name
  /** @type {string} */
  _nameClasses
  /** @type {boolean} */
  _showValue
  /** @type {string|null} */
  _value
  /** @type {string} */
  _valueClasses

  // MARK: - Constructor

  /**
   * @constructor
   * @param {boolean} show
   * @param {string} name
   * @param {string|null} value
   * @param {string} nameClasses
   * @param {string} valueClasses
   * @param {int} space
   */
  constructor (show = true,
    space = Constants.space,
    name,
    value = null,
    nameClasses = Constants.Classes.name,
    valueClasses = Constants.Classes.value
  ) {
    this._show = show
    this._space = new SpaceViewModel(space)
    this._showName = !!name
    this._name = name
    this._nameClasses = nameClasses
    this._showValue = !!value
    this._value = value
    this._valueClasses = valueClasses
  }

  // MARK: - Getter

  /** @return {boolean} */
  get show () {
    return this._show
  }

  /** @return {SpaceViewModel} */
  get space () {
    return this._space
  }

  /** @return {boolean} */
  get showName () {
    return this._showName
  }

  /** @return {string} */
  get name () {
    return this._name
  }

  /** @return {string} */
  get nameClasses () {
    return this._nameClasses
  }

  /** @return {boolean} */
  get showValue () {
    return this._showValue
  }

  /** @return {string|null} */
  get value () {
    return this._value
  }

  /** @return {string} */
  get valueClasses () {
    return this._valueClasses
  }
}
