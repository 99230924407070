import ButtonViewModel from '@/presentation/view-model/button/buttonViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static color = Color.grayLight
  static classes = 'text-center'
  static small = true
  static text = true
  static justifyCenter = true
}

// MARK: - Class

export default class ButtonSmallViewModel extends ButtonViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} titleKey
   */
  constructor (localization, titleKey) {
    super(localization.getText(titleKey),
      Constants.color,
      Constants.classes,
      Constants.small,
      Constants.text,
      Constants.justifyCenter
    )
  }
}
