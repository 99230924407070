import DomainError from '@/domain/error/domainError'
import PasswordInitDomainEntry from '@/domain/scene/password-init/passwordInitDomainEntry'

export default class PasswordInitInteractor {
  // MARK: - Properties

  /** @type {PasswordDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {PasswordInitPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {PasswordDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {PasswordInitPresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = false

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false
  }

  // MARK: - From Controller

  // Init client password from repository
  validateTappedFromController () {
    // Form is valid ?
    if (!this._validation.$invalid) {
      // Show loader
      this._presenter.showLoaderFromInteractor = true

      const self = this
      const entry = new PasswordInitDomainEntry(this._validation)
      this._repository.init(entry.toData).then(function () {
        // Send success to presenter
        self._presenter.prepareSuccessFromInteractor(true)

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      }).catch(e => {
        // Send error to presenter
        self._presenter.prepareErrorFromInteractor = new DomainError(e)

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      })
    } else {
      // Show error on all invalids text fields
      this._presenter.showTextFieldsErrorFromInteractor()
    }
  }

  // Redirect to login vue
  connexionTappedFromController () {
    this._presenter.prepareLoginFromInteractor()
  }
}
