import ObjectAPIResponse from '@/data/api/response/objectAPIResponse'

export default class TokenAPIResponse {
  // MARK: - Properties

  /** @type {string|null} */
  _token
  /** @type {string|null} */
  _refreshToken
  /** @type {ObjectAPIResponse} */
  _content

  // MARK: - Constructor

  constructor (response) {
    this._token = response.token
    this._refreshToken = response.refreshToken
    this._content = new ObjectAPIResponse(response)
  }

  // MARK: - Getter

  /** @return {boolean} */
  get isValid () {
    return this._token !== null && this._refreshToken !== null && this._content.isValid
  }

  /** @return {string} */
  get token () {
    return this._token
  }

  /** @return {string} */
  get refreshToken () {
    return this._refreshToken
  }

  /** @return {ObjectAPIResponse} */
  get content () {
    return this._content
  }
}
