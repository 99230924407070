import DataErrorType from '@/data/error/dataErrorType'
import DomainErrorType from '@/domain/error/domainErrorType'

// MARK: - Class

export default class DomainError {
  // MARK: - Properties

  /** @type {int} */
  _type
  /** @type {string|null} */
  _message
  /** @type {boolean} */
  _redirectToLogin = false

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DataError} dataModel
   */
  constructor (dataModel) {
    switch (dataModel.type) {
      case DataErrorType.TOKEN:
        this._type = DomainErrorType.TOKEN
        this._redirectToLogin = true
        break
      case DataErrorType.BAD_CREDENTIALS:
        this._type = DomainErrorType.BAD_CREDENTIALS
        break
      case DataErrorType.ACCOUNT_DISABLED:
        this._type = DomainErrorType.ACCOUNT_DISABLED
        break
      case DataErrorType.REFRESH_TOKEN:
        this._type = DomainErrorType.REFRESH_TOKEN
        this._redirectToLogin = true
        break
      case DataErrorType.UNDECODABLE:
        this._type = DomainErrorType.UNDECODABLE
        break
      case DataErrorType.UNKNOW:
        this._type = DomainErrorType.UNKNOW
        break
      default:
        return null
    }
    this._message = dataModel.message
  }

  // MARK: - Getter

  /** @return {int} */
  get type () {
    return this._type
  }

  /** @return {string|null} */
  get message () {
    return this._message
  }

  /** @return {boolean} */
  get redirectToLogin () {
    return this._redirectToLogin
  }
}
