import Delay from '@/presentation/utils/delay'

// MARK: - Constants

class Constants {
  static showContent = false
  static showMain = false
}

// MARK: - Class

export default class PopupVisibilityViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _showMain
  /** @type {boolean} */
  _showContent

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._showMain = Constants.showMain
    this._showContent = Constants.showContent
  }

  // MARK: - Getter

  /** @return {boolean} */
  get showMain () {
    return this._showMain
  }

  /** @return {boolean} */
  get showContent () {
    return this._showContent
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set show (value) {
    this._showContent = value

    // Hide main div after delay. Show main div without delay
    if (!value) {
      const self = this
      setTimeout(function () {
        self._showMain = value
      }, Delay.normal)
    } else {
      this._showMain = value
    }
  }
}
