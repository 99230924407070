import NotFoundDomainInjection from '@/domain/scene/not-found/notFoundDomainInjection'
import NotFoundController from '@/presentation/scene/not-found/notFoundController'
import NotFoundPresenter from '@/presentation/scene/not-found/notFoundPresenter'

export default class NotFoundPresentationInjection {
  // MARK: - Properties

  /** @type {NotFoundController} */
  _controller
  /** @type {NotFoundPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {VueRouter} router
   */
  constructor (vuetify, router) {
    this._presenter = new NotFoundPresenter(vuetify, router)

    // Domain
    const domainInjection = new NotFoundDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new NotFoundController(interactor)
  }

  // MARK: - Getter

  /** @return {NotFoundController} */
  get controller () {
    return this._controller
  }

  /** @return {NotFoundPresenter} */
  get presenter () {
    return this._presenter
  }
}
