import PasswordDataRepository from '@/data/repository/password/passwordDataRepository'
import PasswordInitInteractor from '@/domain/scene/password-init/passwordInitInteractor'

export default class PasswordInitDomainInjection {
  // MARK: - Properties

  /** @type {PasswordInitInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate
   * @param {PasswordInitPresenter} presenter
   */
  constructor (validation, presenter) {
    const repository = new PasswordDataRepository()
    this._interactor = new PasswordInitInteractor(repository, validation, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {PasswordInitInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
