import DomainErrorType from '@/domain/error/domainErrorType'
import PresentationInformation from '@/presentation/common/information/presentationInformation'
import PresentationErrorType from '@/presentation/common/enum/presentationError'

// MARK: - Constants

class Constants {
  static titleKey = 'error_title'
  static badCredentialsText = 'error_login_bad_credentials'
  static accountDisabledText = 'error_account_disabled'
  static refreshTokenText = 'error_token_expired'
  static unknowText = 'error_unknown'
}

// MARK: - Class

export default class PresentationError {
  // MARK: - Properties

  /** @type {string} */
  _title
  /** @type {string} */
  _message
  /** @type {boolean} */
  _redirectToLogin

  // MARK: - Constructor
  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} message
   * @param {boolean} redirectToLogin
   */
  constructor (localization, message, redirectToLogin) {
    this._title = localization.getText(Constants.titleKey)
    this._message = message
    this._redirectToLogin = redirectToLogin
  }

  /**
   * @param {Localization} localization
   * @param {DomainError} domainModel
   * @return {PresentationError}
   */
  static fromDomain (localization, domainModel) {
    let message
    let redirectToLogin = false
    if (domainModel !== null) {
      switch (domainModel.type) {
        case DomainErrorType.BAD_CREDENTIALS:
          message = localization.getText(Constants.badCredentialsText)
          break
        case DomainErrorType.ACCOUNT_DISABLED:
          message = localization.getText(Constants.accountDisabledText)
          break
        case DomainErrorType.REFRESH_TOKEN:
          message = localization.getText(Constants.refreshTokenText)
          break
        case DomainErrorType.UNDECODABLE:
          message = localization.getText(Constants.badCredentialsText)
          break
        default:
          if (domainModel.message) {
            message = domainModel.message
          } else {
            message = localization.getText(Constants.unknowText)
          }
          break
      }
      redirectToLogin = domainModel.redirectToLogin
    } else {
      message = localization.getText(Constants.unknowText)
    }

    return new PresentationError(
      localization,
      message,
      redirectToLogin
    )
  }

  /**
   * @constructor
   * @param {Localization} localization
   * @param {int} type corresponding to PresentationErrorType
   * @param {boolean} redirectToLogin redirect to login after close ?
   * @return {PresentationError}
   */
  static fromType (localization, type, redirectToLogin = false) {
    return new PresentationError(
      localization,
      localization.getText(PresentationErrorType.key(type)),
      redirectToLogin
    )
  }

  // MARK: - Getter

  /** @return {PresentationInformation} */
  get toInformation () {
    return new PresentationInformation(this._title, this._message, this._redirectToLogin)
  }
}
