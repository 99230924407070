// MARK: - Constants

class Constants {
  static bottomSheetPersistent = true

  static Sheet = {
    classes: 'text-center mx-auto my-2',
    height: 'auto',
    width: '95%',
    maxWidth: '400px',
    rounded: 'lg'
  }
}

// MARK: - Class

export default class SheetStructureViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _bottomSheetPersistent
  /** @type {string} */
  _sheetClasses
  /** @type {string} */
  _sheetHeight
  /** @type {string} */
  _sheetWidth
  /** @type {string} */
  _sheetMaxWidth
  /** @type {string} */
  _sheetRounded

  // MARK: - Constructor

  constructor () {
    this._bottomSheetPersistent = Constants.bottomSheetPersistent
    this._sheetClasses = Constants.Sheet.classes
    this._sheetHeight = Constants.Sheet.height
    this._sheetWidth = Constants.Sheet.width
    this._sheetMaxWidth = Constants.Sheet.maxWidth
    this._sheetRounded = Constants.Sheet.rounded
  }

  // MARK: - Getter

  /** @return {boolean} */
  get bottomSheetPersistent () {
    return this._bottomSheetPersistent
  }

  /** @return {string} */
  get sheetClasses () {
    return this._sheetClasses
  }

  /** @return {string} */
  get sheetHeight () {
    return this._sheetHeight
  }

  /** @return {string} */
  get sheetWidth () {
    return this._sheetWidth
  }

  /** @return {string} */
  get sheetMaxWidth () {
    return this._sheetMaxWidth
  }

  /** @return {string} */
  get sheetRounded () {
    return this._sheetRounded
  }
}
