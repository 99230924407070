export default class ReservationsController {
  // MARK: - Properties

  /** @type {ReservationsInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationsInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  // MARK: - Methods

  refreshTapped () {
    this._interactor.refreshTappedFromController()
  }
}
