import PasswordDataRepository from '@/data/repository/password/passwordDataRepository'
import PasswordUpdateInteractor from '@/domain/scene/password-update/passwordUpdateInteractor'

export default class PasswordUpdateDomainInjection {
  // MARK: - Properties

  /** @type {PasswordUpdateInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate
   * @param {PasswordUpdatePresenter} presenter
   */
  constructor (validation, presenter) {
    const repository = new PasswordDataRepository()
    this._interactor = new PasswordUpdateInteractor(repository, validation, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {PasswordUpdateInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
