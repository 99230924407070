import Localization from '@/presentation/utils/localization'
import Route from '@/app/utils/route'
import { Bus, Events } from '@/presentation/main'
import SheetViewModel from '@/presentation/view-model/sheet/sheetViewModel'
import String from '@/presentation/utils/string'
import Delay from '@/presentation/utils/delay'

// MARK: - Constants

class Constants {
  static TextKeys = {
    title: String.empty,
    description: String.empty
  }

  static Buttons = {
    closeTitleKey: 'close'
  }
}

// MARK: - Class

export default class AlertViewModel extends SheetViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {VueRouter} */
  _router
  /** @type {PresentationInformation|null} */
  _information

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {VueRouter} router
   */
  constructor (vuetify, router) {
    const localization = new Localization(vuetify)

    super(localization,
      Constants.TextKeys.title,
      Constants.TextKeys.description,
      Constants.Buttons.closeTitleKey
    )

    this._localization = localization
    this._router = router
    this._information = null
  }

  // MARK: - Setter

  /** @param {PresentationInformation} value */
  set information (value) {
    this._information = value
    this.show = true

    this._title.text = value.title
    this._description.text = value.message
  }

  // MARK: - Actions

  closeAction () {
    // Redirect to login ?
    if (this._information.redirectToLogin) {
      this._router.push(Route.loginVue)
    } else {
      // Hide sheet
      this.show = false

      setTimeout(function () {
        // Send status for App
        Bus.$emit(Events.alertClosed, true)
      }, Delay.normal)
    }
  }
}
