import PasswordForgotDataEntry from '@/data/repository/password/entry/passwordForgotDataEntry'

export default class PasswordForgotDomainEntry {
  // MARK: - Properties

  /** @type {string} */
  _email

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate which contains textfields
   */
  constructor (validation) {
    this._email = validation.email.$model
  }

  // MARK: - Conversion

  /**
   * Convert object to Data entry model
   * @return {PasswordForgotDataEntry}
   */
  get toData () {
    return new PasswordForgotDataEntry(this._email)
  }
}
