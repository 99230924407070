import ListContainerViewModel from '@/presentation/view-model/container/listContainerViewModel'
import RowViewModel from '@/presentation/view-model/row/rowViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import CardLoaderViewModel from '@/presentation/view-model/card/cardLoaderViewModel'
import SceneRefreshViewModel from '@/presentation/view-model/scene/sceneRefreshViewModel'

// MARK: - Constants

class Constants {
  static colClasses = 'px-1 py-0'
}

// MARK: - Class

export default class SceneCardViewModel extends SceneRefreshViewModel {
  // MARK: - Properties

  /** @type {string} */
  _title
  /** @type {ListContainerViewModel} */
  _container
  /** @type {RowViewModel} */
  _row
  /** @type {boolean} */
  _showLoader
  /** @type {CardLoaderViewModel} */
  _loader
  /** @type {PresentationInformation|null} */
  _information

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} titleKey
   * @param {int} numberOfLoaderCards
   * @param {int} loaderCardHeight
   */
  constructor (localization,
    titleKey,
    numberOfLoaderCards,
    loaderCardHeight) {
    super(localization)

    this._title = localization.getText(titleKey)
    this._container = new ListContainerViewModel()
    this._row = new RowViewModel()
    this._col = new ColViewModel(Constants.colClasses)
    this._showLoader = false
    this._loader = new CardLoaderViewModel(numberOfLoaderCards, loaderCardHeight)
    this._information = null
  }

  // MARK: - Getter

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {ListContainerViewModel} */
  get container () {
    return this._container
  }

  /** @return {RowViewModel} */
  get row () {
    return this._row
  }

  /** @return {ColViewModel} */
  get col () {
    return this._col
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {CardLoaderViewModel} */
  get loader () {
    return this._loader
  }

  /** @return {PresentationInformation|null} */
  get information () {
    return this._information
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }

  /** @param {PresentationInformation} value */
  set information (value) {
    this._information = value
  }
}
