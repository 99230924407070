export default class LegalNoticesController {
  // MARK: - Properties

  /** @type {LegalNoticesInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LegalNoticesInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Methods

  /** @param {boolean} value */
  showCalled (value) {
    this._interactor.showCalledFromController(value)
  }

  closeTapped () {
    this._interactor.closeTappedFromController()
  }
}
