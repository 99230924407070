import RouteDataRepository from '@/data/repository/router/routeDataRepository'

export default class RouteDomain {
  // MARK: - Properties

  _dataRepository

  // MARK: - Constructor

  constructor () {
    this._dataRepository = new RouteDataRepository()
  }

  // MARK: - Getter

  get containsToken () {
    return this._dataRepository.containsToken
  }
}
