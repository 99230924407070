import Moment from 'moment'
import DAOWrapper from '@/data/data-provider/wrapper/daoWrapper'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import Secure from '@/data/utils/secure'

// MARK: - Class

export default class ObjectDataProviderWorker {
  // MARK: - Properties

  _wrapper
  _expirationWrapper

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} type corresponding to DataProviderType
   */
  constructor (type) {
    const key = DataProviderType.key(type)
    this._wrapper = new DAOWrapper(key)

    const expirationKey = DataProviderType.expirationKey(type)
    this._expirationWrapper = new DAOWrapper(expirationKey)
  }

  // MARK: - Getter

  get get () {
    try {
      const decode = Secure.decode(this._wrapper.get)
      return JSON.parse(decode)
    } catch (_) {
      return null
    }
  }

  get isExpired () {
    const expiredAt = this._expirationWrapper.get
    if (expiredAt) {
      const now = Moment()
      return now > Moment(expiredAt)
    }
    return true
  }

  // MARK: - Setter

  /**
   * @param {any} value to save on dao
   * @param {string?} expiredAt data for value to save on dao
   **/
  save (value, expiredAt) {
    // Value is array and array is empty ? Don't save value !
    if (Array.isArray(value) && value.length === 0) {
      return
    }

    if (value && expiredAt) {
      this._save(value)
      this._expirationWrapper.save(expiredAt)
    }
  }

  /**
   * @param {any} value to save on dao
   **/
  update (value) {
    if (value) {
      this._save(value)
    }
  }

  remove () {
    this._wrapper.remove()
    this._expirationWrapper.remove()
  }

  /**
   * @param {any} value to save on dao
   **/
  _save (value) {
    const data = JSON.stringify(value)
    const Encode = Secure.encode(data)
    this._wrapper.save(Encode)
  }
}
