import DashboardSectionViewModel from '@/presentation/scene/dashboard/view-model/dashboardSectionViewModel'
import DashboardColCardMessageViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardMessageViewModel'
import DashboardColCardValueViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardValueViewModel'
import DashboardColCardReservationViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardReservationViewModel'
import DashboardMessageType from '@/presentation/scene/dashboard/enum/dashboardMessageType'
import DashboardSectionType from '@/presentation/scene/dashboard/enum/dashboardSectionType'

// MARK: - Constants

class Constants {
  static titleKey = 'my_reservations'
  static statsKeys = {
    participated: 'participated',
    canceled: 'canceled',
    ofYear: 'of_year',
    ofMonth: 'of_month'
  }

  static subtitleKey = 'next_reservation'
}

// MARK: - Class

export default class DashboardReservationsViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization

  /** @type {DashboardSectionViewModel} */
  _title
  /** @type {DashboardColCardValueViewModel} */
  _participated
  /** @type {DashboardColCardValueViewModel} */
  _canceled
  /** @type {DashboardColCardValueViewModel} */
  _ofYear
  /** @type {DashboardColCardValueViewModel} */
  _ofMonth

  /** @type {DashboardSectionViewModel} */
  _subtitle
  /** @type {boolean} */
  _showNextReservation
  /** @type {DashboardColCardReservationViewModel} */
  _nextReservation
  /** @type {boolean} */
  _showNoNextReservation
  /** @type {DashboardColCardMessageViewModel} */
  _noNextReservation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    this._localization = localization

    this._title = new DashboardSectionViewModel(localization.getText(Constants.titleKey))
    this._participated = new DashboardColCardValueViewModel()
    this._canceled = new DashboardColCardValueViewModel()
    this._ofYear = new DashboardColCardValueViewModel()
    this._ofMonth = new DashboardColCardValueViewModel()

    this._subtitle = new DashboardSectionViewModel(localization.getText(Constants.subtitleKey), DashboardSectionType.SUBTITLE)
    this._showNextReservation = false
    this._nextReservation = new DashboardColCardReservationViewModel()
    this._showNoNextReservation = false

    // No next reservation ?
    const noNextReservationType = DashboardMessageType.NO_NEXT_RESERVATION
    this._noNextReservation = new DashboardColCardMessageViewModel(DashboardMessageType.toCard(noNextReservationType))
    this._noNextReservation.message = localization.getText(DashboardMessageType.messageKey(noNextReservationType))
  }

  // MARK: - Getter

  /** @return {DashboardSectionViewModel} */
  get title () {
    return this._title
  }

  /** @return {DashboardColCardValueViewModel} */
  get participated () {
    return this._participated
  }

  /** @return {DashboardColCardValueViewModel} */
  get canceled () {
    return this._canceled
  }

  /** @return {DashboardColCardValueViewModel} */
  get ofYear () {
    return this._ofYear
  }

  /** @return {DashboardColCardValueViewModel} */
  get ofMonth () {
    return this._ofMonth
  }

  /** @return {DashboardSectionViewModel} */
  get subtitle () {
    return this._subtitle
  }

  /** @return {boolean} */
  get showNextReservation () {
    return this._showNextReservation && !this._noNextReservation.showLoader
  }

  /** @return {DashboardColCardReservationViewModel} */
  get nextReservation () {
    return this._nextReservation
  }

  /** @return {boolean} */
  get showNoNextReservation () {
    return this._showNoNextReservation || this._noNextReservation.showLoader
  }

  /** @return {DashboardColCardMessageViewModel} */
  get noNextReservation () {
    return this._noNextReservation
  }

  // MARK: - Setter

  /** @param {DashboardDomainModel} domainModel */
  setValues (domainModel) {
    // Participating session
    this._participated.value = domainModel.participatingSession
    this._participated.description = this._localization.getPluralText(Constants.statsKeys.participated,
      (domainModel.participatingSession || 0) > 0)

    // Canceled session
    this._canceled.value = domainModel.canceledReservations
    this._canceled.description = this._localization.getPluralText(Constants.statsKeys.canceled,
      (domainModel.canceledReservations || 0) > 0)

    // Of year
    this._ofYear.value = domainModel.yearReservations
    this._ofYear.description = this._localization.getText(Constants.statsKeys.ofYear)

    // Of month
    this._ofMonth.value = domainModel.monthReservations
    this._ofMonth.description = this._localization.getText(Constants.statsKeys.ofMonth)

    // Next reservation ?
    const isNextReservation = !!domainModel.nextReservation
    this._showNextReservation = isNextReservation
    this._showNoNextReservation = !isNextReservation
    if (domainModel.nextReservation) {
      this._nextReservation.stateInformation.setValues(this._localization, domainModel.nextReservation)
    }
  }

  /** @param {boolean} value */
  set showLoader (value) {
    this._participated.showLoader = value
    this._canceled.showLoader = value
    this._ofYear.showLoader = value
    this._ofMonth.showLoader = value

    this._noNextReservation.showLoader = value
  }
}
