import Vue from 'vue'

export const Bus = new Vue()

export class Events {
  static clicked = 'click'
  static closeButtonClicked = 'closeClick'

  static alertClosed = 'alertClosed'

  static navigationChanged = 'showNavigationChanged'
  static centerContentChanged = 'centerContentChanged'

  static closeForgotAsked = 'closeForgotAsked'
  static closeLogoutAsked = 'closeLogoutAsked'
  static closeReservationAsked = 'closeReservationAsked'
  static closeLegalNoticesAsked = 'closeLegalNoticesAsked'

  static sessionsForWeekChanged = 'sessionsForWeekChanged'

  static sendUserOnPushManager = 'sendUserOnPushManager'

  static nativeAppNotInstalled = 'beforeinstallprompt'
  static nativeAppInstalled = 'appinstalled'
}
