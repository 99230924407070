export default class SessionsForWeekDayDomainModel {
  // MARK: - Properties

  /** @type {moment.Moment} */
  _day
  /** @type {[SessionsForWeekDomainModel]} */
  _sessions

  // MARK: - Constructor

  /**
   * @constructor
   * @param {moment.Moment} day
   * @param {SessionsForWeekDomainModel} session
   */
  constructor (day, session) {
    this._day = day
    this._sessions = [session]
  }

  // MARK: - Getter

  /** @return {moment.Moment} */
  get day () {
    return this._day
  }

  /** @return {[SessionsForWeekDomainModel]} */
  get sessions () {
    return this._sessions
  }

  // MARK: - Setter

  /** @param {SessionsForWeekDomainModel} value */
  set session (value) {
    this._sessions.push(value)
  }
}
