<template>
  <div>
    <custom-skeleton
      v-for="index in viewModel.indexes"
      :key="index"
      :viewModel="viewModel">
    </custom-skeleton>
  </div>
</template>

<script>

import SkeletonComponent from '@/app/common/component/skeleton/SkeletonComponent'
import CardLoaderViewModel from '@/presentation/view-model/card/cardLoaderViewModel'

export default {
  name: 'CardLoaderComponent',

  components: {
    customSkeleton: SkeletonComponent
  },

  props: {
    viewModel: {
      type: CardLoaderViewModel,
      required: true
    }
  }
}
</script>
