<template>
  <div>

    <!-- App Bar -->
    <custom-app-bar :title="presenter.viewModel.title"/>

    <!-- Container -->
    <custom-container :viewModel="presenter.viewModel.container" >

      <!-- Row -->
      <custom-row :viewModel="presenter.viewModel.row">

        <!-- Col -->
        <custom-col :viewModel="presenter.viewModel.col">

          <!-- Cards -->
          <template v-if="presenter.viewModel.showCards">
            <custom-col-card
              v-for="card in presenter.viewModel.cards"
              :key="card.key"
              :view-model="card"
            />
          </template>

        </custom-col>
      </custom-row>
    </custom-container>

    <!-- Loader cards -->
    <custom-card-loader
      v-if="presenter.viewModel.showLoader"
      :viewModel="presenter.viewModel.loader"
    />

    <!-- Refresh -->
    <custom-refresh
      :view-model="presenter.viewModel"
      @click="controller.refreshTapped()"
    />

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.information"/>

  </div>
</template>

<script>
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import ContainerComponent from '@/app/common/component/container/ContainerComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import CardLoaderComponent from '@/app/common/component/card/CardLoaderComponent'
import SubscriptionsColCardComponent from '@/app/scene/subscriptions/component/SubscriptionsColCardComponent'
import RefreshComponent from '@/app/common/component/refresh/RefreshComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import PresentationInjection from '@/presentation/scene/subscriptions/subscriptionsPresentationInjection'

export default {
  name: 'SubscriptionsVue',

  components: {
    customAppBar: AppBarComponent,
    customContainer: ContainerComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customColCard: SubscriptionsColCardComponent,
    customCardLoader: CardLoaderComponent,
    customRefresh: RefreshComponent,
    customAlert: AlertComponent
  },

  data: () => ({
    /** @type SubscriptionsController */
    controller: null,
    /** @type SubscriptionsPresenter */
    presenter: null
  }),

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'subscriptions-style'
</style>
