import LegalNoticesDomainInjection from '@/domain/scene/legal-notices/legalNoticesDomainInjection'
import LegalNoticesController from '@/presentation/scene/legal-notices/legalNoticesController'
import LegalNoticesPresenter from '@/presentation/scene/legal-notices/legalNoticesPresenter'

export default class LegalNoticesPresentationInjection {
  // MARK: - Properties

  /** @type {LegalNoticesController} */
  _controller
  /** @type {LegalNoticesPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._presenter = new LegalNoticesPresenter(vuetify)

    // Domain
    const domainInjection = new LegalNoticesDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new LegalNoticesController(interactor)
  }

  // MARK: - Getter

  /** @return {LegalNoticesController} */
  get controller () {
    return this._controller
  }

  /** @return {LegalNoticesPresenter} */
  get presenter () {
    return this._presenter
  }
}
