<template>
  <div>

    <!-- App Bar -->
    <custom-app-bar :title="presenter.viewModel.title"/>

    <!-- Container -->
    <custom-container :viewModel="presenter.viewModel.container" >

      <!-- Row -->
      <custom-row :viewModel="presenter.viewModel.row">

        <!-- Sections -->
        <template v-if="presenter.viewModel.showSections">
          <custom-col
            v-for="section in presenter.viewModel.sections"
            :key="section.key"
            :viewModel="presenter.viewModel.col">

            <!-- Top space (optional) -->
            <custom-space
              v-if="section.showTopSpace"
              :view-model="section.topSpace"
            />

            <!-- Day -->
            <div :class="section.dayClasses">
              {{ section.day }}
            </div>

            <!-- Cards -->
            <custom-col-card
              v-for="card in section.cards"
              :key="card.key"
              :view-model="card"
              @click="controller.selectSession(card.key)"
            />
          </custom-col>
        </template>
      </custom-row>
    </custom-container>

    <!-- Loader cards -->
    <custom-card-loader
      v-if="presenter.viewModel.showLoader"
      :viewModel="presenter.viewModel.loader"
    />

    <!-- Refresh -->
    <custom-refresh
      :view-model="presenter.viewModel"
      @click="controller.refreshTapped()"
    />

    <!-- Reservation sheet -->
    <custom-reservation
      :show="presenter.viewModel.showReservation"
      :parameter="presenter.reservationParameter"
    />

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.information"/>

  </div>
</template>

<script>
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import ContainerComponent from '@/app/common/component/container/ContainerComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import CardLoaderComponent from '@/app/common/component/card/CardLoaderComponent'
import RefreshComponent from '@/app/common/component/refresh/RefreshComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import ReservationVue from '@/app/scene/reservation/ReservationVue'
import SessionsForWeekColCardComponent from '@/app/scene/sessions-for-week/component/SessionsForWeekColCardComponent'
import PresentationInjection from '@/presentation/scene/sessions-for-week/sessionsForWeekPresentationInjection'

export default {
  name: 'SessionsForWeekVue',

  components: {
    customAppBar: AppBarComponent,
    customContainer: ContainerComponent,
    customRow: RowComponent,
    customCol: ColComponent,
    customSpace: SpaceComponent,
    customColCard: SessionsForWeekColCardComponent,
    customRefresh: RefreshComponent,
    customCardLoader: CardLoaderComponent,
    customAlert: AlertComponent,
    customReservation: ReservationVue
  },

  data: () => ({
    /** @type SessionsForWeekController */
    controller: null,
    /** @type SessionsForWeekPresenter */
    presenter: null
  }),

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
  @import 'sessions-for-week-style'
</style>
