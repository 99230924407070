import Icon from '@/presentation/utils/icon'
import TextFieldEmailViewModel from '@/presentation/view-model/textfield/textFieldEmailViewModel'
import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'
import ButtonClassicResizeViewModel from '@/presentation/view-model/button/buttonClassicResizeViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import ContainerLayoutFlexCenterViewModel from '@/presentation/view-model/container-layout-flex/containerLayoutFlexCenterViewModel'
import TextFieldPasswordViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordViewModel'
import LoginLegalNoticesCheckboxViewModel
  from '@/presentation/scene/login/view-model/loginLegalNoticesCheckboxViewModel'

// MARK: - Constants

class Constants {
  static Icon = {
    size: '100%',
    name: Icon.logoTitle
  }

  static Buttons = {
    connexionTitleKey: 'connexion',
    forgotPasswordTitleKey: 'forgot_password_title'
  }
}

// MARK: - Class

export default class LoginViewModel {
  // MARK: - Properties

  /** @type {any} */
  _validation
  /** @type {ContainerLayoutFlexCenterViewModel} */
  _container
  /** @type {SpaceViewModel} */
  _iconBottomSpace
  /** @type {TextFieldEmailViewModel} */
  _emailTextField
  /** @type {SpaceViewModel} */
  _emailBottomSpace
  /** @type {TextFieldPasswordViewModel} */
  _passwordTextField
  /** @type {SpaceViewModel} */
  _passwordBottomSpace
  /** @type {LoginLegalNoticesCheckboxViewModel} */
  _legalNoticesCheckbox
  /** @type {SpaceViewModel} */
  _legalNoticesBottomSpace
  /** @type {ButtonClassicResizeViewModel} */
  _connexionButton
  /** @type {SpaceViewModel} */
  _connexionBottomSpace
  /** @type {ButtonSmallViewModel} */
  _forgotPasswordButton
  /** @type {boolean} */
  _showLoader
  /** @type {boolean} */
  _showLegalNotices
  /** @type {boolean} */
  _showPasswordForgot
  /** @type {PresentationInformation|null} */
  _error

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (vuetify, localization, validation) {
    this._validation = validation
    this._container = new ContainerLayoutFlexCenterViewModel(true)
    this._iconBottomSpace = new SpaceViewModel(PresentationSpace.BIG)
    this._emailTextField = new TextFieldEmailViewModel(localization, validation)
    this._emailBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._passwordTextField = new TextFieldPasswordViewModel(localization, validation)
    this._passwordBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._legalNoticesCheckbox = new LoginLegalNoticesCheckboxViewModel(localization)
    this._legalNoticesBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._connexionButton = new ButtonClassicResizeViewModel(vuetify, localization, Constants.Buttons.connexionTitleKey)
    this._connexionBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._forgotPasswordButton = new ButtonSmallViewModel(localization, Constants.Buttons.forgotPasswordTitleKey)
    this._showLoader = false
    this._showLegalNotices = false
    this._showPasswordForgot = false
    this._error = null
  }

  // MARK: - Getter

  /** @return {array} */
  static get textFieldsValidations () {
    return Object.assign(
      TextFieldEmailViewModel.validation,
      TextFieldPasswordViewModel.validation
    )
  }

  /** @return {ContainerLayoutFlexCenterViewModel} */
  get container () {
    return this._container
  }

  get icon () {
    return Constants.Icon
  }

  /** @return {SpaceViewModel} */
  get iconBottomSpace () {
    return this._iconBottomSpace
  }

  /** @return {TextFieldEmailViewModel} */
  get emailTextField () {
    return this._emailTextField
  }

  /** @return {SpaceViewModel} */
  get emailBottomSpace () {
    return this._emailBottomSpace
  }

  /** @return {TextFieldPasswordViewModel} */
  get passwordTextField () {
    return this._passwordTextField
  }

  /** @return {SpaceViewModel} */
  get passwordBottomSpace () {
    return this._passwordBottomSpace
  }

  /** @return {LoginLegalNoticesCheckboxViewModel} */
  get legalNoticesCheckbox () {
    return this._legalNoticesCheckbox
  }

  /** @return {SpaceViewModel} */
  get legalNoticesBottomSpace () {
    return this._legalNoticesBottomSpace
  }

  /** @return {ButtonClassicResizeViewModel} */
  get connexionButton () {
    return this._connexionButton
  }

  /** @return {SpaceViewModel} */
  get connexionBottomSpace () {
    return this._connexionBottomSpace
  }

  /** @return {boolean} */
  get showLegalNotices () {
    return this._showLegalNotices
  }

  /** @return {ButtonSmallViewModel} */
  get forgotPasswordButton () {
    return this._forgotPasswordButton
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {boolean} */
  get showPasswordForgot () {
    return this._showPasswordForgot
  }

  /** @return {PresentationInformation|null} */
  get error () {
    return this._error
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }

  /** @param {boolean} value */
  set showLegalNotices (value) {
    this._showLegalNotices = value
  }

  /** @param {boolean} value */
  set showPasswordForgot (value) {
    this._showPasswordForgot = value
  }

  /** @param {PresentationInformation} value */
  set error (value) {
    this._error = value
  }
}
