export default class ReservationDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _sessionId
  /** @type {moment.Moment} */
  _sessionStartAt
  /** @type {int} corresponding to DomainStatus */
  _status

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} sessionId
   * @param {moment.Moment} sessionStartAt
   * @param {int} status corresponding to DomainStatus
   */
  constructor (sessionId, sessionStartAt, status) {
    this._sessionId = sessionId
    this._sessionStartAt = sessionStartAt
    this._status = status
  }

  // MARK: - Getter

  /** @return {string} */
  get sessionId () {
    return this._sessionId
  }

  /** @return {moment.Moment} */
  get sessionStartAt () {
    return this._sessionStartAt
  }

  /** @return {int} */
  get status () {
    return this._status
  }
}
