<template>
  <div>
    <!-- App Bar -->
    <custom-app-bar :title="presenter.viewModel.title"/>

    <!-- Form -->
    <form>
      <!-- Name text field -->
      <custom-text-field
        :view-model="presenter.viewModel.nameTextField"
        :vuelidate="$v.name"
      />

      <!-- Name space -->
      <custom-space :view-model="presenter.viewModel.nameBottomSpace"/>

      <!-- Lastname text field -->
      <custom-text-field
        :view-model="presenter.viewModel.lastnameTextField"
        :vuelidate="$v.lastname"
      />

      <!-- Lastname space -->
      <custom-space :view-model="presenter.viewModel.lastnameBottomSpace"/>

      <!-- Email text field -->
      <custom-text-field
        :view-model="presenter.viewModel.emailTextField"
        :vuelidate="$v.email"
      />

      <!-- Email space -->
      <custom-space :view-model="presenter.viewModel.emailBottomSpace"/>

      <!-- Birthday text field -->
      <custom-text-field
        :view-model="presenter.viewModel.birthdayTextField"
        :vuelidate="$v.birthday"
      />

      <!-- Birthday space -->
      <custom-space :view-model="presenter.viewModel.birthdayBottomSpace"/>

      <!-- Phone text field -->
      <custom-text-field
         :view-model="presenter.viewModel.phoneTextField"
         :vuelidate="$v.phone"
      />

       <!-- Phone space -->
      <custom-space :view-model="presenter.viewModel.phoneBottomSpace"/>

      <!-- Subscribe switch -->
      <custom-switch
        :view-model="presenter.viewModel.subscribeSwitch"
      />

      <!-- Subscribe space -->
      <custom-space :view-model="presenter.viewModel.subscribeBottomSpace"/>

      <!-- Save button -->
      <custom-button
        :view-model="presenter.viewModel.saveButton"
        @click="saveTapped()"
      />
    </form>

    <!-- Save space -->
    <custom-space :view-model="presenter.viewModel.saveBottomSpace"/>

    <!-- Password Update button -->
    <custom-button
      :view-model="presenter.viewModel.passwordUpdateButton"
      @click="controller.passwordUpdateTapped()"
    />

    <!-- Password Update space -->
    <custom-space :view-model="presenter.viewModel.passwordUpdateBottomSpace"/>

    <!-- Logout button -->
    <custom-button
      :view-model="presenter.viewModel.logoutButton"
      @click="controller.logoutTapped()"
    />

    <!-- Logout space -->
    <custom-space :view-model="presenter.viewModel.logoutBottomSpace"/>

    <!-- Legal notices button -->
    <custom-button
      :view-model="presenter.viewModel.legalNoticesButton"
      @click="controller.legalNoticesTapped()"
    />

    <!-- Legal notices space -->
    <custom-space :view-model="presenter.viewModel.legalNoticesBottomSpace"/>

    <!-- Loader -->
    <custom-loader :show="presenter.viewModel.showUpdateLoader"/>

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.error"/>

    <!-- Logout Confirmation -->
    <custom-logout :show="presenter.viewModel.showLogoutConfirmation"/>

    <!-- Legal notices Vue -->
    <custom-legal-notices :show="presenter.viewModel.showLegalNotices"/>

    <!-- Refresh -->
    <custom-refresh
      :view-model="presenter.viewModel"
      @click="controller.refreshTapped()"
    />
  </div>
</template>

<script>
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import TextFieldComponent from '@/app/common/component/textfield/TextFieldComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SwitchComponent from '@/app/common/component/switch/SwitchComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LoaderComponent from '@/app/common/component/loader/LoaderComponent'
import RefreshComponent from '@/app/common/component/refresh/RefreshComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import LogoutVue from '@/app/scene/logout/LogoutVue'
import LegalNoticesVue from '@/app/scene/legal-notices/LegalNoticesVue'
import PresentationInjection from '@/presentation/scene/profile/profilePresentationInjection'
import Presenter from '@/presentation/scene/profile/profilePresenter'

export default {
  name: 'ProfileVue',

  data: () => ({
    /** @type ProfileController */
    controller: null,
    /** @type ProfilePresenter */
    presenter: null,

    // TextFields (NOTE: Same names are used on TextFieldViewModel, Presenter and Interactor)
    name: null,
    lastname: null,
    email: null,
    birthday: null,
    phone: null
  }),

  components: {
    customAppBar: AppBarComponent,
    customTextField: TextFieldComponent,
    customButton: ButtonComponent,
    customSwitch: SwitchComponent,
    customSpace: SpaceComponent,
    customLoader: LoaderComponent,
    customRefresh: RefreshComponent,
    customAlert: AlertComponent,
    customLogout: LogoutVue,
    customLegalNotices: LegalNoticesVue
  },

  validations: Presenter.textFieldsValidations,

  watch: {
    birthday () {
      this.controller.birthdayChanged()
    }
  },

  methods: {
    saveTapped () {
      const subscribe = this.presenter.viewModel.subscribeSwitch.value
      this.controller.saveTapped(subscribe)
    }
  },

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$v, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'profile-style'
</style>
