<template>
  <div :class="viewModel.classes">
    {{ viewModel.text }}
  </div>
</template>

<script>
import SheetDescriptionViewModel from '@/presentation/view-model/sheet/text/sheetDescriptionViewModel'

export default {
  name: 'SheetDescriptionComponent',

  props: {
    viewModel: {
      type: SheetDescriptionViewModel,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'sheet-description-style'
</style>
