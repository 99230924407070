import PasswordUpdateDomainInjection from '@/domain/scene/password-update/passwordUpdateDomainInjection'
import PasswordUpdateController from '@/presentation/scene/password-update/passwordUpdateController'
import PasswordUpdatePresenter from '@/presentation/scene/password-update/passwordUpdatePresenter'

export default class PasswordUpdatePresentationInjection {
  // MARK: - Properties

  /** @type {PasswordUpdateController} */
  _controller
  /** @type {PasswordUpdatePresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    this._presenter = new PasswordUpdatePresenter(vuetify, validation, router)

    // Domain
    const domainInjection = new PasswordUpdateDomainInjection(validation, this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new PasswordUpdateController(interactor)
  }

  // MARK: - Getter

  /** @return {PasswordUpdateController} */
  get controller () {
    return this._controller
  }

  /** @return {PasswordUpdatePresenter} */
  get presenter () {
    return this._presenter
  }
}
