import ProfileDomainModel from '@/domain/scene/profile/profileDomainModel'
import ProfileDomainEntry from '@/domain/scene/profile/profileDomainEntry'
import DomainError from '@/domain/error/domainError'

export default class ProfileInteractor {
  // MARK: - Properties

  /** @type {ProfileDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {ProfilePresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ProfileDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {ProfilePresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = true

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false

    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Get profile from repository
    this._recoverProfileFromRepository()
  }

  // MARK: - To Repository

  /**
   * Get profile from repository
   * @param {boolean} forceApiReload
   */
  _recoverProfileFromRepository (forceApiReload = false) {
    const self = this
    this._repository.profile(forceApiReload).then(function (profile) {
      // Convert to domain model array and send it to presenter
      self._presenter.profileFromInteractor = new ProfileDomainModel(profile)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false

      // Show refresh
      self._presenter.showRefreshFromInteractor = true
    }).catch(e => {
      // Send error to presenter
      self._presenter.prepareErrorFromInteractor = new DomainError(e)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false

      // Show refresh
      self._presenter.showRefreshFromInteractor = true
    })
  }

  // MARK: - From Controller

  /**
   * Update client from repository
   * @param {boolean} subscribe
   */
  saveTappedFromController (subscribe) {
    // Form is valid ?
    if (!this._validation.$invalid) {
      // Show loader
      this._presenter.showUpdateLoaderFromInteractor = true

      // Hide refresh
      this._presenter.showRefreshFromInteractor = false

      const self = this
      const entry = new ProfileDomainEntry(this._validation, subscribe)
      this._repository.updateProfile(entry.toData).then(function () {
        // Hide loader
        self._presenter.showUpdateLoaderFromInteractor = false

        // Show refresh
        self._presenter.showRefreshFromInteractor = true
      }).catch(e => {
        // Send error to presenter
        self._presenter.prepareErrorFromInteractor = new DomainError(e)

        // Hide loader
        self._presenter.showUpdateLoaderFromInteractor = false

        // Show refresh
        self._presenter.showRefreshFromInteractor = true
      })
    } else {
      // Show error on all invalids text fields
      this._presenter.showTextFieldsErrorFromInteractor()
    }
  }

  // MARK: - From Controller

  birthdayChangedFromController () {
    this._presenter.birthdayChanged()
  }

  passwordUpdateTappedFromController () {
    this._presenter.preparePasswordUpdateFromInteractor()
  }

  legalNoticesTappedFromController () {
    this._presenter.prepareLegalNoticesFromInteractor()
  }

  logoutTappedFromController () {
    this._presenter.prepareLogoutConfirmationFromInteractor()
  }

  refreshTappedFromController () {
    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Refresh content from repository
    this._recoverProfileFromRepository(true)
  }

  /**
   * @param {boolean} redirectToLogin
   */
  closeLogoutAskedFromController (redirectToLogin) {
    this._presenter.prepareCloseLogoutConfirmationFromInteractor(redirectToLogin)
  }

  closeLegalNoticesAskedFromController () {
    this._presenter.prepareCloseLegalNoticesFromInteractor()
  }
}
