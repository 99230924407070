<template>
  <custom-col-card :viewModel="viewModel">
    <!-- State value -->
    <custom-state-information :viewModel="viewModel.stateInformation"/>

    <!-- Contract (optional)  -->
    <custom-content-item :view-model="viewModel.contract" />

    <!-- Hall  -->
    <custom-content-item :view-model="viewModel.hall" />

    <!-- Category  -->
    <custom-content-item :view-model="viewModel.category" />

    <!-- Start At -->
    <custom-content-item :view-model="viewModel.startAt" />

    <!-- End At -->
    <custom-content-item :view-model="viewModel.endAt" />

    <!-- Session (optional)  -->
    <custom-content-item :view-model="viewModel.session" />

    <!-- Limit in week (optional)  -->
    <custom-content-item :view-model="viewModel.limitInWeek" />

    <!-- Medical Certificate -->
    <custom-content-item :view-model="viewModel.medicalCertificate" />

    <!-- Payment Details -->
    <custom-content-item :view-model="viewModel.paymentDetails" />

  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import CardContentItemComponent from '@/app/common/component/card/CardContentItemComponent'
import StateInformationComponent from '@/app/common/component/state-information/StateInformationComponent'
import SubscriptionsColCardViewModel from '@/presentation/scene/subscriptions/view-model/subscriptionsColCardViewModel'

export default {
  name: 'SubscriptionsColCardComponent',

  components: {
    customColCard: ColCardComponent,
    customContentItem: CardContentItemComponent,
    customStateInformation: StateInformationComponent
  },

  props: {
    viewModel: {
      type: SubscriptionsColCardViewModel,
      required: true
    }
  }
}
</script>
