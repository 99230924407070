<template>
  <custom-container :viewModel="viewModel.container">
    <custom-layout :viewModel="viewModel.layout">
      <custom-flex :viewModel="viewModel.flex">

        <!-- Slot needed to put content here -->
        <slot></slot>
      </custom-flex>
    </custom-layout>
  </custom-container>
</template>

<script>
import ContainerComponent from '@/app/common/component/container/ContainerComponent'
import LayoutComponent from '@/app/common/component/layout/LayoutComponent'
import FlexComponent from '@/app/common/component/flex/FlexComponent'
import ContainerLayoutFlexViewModel from '@/presentation/view-model/container-layout-flex/containerLayoutFlexViewModel'

export default {
  name: 'ContainerLayoutFlexComponent',

  components: {
    customContainer: ContainerComponent,
    customLayout: LayoutComponent,
    customFlex: FlexComponent
  },

  props: {
    viewModel: {
      type: ContainerLayoutFlexViewModel,
      required: true
    }
  }
}
</script>
