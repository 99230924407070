import DataErrorType from '@/data/error/dataErrorType'
import ApiResponseErrorType from '@/data/api/response-error/apiResponseErrorType'
import ApiResponseError from '@/data/api/response-error/apiResponseError'

// MARK: - Class

export default class DataError {
  // MARK: - Properties

  /** @type {int} */
  _type
  /** @type {string|null} */
  _message

  // MARK: - Constructor

  /**
   * @constructor
   * @param {int} type
   * @param {string|null} [message]
   */
  constructor (type, message = null) {
    this._type = type
    this._message = message
  }

  /**
   * @constructor
   * @param {any} error
   * @return {DataError}
   */
  static fromAnyError (error) {
    // Api Error ?
    if (error instanceof ApiResponseError) {
      let type
      switch (error.type) {
        case ApiResponseErrorType.TOKEN:
          type = DataErrorType.TOKEN
          break
        case ApiResponseErrorType.BAD_CREDENTIALS:
          type = DataErrorType.BAD_CREDENTIALS
          break
        case ApiResponseErrorType.ACCOUNT_DISABLED:
          type = DataErrorType.ACCOUNT_DISABLED
          break
        default:
          type = DataErrorType.UNKNOW
          break
      }
      return new DataError(type, error.message)
    } else if (error instanceof DataError) { // Already Data Error ?
      return error
    } else { // Unknow error
      return new DataError(DataErrorType.UNKNOW)
    }
  }

  // MARK: - Getter

  /** @return {int} */
  get type () {
    return this._type
  }

  /** @return {string|null} */
  get message () {
    return this._message
  }
}
