import PasswordDataRepository from '@/data/repository/password/passwordDataRepository'
import PasswordForgotInteractor from '@/domain/scene/password-forgot/passwordForgotInteractor'

export default class PasswordForgotDomainInjection {
  // MARK: - Properties

  /** @type {PasswordForgotInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate
   * @param {PasswordForgotPresenter} presenter
   */
  constructor (validation, presenter) {
    const repository = new PasswordDataRepository()
    this._interactor = new PasswordForgotInteractor(repository, validation, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {PasswordForgotInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
