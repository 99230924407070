import PresentationState from '@/presentation/common/enum/presentationState'
import DateFormat from '@/common/utils/dateFormat'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import CardContentItemViewModel from '@/presentation/view-model/card/cardContentItemViewModel'
import StateInformationViewModel from '@/presentation/view-model/state-information/stateInformationViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import Moment from 'moment'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'

// MARK: - Constants

class Constants {
  static Text = {
    titleText: 'from_date',
    contractText: 'contract',
    hallText: 'hall',
    categoryText: 'category',
    startAtText: 'start_at',
    endAtText: 'end_at',
    numberOfSessionsText: 'number_of_sessions',
    remainingSessionDiminutiveValueText: 'remaining_session_diminutive_value',
    limitInWeekText: 'limit_in_week',
    medicalCertificateText: 'medical_certificate',
    yesText: 'yes',
    noText: 'no',
    paymentText: 'price',
    paymentValuesText: 'price_value'
  }

  static CssClass = {
    col: 'px-0 py-1',
    namesFont: FontWeight.italic
  }
}

// MARK: - Class

export default class SubscriptionsColCardViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _key
  /** @type {StateInformationViewModel} */
  _stateInformation
  /** @type {CardContentItemViewModel} */
  _contract
  /** @type {CardContentItemViewModel} */
  _hall
  /** @type {CardContentItemViewModel} */
  _category
  /** @type {CardContentItemViewModel} */
  _startAt
  /** @type {CardContentItemViewModel} */
  _endAt
  /** @type {CardContentItemViewModel} */
  _session
  /** @type {CardContentItemViewModel} */
  _limitInWeek
  /** @type {CardContentItemViewModel} */
  _medicalCertificate
  /** @type {CardContentItemViewModel} */
  _paymentDetails

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {SubscriptionsDomainModel} domainModel
   */
  constructor (localization, domainModel) {
    const col = new ColViewModel(Constants.CssClass.col)
    super(undefined, col)

    this._key = domainModel.id

    // Header
    let isValid = false
    if (domainModel.remainingSession !== 0) {
      isValid = domainModel.endAt.isSameOrAfter(Moment())
    }
    const state = isValid ? PresentationState.GREEN : PresentationState.RED
    const stateColor = PresentationState.color(state)

    const startAtString = DateFormat.string(domainModel.startAt, DateFormat.DD_MM_YYYY)
    const title = localization.getText(Constants.Text.titleText, startAtString)
    this._stateInformation = new StateInformationViewModel(stateColor, title)

    // Contract
    const contract = domainModel.contract
    const contractName = localization.getText(Constants.Text.contractText)
    this._contract = new CardContentItemViewModel(!!contract, PresentationSpace.CLASSIC, contractName, contract, Constants.CssClass.namesFont)

    // Hall
    const hall = domainModel.hall.completeName
    const hallName = localization.getText(Constants.Text.hallText)
    const hallTopSpace = !contract ? PresentationSpace.CLASSIC : undefined
    this._hall = new CardContentItemViewModel(true, hallTopSpace, hallName, hall, Constants.CssClass.namesFont)

    // Category
    const category = domainModel.category.name
    const categoryName = localization.getText(Constants.Text.categoryText)
    this._category = new CardContentItemViewModel(true, undefined, categoryName, category, Constants.CssClass.namesFont)

    // Date at
    const startAt = DateFormat.string(domainModel.startAt, DateFormat.DD_MM_YYYY)
    const startAtName = localization.getText(Constants.Text.startAtText)
    this._startAt = new CardContentItemViewModel(true, PresentationSpace.CLASSIC, startAtName, startAt, Constants.CssClass.namesFont)

    // End at
    const endAt = DateFormat.string(domainModel.endAt, DateFormat.DD_MM_YYYY)
    const endAtName = localization.getText(Constants.Text.endAtText)
    this._endAt = new CardContentItemViewModel(true, undefined, endAtName, endAt, Constants.CssClass.namesFont)

    // Sessions
    const session = domainModel.session
    const remainingSession = localization.getText(Constants.Text.remainingSessionDiminutiveValueText, domainModel.remainingSession)
    let sessionValue = String.empty
    if (session) {
      sessionValue = session.toString() + String.space + String.toBracketsInto(remainingSession)
    }
    const sessionName = localization.getText(Constants.Text.numberOfSessionsText)
    this._session = new CardContentItemViewModel(!!session, undefined, sessionName, sessionValue, Constants.CssClass.namesFont)

    // Limit in week
    const limitInWeek = domainModel.limitInWeek
    let limitInWeekValue = String.empty
    if (limitInWeek) {
      limitInWeekValue = limitInWeek.toString()
    }
    const limitInWeekName = localization.getText(Constants.Text.limitInWeekText)
    this._limitInWeek = new CardContentItemViewModel(!!limitInWeek, undefined, limitInWeekName, limitInWeekValue, Constants.CssClass.namesFont)

    // Medical certificate
    const medicalCertificate = domainModel.medicalCertificate ? Constants.Text.yesText : Constants.Text.noText
    const medicalCertificateValue = localization.getText(medicalCertificate)
    const medicalCertificateName = localization.getText(Constants.Text.medicalCertificateText)
    this._medicalCertificate = new CardContentItemViewModel(true, undefined, medicalCertificateName, medicalCertificateValue, Constants.CssClass.namesFont)

    // Payment details name
    const price = domainModel.price.toString()
    const paymentDetails = localization.getText(Constants.Text.paymentValuesText, price)
    const paymentDetailsName = localization.getText(Constants.Text.paymentText)
    this._paymentDetails = new CardContentItemViewModel(true, PresentationSpace.CLASSIC, paymentDetailsName, paymentDetails, Constants.CssClass.namesFont)
  }

  // MARK: - Getter

  /** @return {string} */
  get key () {
    return this._key
  }

  /** @return {StateInformationViewModel} */
  get stateInformation () {
    return this._stateInformation
  }

  /** @return {CardContentItemViewModel} */
  get contract () {
    return this._contract
  }

  /** @return {CardContentItemViewModel} */
  get hall () {
    return this._hall
  }

  /** @return {CardContentItemViewModel} */
  get category () {
    return this._category
  }

  /** @return {CardContentItemViewModel} */
  get startAt () {
    return this._startAt
  }

  /** @return {CardContentItemViewModel} */
  get endAt () {
    return this._endAt
  }

  /** @return {CardContentItemViewModel} */
  get session () {
    return this._session
  }

  /** @return {CardContentItemViewModel} */
  get limitInWeek () {
    return this._limitInWeek
  }

  /** @return {CardContentItemViewModel} */
  get medicalCertificate () {
    return this._medicalCertificate
  }

  /** @return {CardContentItemViewModel} */
  get paymentDetails () {
    return this._paymentDetails
  }
}
