import ReservationsInteractor from '@/domain/scene/reservations/reservationsInteractor'
import ReservationsDataRepository from '@/data/repository/reservations/reservationsDataRepository'

export default class ReservationsDomainInjection {
  // MARK: - Properties

  /** @type {ReservationsInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationsPresenter} presenter
   */
  constructor (presenter) {
    const repository = new ReservationsDataRepository()
    this._interactor = new ReservationsInteractor(repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {ReservationsInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
