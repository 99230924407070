import PresentationCard from '@/presentation/common/enum/presentationCard'

// MARK: - Constants

const ASK_PUSH_REGISTRATION = 0
const SUBSCRIPTION_EXPIRE_SOON = 1
const SUBSCRIPTION_EXPIRED = 2
const NO_VALID_SUBSCRIPTION = 3
const NO_NEXT_RESERVATION = 4

class Constants {
  static askPushRegistration = 'ask_push_registration'
  static subscriptionExpireSoon = 'subscription_expires_soon'
  static subscriptionExpired = 'subscription_expired'
  static noValidSubscription = 'no_valid_subscription'
  static noNextReservation = 'no_next_reservation'
}

// MARK: - Enum

export default {
  ASK_PUSH_REGISTRATION,
  SUBSCRIPTION_EXPIRE_SOON,
  SUBSCRIPTION_EXPIRED,
  NO_VALID_SUBSCRIPTION,
  NO_NEXT_RESERVATION,

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
  */
  messageKey (value) {
    switch (value) {
      case ASK_PUSH_REGISTRATION:
        return Constants.askPushRegistration
      case SUBSCRIPTION_EXPIRE_SOON:
        return Constants.subscriptionExpireSoon
      case SUBSCRIPTION_EXPIRED:
        return Constants.subscriptionExpired
      case NO_VALID_SUBSCRIPTION:
        return Constants.noValidSubscription
      default:
        return Constants.noNextReservation
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {int} corresponding to PresentationCard
   */
  toCard (value) {
    switch (value) {
      case ASK_PUSH_REGISTRATION:
        return PresentationCard.GREEN
      case SUBSCRIPTION_EXPIRE_SOON:
        return PresentationCard.YELLOW
      case SUBSCRIPTION_EXPIRED:
        return PresentationCard.RED
      default:
        return PresentationCard.DEFAULT
    }
  }
}
