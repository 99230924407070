import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'

// MARK: - Constants

const TITLE = 0
const SUBTITLE = 1

// MARK: - Enum

export default {
  TITLE,
  SUBTITLE,

  /**
   * @param {int} value corresponding to this enum
   * @return {int} correspond to PresentationSpace
   */
  space (value) {
    switch (value) {
      case SUBTITLE:
        return PresentationSpace.TINY
      default:
        return PresentationSpace.BIG
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  classes (value) {
    switch (value) {
      case SUBTITLE:
        return 'text-subtitle-1' + FontWeight.regular
      default:
        return 'text-h6' + FontWeight.medium
    }
  },

  /** @return {string} */
  color () {
    return Color.white + String.textColorSuffix
  }
}
