import ButtonClassicViewModel from '@/presentation/view-model/button/buttonClassicViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import SheetViewModel from '@/presentation/view-model/sheet/sheetViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static TextKeys = {
    title: 'logout',
    description: 'logout_confirm_description'
  }

  static Buttons = {
    confirmTitleKey: 'logout_confirm',
    cancelTitleKey: 'cancel'
  }
}

// MARK: - Class

export default class LogoutViewModel extends SheetViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {ButtonClassicViewModel} */
  _confirmButton
  /** @type {SpaceViewModel} */
  _confirmBottomSpace

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization,
      Constants.TextKeys.title,
      Constants.TextKeys.description,
      Constants.Buttons.cancelTitleKey
    )

    this._localization = localization
    this._validation = validation

    this._confirmButton = new ButtonClassicViewModel(localization, Constants.Buttons.confirmTitleKey, Color.red)
    this._confirmBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
  }

  // MARK: - Getter

  /** @type {ButtonClassicViewModel} */
  get confirmButton () {
    return this._confirmButton
  }

  /** @type {SpaceViewModel} */
  get confirmBottomSpace () {
    return this._confirmBottomSpace
  }
}
