<template>
  <v-app-bar
    :app="viewModel.app"
    :fixed="viewModel.fixed"
    :color="viewModel.color">

      <!-- Row -->
    <custom-row :viewModel="viewModel.row">

      <!-- Col -->
      <custom-col :viewModel="viewModel.col">

        <!-- Day -->
        <div :class="viewModel.textClasses">{{ title }}</div>
      </custom-col>
    </custom-row>
  </v-app-bar>
</template>

<script>
import AppBarViewModel from '@/presentation/view-model/app-bar/appBarViewModel'
import RowComponent from '@/app/common/component/row/RowComponent'
import ColComponent from '@/app/common/component/col/ColComponent'

export default {
  name: 'AppBarComponent',

  components: {
    customRow: RowComponent,
    customCol: ColComponent
  },

  props: {
    title: {
      type: String,
      required: true
    }
  },

  data: () => ({
    /** @type AppBarViewModel */
    viewModel: null
  }),

  beforeMount () {
    this.viewModel = new AppBarViewModel(this.$vuetify)
  }
}
</script>
