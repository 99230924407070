import ArrayAPIResponse from '@/data/api/response/arrayAPIResponse'
import ObjectAPIResponse from '@/data/api/response/objectAPIResponse'
import DataError from '@/data/error/dataError'
import DataErrorType from '@/data/error/dataErrorType'

export default class RetrieveDataFromAPI {
  /**
   * Get data from API. Return object if API response is OK.
   * @param {Promise} target of request
   * @return {Promise<{any, DetailsAPIResponse}>}
   */
  _retrieveObject (target) {
    return new Promise(function (resolve, reject) {
      target.then(response => {
        const apiResponse = new ObjectAPIResponse(response)
        if (apiResponse.isValid) {
          const object = apiResponse.data
          const details = apiResponse.details

          resolve({ data: object, details: details })
        } else {
          const error = new DataError(DataErrorType.UNDECODABLE)
          reject(error)
        }
      }).catch(e => {
        reject(e)
      })
    })
  }

  /**
   * Get target promise to call API with pagination
   * SHOULD BE IMPLEMENTED ON CHILD
   * @param {int} page of request
   * @return {Promise}
   */
  _dataTargetPromise (page) {
    return new Promise()
  }

  /**
   * Get data from API with pagination. Return array if all API responses are OK.
   * @param {int} page of request
   * @param {boolean} onlyFirstPage get only first page ?
   * @return {Promise<{any, DetailsAPIResponse}>}
   */
  _retrieveArray (page = 1, onlyFirstPage = false) {
    const self = this
    return new Promise(function (resolve, reject) {
      self._dataTargetPromise(page).then(response => {
        const apiResponse = new ArrayAPIResponse(response)
        if (apiResponse.isValid) {
          let array = apiResponse.data
          const details = apiResponse.details

          // Need to recall api with up pagination ?
          if (!details.isLastPage && !onlyFirstPage) {
            self._retrieveArray(page + 1).then(({ data, details }) => {
              array = array.concat(data)
              resolve({ data: array, details: details })
            }).catch(e => {
              reject(e)
            })
          } else { // Last page ?
            resolve({ data: array, details: details })
          }
        } else {
          const error = new DataError(DataErrorType.UNDECODABLE)
          reject(error)
        }
      }).catch(e => {
        reject(e)
      })
    })
  }
}
