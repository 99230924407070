// MARK: - Constants

class Constants {
  static background = 'background'
  static black = 'black'
  static blackDarkMainLight = 'blackDarkMainLight'
  static brownishGrey = 'brownishGrey'
  static dark = 'dark'
  static darkLight = 'darkLight'
  static darkMintGreen = 'darkMintGreen'
  static grapefruit = 'grapefruit'
  static grayLight = 'grayLight'
  static green = 'green'
  static main = 'main'
  static mainDarkSecondaryLight = 'mainDarkSecondaryLight'
  static secondary = 'secondary'
  static veryLightPink = 'veryLightPink'
  static white = 'white'
  static yellow = 'yellow'
}

// MARK: - Class

export default class Color {
  // MARK: - Properties

  static background = Constants.background
  static black = Constants.black
  static blackDarkMainLight = Constants.blackDarkMainLight
  static brownishGrey = Constants.brownishGrey
  static darkLight = Constants.darkLight
  static green = Constants.darkMintGreen
  static greenMiDark = Constants.green
  static red = Constants.grapefruit
  static grayLight = Constants.grayLight
  static main = Constants.main
  static mainDarkSecondaryLight = Constants.mainDarkSecondaryLight
  static secondary = Constants.secondary
  static veryLightGray = Constants.veryLightPink
  static white = Constants.white
  static yellow = Constants.yellow
}
