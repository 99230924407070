import ReservationDataEntry from '@/data/repository/reservation/reservationDataEntry'

export default class ReservationDomainEntry {
  // MARK: - Properties

  /** @type {string} */
  _sessionId
  /** @type {int} */
  _status
  /** @type {string|null} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} sessionId
   * @param {int} status
   * @param {any} validation from vuelidate which contains pattern textfield
   */
  constructor (sessionId, status, validation) {
    this._sessionId = sessionId
    this._status = status
    if (validation.pattern.$model.length > 0) {
      this._pattern = validation.pattern.$model
    }
  }

  // MARK: - Conversion

  /**
   * Convert object to Data entry model
   * @return {ReservationDataEntry}
   */
  get toDataEntry () {
    return new ReservationDataEntry(this._sessionId,
      this._status,
      this._pattern
    )
  }
}
