import ButtonClassicViewModel from '@/presentation/view-model/button/buttonClassicViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Class

export default class ButtonClassicResizeViewModel extends ButtonClassicViewModel {
  // MARK: - Properties

  /** @type {Framework} */
  _vuetify

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {Localization} localization
   * @param {string} titleKey
   * @param {string} color
   */
  constructor (vuetify, localization, titleKey, color = Color.main) {
    super(localization,
      titleKey,
      color
    )
    this._vuetify = vuetify
  }

  // MARK: - Getter

  /** @return {boolean} */
  get block () {
    return this._vuetify.breakpoint.smAndDown
  }
}
