<template>
  <v-bottom-sheet
    v-model="show"
    :persistent="viewModel.bottomSheetPersistent"
  >
    <v-sheet
      :class="viewModel.sheetClasses"
      :height="viewModel.sheetHeight"
      :width="viewModel.sheetWidth"
      :max-width="viewModel.sheetMaxWidth"
      :rounded="viewModel.sheetRounded"
    >

      <!-- Slot needed to put content here -->
      <slot></slot>

    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import SheetStructureViewModel from '@/presentation/view-model/sheet/sheetStructureViewModel'

export default {
  name: 'SheetStructureComponent',

  props: {
    viewModel: {
      type: SheetStructureViewModel,
      required: true
    },

    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>
