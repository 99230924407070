<template>
  <v-flex :class="viewModel.classes">

    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-flex>
</template>

<script>
import FlexViewModel from '@/presentation/view-model/flex/flexViewModel'

export default {
  name: 'FlexComponent',

  props: {
    viewModel: {
      type: FlexViewModel,
      required: true
    }
  }
}
</script>
