import SceneCardViewModel from '@/presentation/view-model/scene/sceneCardViewModel'

// MARK: - Constants

class Constants {
  static titleKey = 'sessions_title'

  static CardLoader = {
    count: 10,
    height: '75px'
  }
}

// MARK: - Class

export default class SessionsForWeekViewModel extends SceneCardViewModel {
  // MARK: - Properties

  /** @type {[SessionsForWeekSectionViewModel]} */
  _sections
  /** @type {boolean} */
  _showReservation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super(localization,
      Constants.titleKey,
      Constants.CardLoader.count,
      Constants.CardLoader.height
    )

    this._sections = []
    this._showReservation = false
  }

  // MARK: - Getter

  /** @return {boolean} */
  get showSections () {
    return !this._showLoader && this._sections
  }

  /** @return {[SessionsForWeekSectionViewModel]} */
  get sections () {
    return this._sections
  }

  /** @return {boolean} */
  get showReservation () {
    return this._showReservation
  }

  // MARK: - Setter

  /** @param {[SessionsForWeekSectionViewModel]} values */
  set sections (values) {
    this._sections = values
  }

  /** @param {boolean} value */
  set showReservation (value) {
    this._showReservation = value
  }
}
