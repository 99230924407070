export default class DashboardReservationSessionDomainModel {
  // MARK: - Properties

  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {string|null} */
  _description

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardReservationSessionDataModel} dataModel
   */
  constructor (dataModel) {
    this._startAt = dataModel.startAt
    this._endAt = dataModel.endAt
    this._description = dataModel.description
  }

  // MARK: - Getter

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {string|null} */
  get description () {
    return this._description
  }
}
