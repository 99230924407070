import ReservationStateInformationViewModel from '@/presentation/view-model/reservation/reservationStateInformationViewModel'
import PresentationCard from '@/presentation/common/enum/presentationCard'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'

// MARK: - Constants

class Constants {
  static defaultType = PresentationCard.DEFAULT
}

// MARK: - Class

export default class DashboardColCardReservationViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {ReservationStateInformationViewModel} */
  _stateInformation

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    const cardBackgroundColor = PresentationCard.backgroundColor(Constants.defaultType)
    super(cardBackgroundColor)

    this._stateInformation = new ReservationStateInformationViewModel()
  }

  // MARK: - Getter

  /** @return {ReservationStateInformationViewModel} */
  get stateInformation () {
    return this._stateInformation
  }
}
