import Moment from 'moment'

export default class ProfileDataModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {string} */
  _name
  /** @type {string} */
  _lastname
  /** @type {string} */
  _email
  /** @type {moment.Moment} */
  _birthday
  /** @type {string} */
  _phone
  /** @type {boolean} */
  _subscribe

  // MARK: - Constructor

  constructor (response) {
    this._id = response.id
    this._name = response.name
    this._lastname = response.lastname
    this._email = response.email
    this._birthday = Moment(response.birthday)
    this._phone = response.phone
    this._subscribe = response.subscribe
  }

  // MARK: - Getter

  /** @type {string} */
  get id () {
    return this._id
  }

  /** @type {string} */
  get name () {
    return this._name
  }

  /** @type {string} */
  get lastname () {
    return this._lastname
  }

  /** @type {string} */
  get email () {
    return this._email
  }

  /** @type {moment.Moment} */
  get birthday () {
    return this._birthday
  }

  /** @type {string} */
  get phone () {
    return this._phone
  }

  /** @type {boolean} */
  get subscribe () {
    return this._subscribe
  }
}
