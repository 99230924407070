export default class SessionsForWeekReservationDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {int} */
  _status
  /** @type {string|null} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SessionsForWeekReservationDataModel} dataModel
   */
  constructor (dataModel) {
    this._id = dataModel.id
    this._status = dataModel.status
    this._status = dataModel.status
    this._pattern = dataModel.pattern
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {int} */
  get status () {
    return this._status
  }

  /** @return {string|null} */
  get pattern () {
    return this._pattern
  }
}
