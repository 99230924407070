import Color from '@/presentation/utils/color'

// MARK: - Constants

const WHITE = 0
const YELLOW = 1
const GREEN = 2
const RED = 3

// MARK: - Enum

export default {
  WHITE,
  YELLOW,
  GREEN,
  RED,

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  color (value) {
    switch (value) {
      case WHITE:
        return Color.white
      case YELLOW:
        return Color.yellow
      case GREEN:
        return Color.green
      case RED:
        return Color.red
      default:
        return Color.main
    }
  }
}
