import SessionsForWeekDomainModel from '@/domain/scene/sessions-for-week/model/sessionsForWeekDomainModel'
import SessionsForWeekDayDomainModel from '@/domain/scene/sessions-for-week/model/sessionsForWeekDayDomainModel'
import ReservationDomainParameter from '@/domain/scene/reservation/parameter/reservationDomainParameter'
import ReservationSessionDomainParameter from '@/domain/scene/reservation/parameter/reservationSessionDomainParameter'
import DomainError from '@/domain/error/domainError'
import DateFormat from '@/common/utils/dateFormat'
import DomainStatus from '@/domain/enum/domainStatus'
import SessionDate from '@/domain/utils/sessionDate'

export default class SessionsForWeekInteractor {
  // MARK: - Properties

  /** @type {SessionsForWeekDataRepository} */
  _repository
  /** @type {SessionsForWeekPresenter} */
  _presenter

  /** @type {SessionsForWeekDomainModel[]} */
  _sessions

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SessionsForWeekDataRepository} repository
   * @param {SessionsForWeekPresenter} presenter
   */
  constructor (repository, presenter) {
    this._repository = repository
    this._presenter = presenter

    this._sessions = []
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = true

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false

    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Get sessionsForWeek from repository
    this._recoverSessionsForWeekFromRepository()
  }

  // MARK: - To Repository

  /**
   * Get sessionsForWeek from repository
   * @param {boolean} forceApiReload
   */
  _recoverSessionsForWeekFromRepository (forceApiReload = false) {
    const self = this
    this._repository.sessionsForWeek(forceApiReload).then(function (sessionsForWeek) {
      self._sessionsForWeekFromRepository = sessionsForWeek
    }).catch(e => {
      // Send error to presenter
      self._presenter.prepareErrorFromInteractor = new DomainError(e)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false

      // Show refresh
      self._presenter.showRefreshFromInteractor = true
    })
  }

  // MARK: - From Repository

  /**
   * Convert object to Data entry model
   * @param {[SessionsForWeekDataModel]} values
   */
  set _sessionsForWeekFromRepository (values) {
    // Convert to domain model array
    const sessionsForWeek = values.map(e => {
      return new SessionsForWeekDomainModel(e)
    })

    // Sort array from start date
    this._sessions = sessionsForWeek.sort((a, b) => {
      return a.startAt - b.startAt
    })

    // Send sessions on presenter
    this._sendSessionsToPresenter()

    // Hide loader
    this._presenter.showLoaderFromInteractor = false

    // Show refresh
    this._presenter.showRefreshFromInteractor = true
  }

  // MARK: - To Presenter

  /**
   * Send sessions to presenter
  */
  _sendSessionsToPresenter () {
    // Filter array to get only next sessions
    const nextSessions = this._sessions.filter(session =>
      DateFormat.isSameOrAfterNow(session.startAt)
    )

    // Split array to days
    const sessionsByDays = []
    nextSessions.forEach(session => {
      const day = session.startAt
      const item = sessionsByDays.find(i => {
        return DateFormat.isSameDay(i.day, day)
      })

      if (item) {
        item.session = session
      } else {
        const item = new SessionsForWeekDayDomainModel(day, session)
        sessionsByDays.push(item)
      }
    })

    this._presenter.sessionsForWeekByDaysFromInteractor = sessionsByDays
  }

  // MARK: - From Controller

  /**
   * @param {string} id
   */
  selectSessionFromController (id) {
    const session = this._sessions.find(session => session.id === id)
    // Check if still possible to do action
    if (session) {
      let checkForReservation = false
      let status = null // Corresponding to DomainStatus

      // Action isn't equals to cancel
      if (session.reservation) {
        switch (session.reservation.status) {
          case DomainStatus.RESERVE:
            if (SessionDate.stillTimeToCancel(session)) {
              status = DomainStatus.CANCEL
            }
            break
          case DomainStatus.WAITING:
            // Check if there is available place to reserve
            if (SessionDate.stillTimeToReserve(session)) {
              if (!session.isComplete) {
                status = DomainStatus.RESERVE
              } else { // Do anything in this case
                return
              }
            }
            break
          case DomainStatus.CANCEL:
            checkForReservation = true
            break
          default:
            return
        }
      } else if (SessionDate.stillTimeToReserve(session)) {
        checkForReservation = true
      }

      // Check if there is available place to reserve
      if (checkForReservation && SessionDate.stillTimeToReserve(session)) {
        status = session.isComplete ? DomainStatus.WAITING : DomainStatus.RESERVE
      }
      // Show reservation if status is valid
      if (status !== null) {
        const sessionParameter = new ReservationSessionDomainParameter(session.id, session.startAt)
        let reservationId
        if (session.reservation) {
          reservationId = session.reservation.id
        }

        const reservationParameter = new ReservationDomainParameter(status, sessionParameter, reservationId)
        this._presenter.prepareReservationFromInteractor(reservationParameter)
      } else {
        // No status ? Show error
        this._presenter.prepareToLateErrorFromInteractor()
      }
    }
  }

  refreshTappedFromController () {
    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Refresh content from repository
    this._recoverSessionsForWeekFromRepository(true)
  }

  closeReservationAskedFromController () {
    this._presenter.prepareCloseReservationFromInteractor()
  }

  sessionsForWeekChangedFromController () {
    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Get sessionsForWeek from repository (force to get data from api)
    this._recoverSessionsForWeekFromRepository(true)
  }
}
