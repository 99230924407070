import ValueDataProviderWorker from '@/data/data-provider/worker/valueDataProviderWorker'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import LoginAPITarget from '@/data/api/target/loginAPITarget'
import TokenAPIResponse from '@/data/api/response/tokenAPIResponse'
import DataError from '@/data/error/dataError'
import DataErrorType from '@/data/error/dataErrorType'
import ReloginAPIBody from '@/data/api/body/reloginAPIBody'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import LogoutDataRepository from '@/data/repository/logout/logoutDataRepository'

export default class ReloginDataRepository {
  // MARK: - Properties

  /** @type {LoginAPITarget} */
  _apiTarget
  /** @type {ValueDataProviderWorker} */
  _tokenDataProviderWorker
  /** @type {ValueDataProviderWorker} */
  _refreshTokenDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _profileDataProviderWorker

  // MARK: - Constructor

  constructor () {
    this._apiTarget = new LoginAPITarget()

    this._tokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.TOKEN)
    this._refreshTokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.REFRESH_TOKEN)
    this._profileDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.PROFILE)
  }

  // MARK: - Getter

  /** @return {DataError} */
  get _error () {
    // Remove all cache data
    this._removeAllDataProvider()
    return new DataError(DataErrorType.REFRESH_TOKEN)
  }

  get refreshToken () {
    const self = this
    return new Promise(function (resolve, reject) {
      // Get value on worker
      const refreshToken = self._refreshTokenDataProviderWorker.get
      if (refreshToken !== null) {
        const requestBody = new ReloginAPIBody(refreshToken)
        self._apiTarget.refreshToken(requestBody).then(response => {
          const apiResponse = new TokenAPIResponse(response)
          if (apiResponse.isValid) {
            // Save token and profile on cache
            self._tokenDataProviderWorker.save(apiResponse.token)
            self._refreshTokenDataProviderWorker.save(apiResponse.refreshToken)
            self._profileDataProviderWorker.save(apiResponse.content.data, apiResponse.content.details.expiredAt)
            resolve()
          } else {
            reject(self._error)
          }
        }).catch(_ => {
          reject(self._error)
        })
      } else { // No refresh token, return reject
        reject(self._error)
      }
    })
  }

  // MARK: - Others

  /** Remove all cache data */
  _removeAllDataProvider () {
    const logoutDataRepository = new LogoutDataRepository()
    logoutDataRepository.removeAll()
  }
}
