import String from '@/presentation/utils/string'
import FormViewModel from '@/presentation/view-model/form/formViewModel'

// MARK: - Constants

class Constants {
  static value = false
  static classes = 'my-0 ml-1'
  static inset = true
  static messages = String.empty
}

// MARK: - Class

export default class SwitchViewModel extends FormViewModel {
  // MARK: - Properties

  /** @type {string} */
  _message
  /** @type {boolean} */
  _value
  /** @type {string} */
  _classes
  /** @type {boolean} */
  _inset

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} label
   * @param {string} message
   * @param {boolean} value
   * @param {string} classes
   * @param {string} color
   * @param {boolean} inset
   * @param {boolean} showLoader
   */
  constructor (label,
    message = Constants.messages,
    value = Constants.value,
    classes = Constants.classes,
    color = undefined,
    inset = Constants.inset,
    showLoader = undefined) {
    super(label, color, showLoader)
    this._message = String.brackets(message.toLowerCase())
    this._value = value
    this._classes = classes
    this._inset = inset
  }

  // MARK: - Getter

  /** @return {string} */
  get message () {
    return this._message
  }

  /** @return {boolean} */
  get value () {
    return this._value
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {boolean} */
  get inset () {
    return this._inset
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set value (value) {
    this._value = value
  }
}
