import SessionsForWeekViewModel from '@/presentation/scene/sessions-for-week/view-model/sessionsForWeekViewModel'
import SessionsForWeekSectionViewModel from '@/presentation/scene/sessions-for-week/view-model/sessionsForWeekSectionViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import PresentationErrorType from '@/presentation/common/enum/presentationError'
import ContentPresenter from '@/presentation/utils/contentPresenter'
import ReservationPresentationParameter from '@/presentation/scene/reservation/parameter/reservationPresentationParameter'

export default class SessionsForWeekPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {SessionsForWeekViewModel} */
  _viewModel
  /** @type {ReservationPresentationParameter|null} */
  _reservationParameter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    super()
    this._localization = new Localization(vuetify)
    this._viewModel = new SessionsForWeekViewModel(this._localization)
    this._reservationParameter = null
  }

  // MARK: - Getter

  /** @return {SessionsForWeekViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {ReservationPresentationParameter|null} */
  get reservationParameter () {
    return this._reservationParameter
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  /** @param {boolean} value */
  set showRefreshFromInteractor (value) {
    this._viewModel.showRefresh = value
  }

  /** @param {[SessionsForWeekDayDomainModel]} values */
  set sessionsForWeekByDaysFromInteractor (values) {
    // Convert to view model array
    this._viewModel.sections = values.map(e => {
      return new SessionsForWeekSectionViewModel(this._localization, e, e === values[0])
    })
  }

  /** @param {ReservationDomainParameter} parameter */
  prepareReservationFromInteractor (parameter) {
    this._reservationParameter = new ReservationPresentationParameter(parameter)
    this._viewModel.showReservation = true
  }

  /**
   * @param {boolean} redirectToLogin
   */
  prepareCloseReservationFromInteractor () {
    this._viewModel.showReservation = false
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.information = presentationError.toInformation
  }

  prepareToLateErrorFromInteractor () {
    const presentationError = PresentationError.fromType(this._localization, PresentationErrorType.RESERVATION_TO_LATE)
    this._viewModel.information = presentationError.toInformation
  }
}
