import SessionsForWeekDataModel from '@/data/repository/sessions-for-week/model/sessionsForWeekDataModel'
import SessionAPITarget from '@/data/api/target/sessionAPITarget'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import RetrieveDataFromAPI from '@/data/utils/retrieveDataFromAPI'
import DataError from '@/data/error/dataError'

export default class SessionsForWeekDataRepository extends RetrieveDataFromAPI {
  // MARK: - Properties

  /** @type {SessionAPITarget} */
  _apiTarget
  /** @type {ObjectDataProviderWorker} */
  _dataProviderWorker

  // MARK: - Constructor

  constructor () {
    super()

    this._apiTarget = new SessionAPITarget()
    this._dataProviderWorker = new ObjectDataProviderWorker(DataProviderType.SESSIONS)
  }

  // MARK: - Getter

  /**
   * Get all sessions of week mapped
   * @return {[SessionsForWeekDataModel]}
   */
  _getSessionsForWeekMapped (response) {
    return response.map(e => {
      return new SessionsForWeekDataModel(e)
    })
  }

  /**
   * Get all sessions of week
   * @param {boolean} forceApiReload
   * @return {Promise<[SessionsForWeekDataModel]>}
   */
  sessionsForWeek (forceApiReload) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Force reload or data expired on cache ? Call API
      if (forceApiReload || self._dataProviderWorker.isExpired) {
        self._retrieveArray().then(({ data, details }) => {
          // Save data on local
          self._dataProviderWorker.save(data, details.expiredAt)

          const sessionsForWeek = self._getSessionsForWeekMapped(data)
          resolve(sessionsForWeek)
        }).catch(e => {
          const error = DataError.fromAnyError(e)
          reject(error)
        })
      } else { // Get data from cache
        const response = self._dataProviderWorker.get
        const sessionsForWeek = self._getSessionsForWeekMapped(response)
        resolve(sessionsForWeek)
      }
    })
  }

  // MARK: - Retrieve Data

  _dataTargetPromise (page) {
    return this._apiTarget.getAllForWeek(page)
  }
}
