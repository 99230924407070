import ValueDataProviderWorker from '@/data/data-provider/worker/valueDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'

export default class RouteDataRepository {
  // MARK: - Properties

  /** @type {ValueDataProviderWorker} */
  _tokenDataProviderWorker

  // MARK: - Constructor

  constructor () {
    this._tokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.TOKEN)
  }

  // MARK: - Getter

  /** @return {boolean} */
  get containsToken () {
    return this._tokenDataProviderWorker.get !== null
  }
}
