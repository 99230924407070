// MARK: - Constants

class Constants {
  static currentKey = 'current'
  static newKey = 'new'
}

// MARK: - Class

export default class PasswordUpdateAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} currentPassword
   * @param {string} newPassword
   */
  constructor (currentPassword, newPassword) {
    this._body[Constants.currentKey] = currentPassword
    this._body[Constants.newKey] = newPassword
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
