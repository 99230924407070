<template>
  <v-banner
    :value="viewModel.show"
    :color="viewModel.color"
    :class="viewModel.classes"
    :width="viewModel.width"
    :max-width="viewModel.maxWidth"
    :rounded="viewModel.rounded"
    :style="viewModel.style">

    <div :class="viewModel.titleClasses">
      {{ viewModel.title }}
    </div>

    <template v-slot:actions>
      <custom-button
        v-for="(item, index) in viewModel.buttons"
        :key="index"
        :view-model="item"
        @click="clicked(index)"
      />
    </template>

  </v-banner>
</template>

<script>
import BannerViewModel from '@/presentation/view-model/banner/bannerViewModel'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import { Events } from '@/presentation/main'

export default {
  name: 'BannerComponent',

  props: {
    viewModel: {
      type: BannerViewModel,
      required: true
    }
  },

  components: {
    customButton: ButtonComponent
  },

  methods: {
    clicked (id) {
      this.$emit(Events.clicked, id)
    }
  }
}
</script>
