<template>
  <v-checkbox
    v-model="viewModel.value"
    :label="viewModel.label"
    :color="viewModel.color"
    :hide-details="viewModel.hideDetails"
    :error="viewModel.error"
    @change="change()">

    <template v-slot:label>
      <div>
        {{ viewModel.labelStart }}
            <a :class="viewModel.labelLinkClasses"
              href="#"
              @click.stop
              @click="clicked()">
              {{ viewModel.labelLink }}</a>
        {{ viewModel.labelEnd }}
      </div>
    </template>
  </v-checkbox>
</template>

<script>
import LegalNoticesCheckboxViewModel from '@/presentation/scene/login/view-model/loginLegalNoticesCheckboxViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'LoginLegalNoticesCheckboxComponent',

  props: {
    viewModel: {
      type: LegalNoticesCheckboxViewModel,
      required: true
    }
  },

  methods: {
    change () {
      this.viewModel.valueChanged()
    },

    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
