// MARK: - Constants

class Constants {
  static emailKey = 'email'
  static codeKey = 'code'
  static passwordKey = 'password'
}

// MARK: - Class

export default class PasswordInitAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} email
   * @param {string} code
   * @param {string} password
   */
  constructor (email, code, password) {
    this._body[Constants.emailKey] = email
    this._body[Constants.codeKey] = code
    this._body[Constants.passwordKey] = password
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
