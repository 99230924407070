import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'
import PresentationStatus from '@/presentation/common/enum/presentationStatus'
import String from '@/presentation/utils/string'
import { required } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static defaultLabel = String.empty
  static defaultRequired = false
  static defaultRequiredKey = null
  static type = 'text'
}

// MARK: - Class

export default class ReservationPatternTextFieldViewModel extends TextFieldViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {string|null} */
  _requiredKey

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization,
    validation) {
    super(Constants.defaultLabel,
      Constants.defaultRequired,
      Constants.type
    )

    this._localization = localization
    this._validation = validation
    this._requiredKey = Constants.defaultRequiredKey
  }

  // MARK: - Getter

  /** @return {{pattern: {required: *}}} */
  static get validation () {
    return {
      pattern: {
        required
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.pattern.$dirty) return errors
    !this._validation.pattern.required && this.required && errors.push(this._localization.getText(this._requiredKey))
    return errors
  }

  // MARK: - Setter

  /** @param {int} value (corresponding to presentationStatus) */
  set status (value) {
    this.label = this._localization.getText(PresentationStatus.fieldLabelKey(value))
    this.required = PresentationStatus.fieldRequired(value)
    this.requiredKey = PresentationStatus.fieldRequiredKey(value)
  }
}
