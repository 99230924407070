export default class HallDataModel {
  // MARK: - Properties

  /** @type {string} */
  _address
  /** @type {string} */
  _city
  /** @type {string} */
  _zipcode

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._address = response.address
    this._city = response.city
    this._zipcode = response.zipcode
  }

  // MARK: - Getter

  /** @return {string} */
  get address () {
    return this._address
  }

  /** @return {string} */
  get city () {
    return this._city
  }

  /** @return {string} */
  get zipcode () {
    return this._zipcode
  }
}
