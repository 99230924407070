<template>
  <div v-if="viewModel.show">
    <!-- Space -->
    <custom-space :view-model="viewModel.space"/>

    <!-- Name -->
    <span v-if="viewModel.showName"
          :class="viewModel.nameClasses">
      {{ viewModel.name }}
    </span>

    <!-- Value -->
    <span v-if="viewModel.showValue"
          :class="viewModel.valueClasses">
      {{ viewModel.value }}
    </span>
  </div>
</template>

<script>
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import CardContentItemViewModel from '@/presentation/view-model/card/cardContentItemViewModel'

export default {
  name: 'CardContentItemComponent',

  props: {
    viewModel: {
      type: CardContentItemViewModel,
      required: true
    }
  },

  components: {
    customSpace: SpaceComponent
  }
}
</script>
