// MARK: - Constants

const GENERIC = 0
const PASSWORD_FORGOT = 1
const PASSWORD_INIT = 2
const RESERVATION_CANCEL = 3
const RESERVATION_RESERVE = 4
const RESERVATION_WAITING = 5

class Constants {
  static genericText = 'success_generic'
  static passwordForgotText = 'success_forgot'
  static passwordInitText = 'success_init_password'
  static reservationCancelText = 'success_reservation_cancel'
  static reservationReserveText = 'success_reservation_reserve'
  static reservationWaitingText = 'success_reservation_waiting'
}

// MARK: - Enum

export default {
  GENERIC,
  PASSWORD_FORGOT,
  PASSWORD_INIT,
  RESERVATION_CANCEL,
  RESERVATION_RESERVE,
  RESERVATION_WAITING,

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  key (value) {
    switch (value) {
      case PASSWORD_FORGOT:
        return Constants.passwordForgotText
      case PASSWORD_INIT:
        return Constants.passwordInitText
      case RESERVATION_CANCEL:
        return Constants.reservationCancelText
      case RESERVATION_RESERVE:
        return Constants.reservationReserveText
      case RESERVATION_WAITING:
        return Constants.reservationWaitingText
      default:
        return Constants.genericText
    }
  }
}
