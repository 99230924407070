import SubscriptionsDomainModel from '@/domain/scene/subscriptions/subscriptionsDomainModel'
import DomainError from '@/domain/error/domainError'

export default class SubscriptionsInteractor {
  // MARK: - Properties

  /** @type {SubscriptionsDataRepository} */
  _repository
  /** @type {SubscriptionsPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SubscriptionsDataRepository} repository
   * @param {SubscriptionsPresenter} presenter
   */
  constructor (repository, presenter) {
    this._repository = repository
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = true

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false

    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Get subscriptions from repository
    this._recoverSubscriptionsFromRepository()
  }

  // MARK: - To Repository

  /**
   * Get subscriptions from repository
   * @param {boolean} forceApiReload
   */
  _recoverSubscriptionsFromRepository (forceApiReload = false) {
    const self = this
    this._repository.subscriptions(forceApiReload).then(function (subscriptions) {
      self._subscriptionsFromRepository = subscriptions
    }).catch(e => {
      // Send error to presenter
      self._presenter.prepareErrorFromInteractor = new DomainError(e)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false

      // Show refresh
      self._presenter.showRefreshFromInteractor = true
    })
  }

  // MARK: - From Repository

  /**
   * Convert object to Data entry model
   * @param {[SubscriptionsDataModel]} values
   */
  set _subscriptionsFromRepository (values) {
    // Convert to domain model array
    const subscriptions = values.map(e => {
      return new SubscriptionsDomainModel(e)
    })

    // Sort array from start date and send it to presenter
    this._presenter.subscriptionsFromInteractor = subscriptions.sort((a, b) => {
      return b.endAt - a.endAt
    })

    // Hide loader
    this._presenter.showLoaderFromInteractor = false

    // Show refresh
    this._presenter.showRefreshFromInteractor = true
  }

  // MARK: - From Controller

  refreshTappedFromController () {
    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Refresh content from repository
    this._recoverSubscriptionsFromRepository(true)
  }
}
