import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static keyPrefix = '$vuetify.'
  static pluralKeyPrefix = '_plural'
}

// MARK: - Class

export default class Localization {
  // MARK: - Properties

  /** @type {Framework} */
  _vuetify

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._vuetify = vuetify
  }

  // MARK: - Getter

  getText (key, ...params) {
    // Check if key isn't empty
    if (!String.isEmpty(key)) {
      return this._vuetify.lang.t(Constants.keyPrefix + key, params)
    } else {
      return String.empty
    }
  }

  getPluralText (key, plural, ...params) {
    if (plural) {
      key += Constants.pluralKeyPrefix
    }
    return this.getText(key, params)
  }
}
