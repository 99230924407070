import DetailsAPIResponse from '@/data/api/response/detailsAPIResponse'

export default class ObjectAPIResponse {
  // MARK: - Properties

  /** @type {any|null} */
  _data
  /** @type {DetailsAPIResponse|null} */
  _details

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any|null} response
   */
  constructor (response) {
    this._data = response.data
    this._details = new DetailsAPIResponse(response.details)
  }

  // MARK: - Getter

  /** @return {boolean} */
  get isValid () {
    return this._data !== null && this._details.isValid
  }

  /** @return {any} */
  get data () {
    return this._data
  }

  /** @return {DetailsAPIResponse} */
  get details () {
    return this._details
  }
}
