import Status from '@/presentation/common/enum/presentationStatus'
import State from '@/presentation/common/enum/presentationState'
import DateFormat from '@/common/utils/dateFormat'
import String from '@/presentation/utils/string'
import StateInformationViewModel from '@/presentation/view-model/state-information/stateInformationViewModel'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static Text = {
    sessionStartDay: 'date_day_month',
    sessionStartTime: 'session_start_time'
  }
}

// MARK: - Class

export default class ReservationStateInformationViewModel extends StateInformationViewModel {
  // MARK: - Constructor

  constructor () {
    super(Color.white, String.dashSpace)
  }

  // MARK: - Setter

  /**
   * @param {Localization} localization
   * @param {ReservationsDomainModel|DashboardReservationDomainModel} domainModel
   * */
  setValues (localization, domainModel) {
    const state = Status.state(domainModel.status)
    this.stateColor = State.color(state)

    const startAtDay = DateFormat.string(domainModel.session.startAt, DateFormat.DD_MM_YYYY)
    const dayString = localization.getText(Constants.Text.sessionStartDay, startAtDay)
    const startAtTime = DateFormat.string(domainModel.session.startAt, DateFormat.HH_MM)
    const timeString = localization.getText(Constants.Text.sessionStartTime, startAtTime)
    this.title = dayString + String.space + timeString
  }
}
