export default class CategoryDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _name

  // MARK: - Constructor

  /**
   * @constructor
   * @param {CategoryDataModel} dataModel
   */
  constructor (dataModel) {
    this._name = dataModel.name
  }

  // MARK: - Getter

  /** @return {string} */
  get name () {
    return this._name
  }
}
