import DashboardSectionViewModel from '@/presentation/scene/dashboard/view-model/dashboardSectionViewModel'
import DashboardColCardMessageViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardMessageViewModel'
import PresentationInformationLevel from '@/presentation/common/enum/presentationInformationLevel'
import PresentationCard from '@/presentation/common/enum/presentationCard'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static titleKey = 'current_informations'
  static messagesColClasses = 'py-0 my-1'
  static defaultShowLoader = false
}

// MARK: - Class

export default class DashboardInformationViewModel {
  // MARK: - Properties

  /** @type {DashboardSectionViewModel} */
  _title
  /** @type {DashboardColCardMessageViewModel[]} */
  _messages
  /** @type {DashboardColCardMessageViewModel} */
  _messageLoader

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    this._title = new DashboardSectionViewModel(localization.getText(Constants.titleKey))
    this._messages = []
    this._showLoader = Constants.defaultShowLoader
    this._messageLoader = new DashboardColCardMessageViewModel(PresentationCard.DEFAULT, String.dashSpace)
  }

  // MARK: - Getter

  /** @return {DashboardSectionViewModel} */
  get title () {
    return this._title
  }

  /** @return {DashboardColCardMessageViewModel[]} */
  get messages () {
    return this._messages
  }

  /** @return {boolean} */
  get showMessages () {
    return !this._messageLoader.showLoader
  }

  /** @return {boolean} */
  get showLoader () {
    return this._messageLoader.showLoader
  }

  /** @return {DashboardColCardMessageViewModel} */
  get messageLoader () {
    return this._messageLoader
  }

  // MARK: - Setter

  /** @param {DashboardDomainModel} domainModel */
  setValues (domainModel) {
    const messages = []
    if (domainModel.informations) {
      domainModel.informations.forEach(information => {
        const type = PresentationInformationLevel.toCard(information.level)
        const card = new DashboardColCardMessageViewModel(type, Constants.messagesColClasses)
        card.message = information.message
        messages.push(card)
      })
    }
    this._messages = messages
  }

  /** @param {boolean} value */
  set showLoader (value) {
    this._messageLoader.showLoader = value
  }
}
