export default class LegalNoticesInteractor {
  // MARK: - Properties

  /** @type {LegalNoticesPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LegalNoticesPresenter} presenter
   */
  constructor (presenter) {
    this._presenter = presenter
  }

  // MARK: - From Controller

  /**
   * Show or hide vue
   * @param {boolean} value
   */
  showCalledFromController (value) {
    this._presenter.showFromInteractor = value
  }

  closeTappedFromController () {
    this._presenter.prepareReturnToParentFromInteractor()
  }
}
