<template>
  <custom-sheet
    :view-model="presenter.viewModel"
    @closeClick="controller.cancelTapped()">

    <!-- Confirm button -->
    <custom-button
      :view-model="presenter.viewModel.confirmButton"
      @click="controller.confirmTapped()"
    />

    <!-- Space -->
    <custom-space :view-model="presenter.viewModel.confirmBottomSpace"/>
  </custom-sheet>
</template>

<script>
import SheetComponent from '@/app/common/component/sheet/SheetComponent'

import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import PresentationInjection from '@/presentation/scene/logout/logoutPresentationInjection'

export default {
  name: 'LogoutVue',

  data: () => ({
    /** @type LogoutController */
    controller: null,
    /** @type LogoutPresenter */
    presenter: null
  }),

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  components: {
    customSheet: SheetComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent
  },

  watch: {
    show () {
      this.controller.showCalled(this.show)
    }
  },

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'logout-style'
</style>
