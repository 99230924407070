export default class PresentationInformation {
  // MARK: - Properties

  /** @type {string} */
  _title
  /** @type {string} */
  _message
  /** @type {boolean} */
  _redirectToLogin

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} title
   * @param {string} message
   * @param {boolean} redirectToLogin
   */
  constructor (title, message, redirectToLogin = false) {
    this._title = title
    this._message = message
    this._redirectToLogin = redirectToLogin
  }

  // MARK: - Getter

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get message () {
    return this._message
  }

  /** @return {boolean} */
  get redirectToLogin () {
    return this._redirectToLogin
  }
}
