import DialogViewModel from '@/presentation/view-model/dialog/dialogViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import ButtonSimpleViewModel from '@/presentation/view-model/button/buttonSimpleViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'

// MARK: - Constants

class Constants {
  static TextKey = {
    title: 'legal_notices',
    content: 'legal_notices_content',
    close: 'close'
  }

  static contentClasses = 'text-justify text-caption'
}

// MARK: - Class

export default class LegalNoticesViewModel extends DialogViewModel {
  // MARK: - Properties

  /** @type {CardViewModel} */
  _card
  /** @type {string} */
  _title
  /** @type {string} */
  _content
  /** @type {string} */
  _contentClasses
  /** @type {SpaceViewModel} */
  _closeTopSpace
  /** @type {ButtonSimpleViewModel} */
  _closeButton

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super()

    this._card = new CardViewModel()
    this._title = localization.getText(Constants.TextKey.title)
    this._content = localization.getText(Constants.TextKey.content)
    this._contentClasses = Constants.contentClasses
    this._closeTopSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._closeButton = new ButtonSimpleViewModel(localization, Constants.TextKey.close)
  }

  // MARK: - Getter

  /** @return {CardViewModel} */
  get card () {
    return this._card
  }

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get content () {
    return this._content
  }

  /** @return {string} */
  get contentClasses () {
    return this._contentClasses
  }

  /** @return {SpaceViewModel} */
  get closeTopSpace () {
    return this._closeTopSpace
  }

  /** @return {ButtonSimpleViewModel} */
  get closeButton () {
    return this._closeButton
  }
}
