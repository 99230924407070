<template>
  <div>

    <!-- App Bar -->
    <custom-app-bar :title="presenter.viewModel.title"/>

    <!-- Container -->
    <custom-container :viewModel="presenter.viewModel.container" >

      <!-- Row -->
      <custom-row :viewModel="presenter.viewModel.row">

        <!-- Push notification subscription -->
        <custom-col-card-message
          v-if="presenter.viewModel.showAskPushRegistration"
          :viewModel="presenter.viewModel.askPushRegistration"
          @click="controller.registerToPushTapped()"
        />

        <!-- Subscription expire soon component message -->
        <custom-col-card-message
          v-if="presenter.viewModel.showSubscriptionExpireSoon"
          :viewModel="presenter.viewModel.subscriptionExpireSoon"
        />

        <!-- Subscription expired component message -->
        <custom-col-card-message
          v-if="presenter.viewModel.showSubscriptionExpired"
          :viewModel="presenter.viewModel.subscriptionExpired"
        />

        <!-- Informations title -->
        <custom-section-text
          v-if="presenter.viewModel.showInformations"
          :viewModel="presenter.viewModel.informations.title"
        />

        <!-- Informations component messages -->
        <template v-if="presenter.viewModel.showInformations">
          <custom-col-card-message
            v-for="message in presenter.viewModel.informations.messages"
            :key="message.key"
            :viewModel="message"
          />
        </template>

        <!-- Informations messages loader -->
        <custom-col-card-message
          v-if="presenter.viewModel.informations.showLoader"
          :viewModel="presenter.viewModel.informations.messageLoader"
        />

        <!-- Reservations title -->
        <custom-section-text :viewModel="presenter.viewModel.reservations.title"/>

        <!-- Participated reservation -->
        <custom-col-card-value :viewModel="presenter.viewModel.reservations.participated"/>

        <!-- Reservation canceled -->
        <custom-col-card-value :viewModel="presenter.viewModel.reservations.canceled"/>

        <!-- Reservation of the year -->
        <custom-col-card-value :viewModel="presenter.viewModel.reservations.ofYear"/>

        <!-- Reservation of the month -->
        <custom-col-card-value :viewModel="presenter.viewModel.reservations.ofMonth"/>

        <!-- Reservations subtitle -->
        <custom-section-text :viewModel="presenter.viewModel.reservations.subtitle"/>

        <!-- Next reservation -->
        <custom-col-card-reservation
          v-if="presenter.viewModel.reservations.showNextReservation"
          :viewModel="presenter.viewModel.reservations.nextReservation"
        />

        <!-- No next reservation -->
        <custom-col-card-message
          v-if="presenter.viewModel.reservations.showNoNextReservation"
          :viewModel="presenter.viewModel.reservations.noNextReservation"
        />

        <!-- Subscriptions title -->
        <custom-section-text :viewModel="presenter.viewModel.subscriptions.title"/>

        <!-- Subscriber since -->
        <custom-col-card-details :viewModel="presenter.viewModel.subscriptions.subcriberSince"/>

        <!-- Subscriptions subtitle -->
        <custom-section-text :viewModel="presenter.viewModel.subscriptions.subtitle"/>

        <!-- Valid subscription -->
        <custom-col-card-details
          v-if="presenter.viewModel.subscriptions.showValidSubcription"
          :viewModel="presenter.viewModel.subscriptions.validSubscription"
        />

        <!-- No valid subscription -->
        <custom-col-card-message
          v-if="presenter.viewModel.subscriptions.showNoValidSubcription"
          :viewModel="presenter.viewModel.subscriptions.noValidSubcription"
        />

      </custom-row>
    </custom-container>

    <!-- Refresh -->
    <custom-refresh
      :view-model="presenter.viewModel"
      @click="controller.refreshTapped()"
    />

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.error"/>

  </div>
</template>

<script>
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import DashboardColCardMessageComponent from '@/app/scene/dashboard/component/DashboardColCardMessageComponent'
import DashboardColCardReservationComponent from '@/app/scene/dashboard/component/DashboardColCardReservationComponent'
import DashboardColCardDetailsComponent from '@/app/scene/dashboard/component/DashboardColCardDetailsComponent'
import DashboardColCardComponent from '@/app/scene/dashboard/component/DashboardColCardValueComponent'
import DashboardSectionTextComponent from '@/app/scene/dashboard/component/DashboardSectionComponent'
import ContainerComponent from '@/app/common/component/container/ContainerComponent'
import RowComponent from '@/app/common/component/row/RowComponent'
import RefreshComponent from '@/app/common/component/refresh/RefreshComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import PresentationInjection from '@/presentation/scene/dashboard/dashboardPresentationInjection'

export default {
  name: 'Dashboard',

  components: {
    customAppBar: AppBarComponent,
    customContainer: ContainerComponent,
    customRow: RowComponent,
    customColCardMessage: DashboardColCardMessageComponent,
    customColCardReservation: DashboardColCardReservationComponent,
    customColCardDetails: DashboardColCardDetailsComponent,
    customColCardValue: DashboardColCardComponent,
    customSectionText: DashboardSectionTextComponent,
    customRefresh: RefreshComponent,
    customAlert: AlertComponent
  },

  data: () => ({
    /** @type DashboardController */
    controller: null,
    /** @type DashboardPresenter */
    presenter: null
  }),

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
  @import 'dashboard-style'
</style>
