import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static basePath = 'clients'
  static Path = {
    get: Constants.basePath + '/',
    update: Constants.basePath + '/'
  }
}

// MARK: - Class

export default class ProfileAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _worker

  // MARK: - Constructor

  constructor () {
    const configuration = new ApiConfiguration(true)
    this._worker = new ApiWorker(configuration)
  }

  // MARK: - Get

  /**
   * Call API to get client profile
   * @param {string} clientId client id
   * @return {Promise}
   */
  get (clientId) {
    return this._worker.getRequest(Constants.Path.get + clientId)
  }

  // MARK: - Put

  /**
   * Call API to update client profile
   * @param {string} clientId client id
   * @param {ProfileAPIBody} parameter
   * @return {Promise}
   */
  update (clientId, parameter) {
    return this._worker.putRequest(Constants.Path.update + clientId, parameter)
  }
}
