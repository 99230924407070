import CategoryDomainModel from '@/domain/model/categoryDomainModel'

export default class DashboardSubscriptionDomainModel {
  // MARK: - Properties

  /** @type {string|null} */
  _contract
  /** @type {CategoryDomainModel} */
  _category
  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {int|null} */
  _session
  /** @type {int|null} */
  _remainingSession
  /** @type {boolean} */
  _isForDuration

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardSubscriptionDataModel} dataModel
   */
  constructor (dataModel) {
    this._contract = dataModel.contract
    this._category = new CategoryDomainModel(dataModel.category)
    this._startAt = dataModel.startAt
    this._endAt = dataModel.endAt
    this._session = dataModel.session
    this._remainingSession = dataModel.remainingSession
    this._isForDuration = (dataModel.session === null)
  }

  // MARK: - Getter

  /** @return {string|null} */
  get contract () {
    return this._contract
  }

  /** @return {CategoryDomainModel} */
  get category () {
    return this._category
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {int|null} */
  get session () {
    return this._session
  }

  /** @return {int|null} */
  get remainingSession () {
    return this._remainingSession
  }

  /** @return {boolean} */
  get isForDuration () {
    return this._isForDuration
  }
}
