import String from '@/presentation/utils/string'
import Color from '@/presentation/utils/color'

// MARK: - Constants

class Constants {
  static value = false
  static color = Color.main
  static hideDetails = true
  static label = String.empty
}

// MARK: - Class

export default class CheckboxViewModel {
  // MARK: - Properties

  /** @type {string} */
  _label
  /** @type {boolean} */
  _value
  /** @type {string} */
  _color
  /** @type {boolean} */
  _hideDetails
  /** @type {boolean} */
  _error

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} label
   * @param {boolean} value
   * @param {string} color
   * @param {boolean} hideDetails
   */
  constructor (label = Constants.label,
    value = Constants.value,
    color = Constants.color,
    hideDetails = Constants.hideDetails) {
    this._label = label
    this._value = value
    this._color = color
    this._hideDetails = hideDetails
    this.error = false
  }

  // MARK: - Getter

  /** @return {string} */
  get label () {
    return this._label
  }

  /** @return {boolean} */
  get value () {
    return this._value
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {boolean} */
  get hideDetails () {
    return this._hideDetails
  }

  /** @return {boolean} */
  get error () {
    return this._error
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set value (value) {
    this._value = value
  }

  /** @param {boolean} value */
  set error (value) {
    // We set error just if param is true and checkbox value isn't selected
    this._error = (value && !this.value)
  }

  // MARK: - Methods

  valueChanged () {
    // Show error if checkbox isn't check
    this.error = !this.value
  }
}
