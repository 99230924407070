<template>
  <div>
    <!-- Animation -->
    <custom-col :viewModel="presenter.viewModel.animationCol">
      <!-- Lottie animation -->
      <custom-animation
        :path="presenter.viewModel.animation.path"
        :loop="presenter.viewModel.animation.loop"
        :autoPlay="presenter.viewModel.animation.play"
        :speed="presenter.viewModel.animation.speed"
        :width="presenter.viewModel.animation.size"
        :height="presenter.viewModel.animation.size"
      />
    </custom-col>

    <!-- Description -->
    <div :class="presenter.viewModel.descriptionClasses">
      {{ presenter.viewModel.description }}
    </div>

    <!-- Description space -->
    <custom-space :view-model="presenter.viewModel.descriptionBottomSpace"/>

    <!-- Redirect button -->
    <custom-button
      :view-model="presenter.viewModel.redirectButton"
      @click="controller.redirectTapped()"
    />
  </div>
</template>

<script>
import ColComponent from '@/app/common/component/col/ColComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import Animation from 'lottie-vuejs/src/LottieAnimation.vue'
import PresentationInjection from '@/presentation/scene/not-found/notFoundPresentationInjection'

export default {
  name: 'NotFoundVue',

  data: () => ({
    /** @type NotFoundController */
    controller: null,
    /** @type NotFoundPresenter */
    presenter: null
  }),

  components: {
    customCol: ColComponent,
    customSpace: SpaceComponent,
    customButton: ButtonComponent,
    customAnimation: Animation
  },

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'not-found-style'
</style>
