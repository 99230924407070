<template>
  <div
    :class="viewModel.textClasses"
    v-html="linkify(viewModel.text, viewModel.options)"
  />
</template>

<script>
import TextLinkViewModel from '@/presentation/view-model/text-link/textLinkViewModel'
import linkify from 'linkifyjs/lib/linkify-string'

export default {
  name: 'TextLinkComponent',

  props: {
    viewModel: {
      type: TextLinkViewModel,
      required: true
    }
  },

  methods: {
    linkify
  }
}
</script>
