<template>
  <v-card
    :class="viewModel.classes"
    :color="viewModel.backgroundColor"
    v-on="viewModel.isClickable ? { click: () => clicked() } : {}"
  >
    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-card>
</template>

<script>
import CardViewModel from '@/presentation/view-model/card/cardViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'CardComponent',

  props: {
    viewModel: {
      type: CardViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
