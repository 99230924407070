import ProfileDataEntry from '@/data/repository/profile/profileDataEntry'
import DateFormat from '@/common/utils/dateFormat'

export default class ProfileDomainEntry {
  // MARK: - Properties

  /** @type {string} */
  _name
  /** @type {string} */
  _lastname
  /** @type {string} */
  _email
  /** @type {moment.Moment} */
  _birthday
  /** @type {string} */
  _phone
  /** @type {boolean} */
  _subscribe

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate which contains textfields
   * @param {boolean} subscribe to newsletter
   */
  constructor (validation, subscribe) {
    this._name = validation.name.$model
    this._lastname = validation.lastname.$model
    this._email = validation.email.$model
    this._birthday = DateFormat.date(validation.birthday.$model, DateFormat.DD_MM_YYYY)
    this._phone = validation.phone.$model
    this._subscribe = subscribe
  }

  // MARK: - Conversion

  /**
   * Convert object to Data entry model
   * @return {ProfileDataEntry}
   */
  get toData () {
    return new ProfileDataEntry(this._name,
      this._lastname,
      this._email,
      this._birthday,
      this._phone,
      this._subscribe
    )
  }
}
