import ReservationsSessionDomainModel from '@/domain/scene/reservations/model/reservationsSessionDomainModel'

export default class ReservationsDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {ReservationsSessionDomainModel} */
  _session
  /** @type {int} */
  _status
  /** @type {string} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationsDataModel} dataModel
   */
  constructor (dataModel) {
    this._id = dataModel.id
    this._session = new ReservationsSessionDomainModel(dataModel.session)
    this._status = dataModel.status
    this._pattern = dataModel.pattern
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {ReservationsSessionDomainModel} */
  get session () {
    return this._session
  }

  /** @return {int} */
  get status () {
    return this._status
  }

  /** @return {string} */
  get pattern () {
    return this._pattern
  }
}
