// MARK: - Constants

class Constants {
  static Loader = {
    black: 'loaderBlack.json',
    white: 'loaderWhite.json'
  }

  static notFound = 'notFound.json'

  static prefixPath = 'animation/'
}

// MARK: - Class

export default class Animation {
  // MARK: - Properties

  /** @type {Framework} */
  _vuetify

  // MARK: - Initialization

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._vuetify = vuetify
  }

  // MARK: - Getter

  /** @return {string} */
  get loaderPath () {
    return this._getAnimation(this._vuetify.theme.dark ? Constants.Loader.white : Constants.Loader.black)
  }

  /** @return {string} */
  get notFoundPath () {
    return this._getAnimation(Constants.notFound)
  }

  /** @return {string} */
  _getAnimation (name) {
    return Constants.prefixPath + name
  }
}
