import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'

// MARK: - Constants

class Constants {
  static refreshTextKey = 'refresh'
}

// MARK: - Class

export default class SceneRefreshViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _showRefresh
  /** @type {SpaceViewModel} */
  _refreshSpace
  /** @type {ButtonSmallViewModel} */
  _refreshButton

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    this._showRefresh = false
    this._refreshSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._refreshButton = new ButtonSmallViewModel(localization, Constants.refreshTextKey)
  }

  // MARK: - Getter

  /** @return {boolean} */
  get showRefresh () {
    return this._showRefresh
  }

  /** @return {SpaceViewModel} */
  get refreshTopSpace () {
    return this._refreshSpace
  }

  /** @return {ButtonSmallViewModel} */
  get refreshButton () {
    return this._refreshButton
  }

  /** @return {SpaceViewModel} */
  get refreshBottomSpace () {
    return this._refreshSpace
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showRefresh (value) {
    this._showRefresh = value
  }
}
