import Moment from 'moment'

export default class DashboardReservationSessionDataModel {
  // MARK: - Properties

  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {string|null} */
  _description

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._startAt = Moment(response.startAt)
    this._endAt = Moment(response.endAt)
    this._description = response.description
  }

  // MARK: - Getter

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {string|null} */
  get description () {
    return this._description
  }
}
