// MARK: - Constants

class Constants {
  static defaultSize = 12
}

// MARK: - Class

export default class ColViewModel {
  // MARK: - Properties

  /** @type {int} */
  _xs
  /** @type {int|null} */
  _sm
  /** @type {int|null} */
  _md
  /** @type {int|null} */
  _lg
  /** @type {int|null} */
  _xl
  /** @type {string} */
  _classes

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string|null} classes
   * @param {int} xsAndGreater
   * @param {int|null} smAndGreater
   * @param {int|null} mdAndGreater
   * @param {int|null} lgAndGreater
   * @param {int|null} xlAndGreater
   * @param {int|null} xsOffsetAndGreater
   * @param {int|null} smOffsetAndGreater
   * @param {int|null} mdOffsetAndGreater
   * @param {int|null} lgOffsetAndGreater
   * @param {int|null} xlOffsetAndGreater
   */
  constructor (classes = null,
    xsAndGreater = Constants.defaultSize,
    smAndGreater,
    mdAndGreater,
    lgAndGreater,
    xlAndGreater,
    xsOffsetAndGreater,
    smOffsetAndGreater,
    mdOffsetAndGreater,
    lgOffsetAndGreater,
    xlOffsetAndGreater
  ) {
    this._classes = classes
    this._xs = xsAndGreater
    this._sm = smAndGreater
    this._md = mdAndGreater
    this._lg = lgAndGreater
    this._xl = xlAndGreater
    this._xsOffset = xsOffsetAndGreater
    this._smOffset = smOffsetAndGreater
    this._mdOffset = mdOffsetAndGreater
    this._lgOffset = lgOffsetAndGreater
    this._xlOffset = xlOffsetAndGreater
  }

  // MARK: - Getter

  /** @return {int} */
  get xs () {
    return this._xs
  }

  /** @return {int|null} */
  get sm () {
    return this._sm
  }

  /** @return {int|null} */
  get md () {
    return this._md
  }

  /** @return {int|null} */
  get lg () {
    return this._lg
  }

  /** @return {int|null} */
  get xl () {
    return this._xl
  }

  /** @return {int} */
  get xsOffset () {
    return this._xsOffset
  }

  /** @return {int|null} */
  get smOffset () {
    return this._smOffset
  }

  /** @return {int|null} */
  get mdOffset () {
    return this._mdOffset
  }

  /** @return {int|null} */
  get lgOffset () {
    return this._lgOffset
  }

  /** @return {int|null} */
  get xlOffset () {
    return this._xlOffset
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }
}
