import PresentationCard from '@/presentation/common/enum/presentationCard'

// MARK: - Constants

class Constants {
  static defaultType = PresentationCard.DEFAULT
  static defaultClickable = false
  static classes = 'pa-3'
}

// MARK: - Class

export default class CardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _backgroundColor
  /** @type {string} */
  _classes
  /** @type {boolean} */
  _isClickable

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} backgroundColor
   * @param {boolean} isClickable
   */
  constructor (backgroundColor = PresentationCard.backgroundColor(Constants.defaultType),
    isClickable = Constants.defaultClickable) {
    this._backgroundColor = backgroundColor
    this._classes = Constants.classes
    this._isClickable = isClickable
  }

  // MARK: - Getter

  /** @return {string} */
  get backgroundColor () {
    return this._backgroundColor
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {boolean} */
  get isClickable () {
    return this._isClickable
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }
}
