import LogoutDataRepository from '@/data/repository/logout/logoutDataRepository'
import LogoutInteractor from '@/domain/scene/logout/logoutInteractor'

export default class LogoutDomainInjection {
  // MARK: - Properties

  /** @type {LogoutInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LogoutPresenter} presenter
   */
  constructor (presenter) {
    const repository = new LogoutDataRepository()
    this._interactor = new LogoutInteractor(repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {LogoutInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
