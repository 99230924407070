import { Bus, Events } from '@/presentation/main'

export default class PasswordForgotController {
  // MARK: - Properties

  /** @type {PasswordForgotInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {PasswordForgotInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  vueDestroyed () {
    this._clearBus()
  }

  // MARK: - Methods

  /** @param {boolean} value */
  showCalled (value) {
    this._interactor.showCalledFromController(value)
  }

  validateTapped () {
    this._interactor.validateTappedFromController()
  }

  cancelTapped () {
    this._interactor.cancelTappedFromController()
  }

  // MARK: - Bus

  _loadBus () {
    // Check when alert is closed
    const self = this
    Bus.$on(Events.alertClosed, _ => {
      self._interactor.closeThisSceneFromController()
    })
  }

  _clearBus () {
    Bus.$off(Events.alertClosed)
  }
}
