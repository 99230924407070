<template>
  <div :class="viewModel.classes">
    <h2>{{ viewModel.text }}</h2>
  </div>
</template>

<script>
import SheetTitleViewModel from '@/presentation/view-model/sheet/text/sheetTitleViewModel'

export default {
  name: 'SheetTitleComponent',

  props: {
    viewModel: {
      type: SheetTitleViewModel,
      required: true
    }
  }
}
</script>
