export default class PasswordInitController {
  // MARK: - Properties

  /** @type {PasswordInitInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {PasswordInitInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  // MARK: - Methods

  validateTapped () {
    this._interactor.validateTappedFromController()
  }

  connexionTapped () {
    this._interactor.connexionTappedFromController()
  }
}
