import AppViewModel from '@/presentation/scene/app/appViewModel'

export default class AppPresenter {
  // MARK: - Properties

  /** @type {AppViewModel} */
  _viewModel
  /** @type {any} */
  _installAppPrompt

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._viewModel = new AppViewModel(vuetify)
    this._installAppPrompt = null
  }

  // MARK: - Getter

  /** @return {AppViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  /**
   * @param {boolean} value
   * @param {Event|null} event
   */
  showInstallAppBanner (value, event = null) {
    if (value && event) {
      event.preventDefault()
      this._installAppPrompt = event
    }

    this._viewModel.installAppBanner.show = value
  }

  /**
   * @param {boolean} value
   */
  showInstallAppPrompt (value) {
    if (value) {
      this._installAppPrompt.prompt()
    } else {
      this._installAppPrompt = null
    }
  }

  /** @param {boolean} value */
  set showNavigationFromInteractor (value) {
    this._viewModel.showNavigation = value
  }

  /** @param {boolean} value */
  set centerContentChangedFromInteractor (value) {
    this._viewModel.centerContent = value
  }
}
