// MARK: - Constants

const RESERVATION_TO_LATE = 0

class Constants {
  static genericText = 'error_unknown'
  static reservationToLateText = 'error_reservation_to_late'
}

// MARK: - Enum

export default {
  RESERVATION_TO_LATE,

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  key (value) {
    switch (value) {
      case RESERVATION_TO_LATE:
        return Constants.reservationToLateText
      default:
        return Constants.genericText
    }
  }
}
