<template>
  <v-skeleton-loader
    :type="viewModel.type"
    :height="viewModel.height"
    :class="viewModel.classes"
  />
</template>

<script>
import SkeletonLoaderViewModel from '@/presentation/view-model/skeleton/skeletonViewModel'

export default {
  name: 'SkeletonLoaderComponent',

  props: {
    viewModel: {
      type: SkeletonLoaderViewModel,
      required: true
    }
  }
}
</script>
