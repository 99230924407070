<template>
  <custom-col-card :viewModel="viewModel">

    <!-- State value -->
    <custom-state-information :viewModel="viewModel.stateInformation"/>

    <!-- Description (optional) -->
    <custom-content-item :view-model="viewModel.description"/>

    <!-- Pattern (optional) -->
    <custom-content-item :view-model="viewModel.pattern"/>

  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import CardContentItemComponent from '@/app/common/component/card/CardContentItemComponent'
import StateInformationComponent from '@/app/common/component/state-information/StateInformationComponent'
import ReservationsColCardViewModel from '@/presentation/scene/reservations/view-model/reservationsColCardViewModel'

export default {
  name: 'ReservationsColCardComponent',

  components: {
    customColCard: ColCardComponent,
    customContentItem: CardContentItemComponent,
    customStateInformation: StateInformationComponent
  },

  props: {
    viewModel: {
      type: ReservationsColCardViewModel,
      required: true
    }
  }
}
</script>
