import Vue from 'vue'
import Vuetify from 'vuetify'

// Roles
import Vuelidate from 'vuelidate'

// Custom Icon
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import IconLogo from '@/presentation/assets/icon/logo/LogoIcon'
import IconLogoTitle from '@/presentation/assets/icon/logo/LogoTitleIcon'
import IconNavigationDashboard from '@/presentation/assets/icon/navigation/NavigationDashboardIcon'
import IconNavigationProfile from '@/presentation/assets/icon/navigation/NavigationProfileIcon'
import IconNavigationReservations from '@/presentation/assets/icon/navigation/NavigationReservationsIcon'
import IconNavigationSessions from '@/presentation/assets/icon/navigation/NavigationSessionsIcon'
import IconNavigationSubscriptions from '@/presentation/assets/icon/navigation/NavigationSubscriptionsIcon'

// Localization
import VueI18n from 'vue-i18n'
import EnglishLocalization from '@/presentation/assets/localization/en.json'

// Other
import Moment from 'moment'

Vue.use(Vuetify)
Vue.use(VueI18n)
Vue.use(Vuelidate)

const messages = {
  en: {
    $vuetify: EnglishLocalization
  }
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en',
  messages
})

Moment.locale('fr')

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // Colors
        background: '#FFFFFF',
        black: '#F4F4F4',
        blackDarkMainLight: '#EBC537',
        brownishGrey: '#A1A1A1',
        dark: '#E6E6E0',
        darkLight: '#EFEFEF',
        darkMintGreen: '#18A86B',
        grapefruit: '#FF5A5A',
        green: '#05a860',
        error: '#FF5A5A',
        grayLight: '#676767',
        main: '#EBC537',
        mainDarkSecondaryLight: '#1E1716',
        secondary: '#1E1716',
        veryLightPink: '#2F2F2F',
        white: '#070707',
        yellow: '#DCAD00'
      },
      dark: {
        // Colors
        background: '#050505',
        black: '#0A0A0A',
        blackDarkMainLight: '#050505',
        brownishGrey: '#5D5D5D',
        dark: '#E6E6E0',
        darkLight: '#1F1F24',
        darkMintGreen: '#1DC57A',
        grapefruit: '#FF5A5A',
        green: '#05a860',
        error: '#FF5A5A',
        grayLight: '#969696',
        main: '#EBC537',
        mainDarkSecondaryLight: '#EBC537',
        secondary: '#1E1615',
        veryLightPink: '#CFCFCF',
        white: '#F7F7F7',
        yellow: '#EBC537'
      }
    }
  },
  icons: {
    iconfont: 'mdi',
    values: {
      iconLogo: {
        component: IconLogo,
        props: {
          name: 'LogoIcon'
        }
      },
      iconLogoTitle: {
        component: IconLogoTitle,
        props: {
          name: 'LogoTitleIcon'
        }
      },
      iconNavigationDashboard: {
        component: IconNavigationDashboard,
        props: {
          name: 'NavigationDashboardIcon'
        }
      },
      iconNavigationProfile: {
        component: IconNavigationProfile,
        props: {
          name: 'NavigationProfileIcon'
        }
      },
      iconNavigationReservations: {
        component: IconNavigationReservations,
        props: {
          name: 'NavigationReservationsIcon'
        }
      },
      iconNavigationSessions: {
        component: IconNavigationSessions,
        props: {
          name: 'NavigationSessionsIcon'
        }
      },
      iconNavigationSubscriptions: {
        component: IconNavigationSubscriptions,
        props: {
          name: 'NavigationSubscriptionsIcon'
        }
      }
    }
  },
  lang: {
    t: (key, ...params) => i18n.t(key, ...params)
  }
})
