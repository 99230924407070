import ButtonViewModel from '@/presentation/view-model/button/buttonViewModel'
import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static color = Color.main
  static classes = String.empty
  static small = false
  static text = true
  static justifyCenter = false
}

// MARK: - Class

export default class ButtonSimpleViewModel extends ButtonViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} titleKey
   * @param {string} color
   * @param {string} classes
   */
  constructor (localization,
    titleKey,
    color = Constants.color,
    classes = Constants.classes) {
    super(localization.getText(titleKey),
      color,
      classes,
      Constants.small,
      Constants.text,
      Constants.justifyCenter
    )
  }
}
