<template>
  <v-layout
    :class="viewModel.classes"
    :align-center="viewModel.alignCenter"
    :justify-center="viewModel.justifyCenter"
  >
    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-layout>
</template>

<script>
import LayoutViewModel from '@/presentation/view-model/layout/layoutViewModel'

export default {
  name: 'LayoutComponent',

  props: {
    viewModel: {
      type: LayoutViewModel,
      required: true
    }
  }
}
</script>
