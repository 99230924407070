import LoginViewModel from '@/presentation/scene/login/view-model/loginViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import Route from '@/app/utils/route'
import ContentPresenter from '@/presentation/utils/contentPresenter'

export default class LoginPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {LoginViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    super()
    this._localization = new Localization(vuetify)
    this._validation = validation
    this._viewModel = new LoginViewModel(vuetify, this._localization, validation)
    this._router = router
  }

  // MARK: - Getter

  /** @return {LoginViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return LoginViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  showFormErrorFromInteractor () {
    this._validation.email.$touch()
    this._validation.password.$touch()
    this._viewModel.legalNoticesCheckbox.error = true
  }

  prepareLegalNoticesFromInteractor () {
    this._viewModel.showLegalNotices = true
  }

  prepareCloseLegalNoticesFromInteractor () {
    this._viewModel.showLegalNotices = false
  }

  preparePasswordForgotFromInteractor () {
    this._viewModel.showPasswordForgot = true
  }

  prepareClosePasswordForgotFromInteractor () {
    this._viewModel.showPasswordForgot = false
  }

  prepareConnectedSpaceFromInteractor () {
    this._router.push(Route.dashboardVue)
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.error = presentationError.toInformation
  }
}
