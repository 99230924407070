import String from '@/presentation/utils/string'

// MARK: - Constants

const NAME = 0
const LASTNAME = 1

// MARK: - Enum

export default {
  NAME,
  LASTNAME,

  /** @return {string} */
  model (value) {
    switch (value) {
      case NAME:
        return 'name'
      case LASTNAME:
        return 'lastname'
      default:
        return String.empty
    }
  },

  /** @return {string} */
  labelKey (value) {
    switch (value) {
      case NAME:
        return 'firstname'
      case LASTNAME:
        return 'name'
      default:
        return String.empty
    }
  },

  /** @return {string} */
  requiredKey (value) {
    switch (value) {
      case NAME:
        return 'firstname_required'
      case LASTNAME:
        return 'name_required'
      default:
        return String.empty
    }
  }
}
