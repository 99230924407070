<template>
  <v-col
    :cols="viewModel.xs"
    :sm="viewModel.sm"
    :md="viewModel.md"
    :lg="viewModel.lg"
    :xl="viewModel.xl"
    :offset="viewModel.xsOffset"
    :offset-sm="viewModel.smOffset"
    :offset-md="viewModel.mdOffset"
    :offset-lg="viewModel.lgOffset"
    :offset-xl="viewModel.xlOffset"
    :class="viewModel.classes"
  >

    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-col>
</template>

<script>
import ColViewModel from '@/presentation/view-model/col/colViewModel'

export default {
  name: 'ColComponent',

  props: {
    viewModel: {
      type: ColViewModel,
      required: true
    }
  }
}
</script>
