import SwitchViewModel from '@/presentation/view-model/switch/switchViewModel'

// MARK: - Constants

class Constants {
  static labelKey = 'receiving_email'
  static messageKey = 'email_information'
}

// MARK: - Class

export default class ProfileSubscribeSwitchViewModel extends SwitchViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super(localization.getText(Constants.labelKey),
      localization.getText(Constants.messageKey))
  }
}
