import PushNotificationManager from '@/domain/manager/pushNotificationManager'
import Moment from 'moment'
import DateFormat from '@/common/utils/dateFormat'

export default class AppInteractor {
  // MARK: - Properties

  /** @type {VueRouter} */
  _router
  /** @type {AppDataRepository} */
  _repository
  /** @type {AppPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {VueRouter} router
   * @param {AppDataRepository} repository
   * @param {AppPresenter} presenter
   */
  constructor (router, repository, presenter) {
    this._router = router
    this._repository = repository
    this._presenter = presenter

    // Init push notification manager
    PushNotificationManager.shared = new PushNotificationManager()
  }

  // MARK: From Controller

  /** @param {Event} event */
  nativeAppIsNotInstalledFromController (event) {
    const installAppExpiration = this._repository.installAppExpiration

    let show = true
    // Date exists and it is not expired ? Don't show banner !
    if (installAppExpiration && Moment().isBefore(installAppExpiration)) {
      show = false
    }

    this._presenter.showInstallAppBanner(show, event)
  }

  nativeAppIsInstalledFromController () {
    this._presenter.showInstallAppBanner(false)
  }

  /** @param {int} index */
  installAppTappedFromController (index) {
    // Tap on 'install' ?
    const installTapped = (index === 1)

    // Save current date + X day to not ask again to install app until this date
    if (!installTapped) {
      this._repository.installAppExpiration = DateFormat.addDay(Moment(), 15)
    }

    // Show native prompt to install app ?
    this._presenter.showInstallAppPrompt(installTapped)

    // Hide banner
    this._presenter.showInstallAppBanner(false)
  }

  /** @param {boolean} show */
  navigationStatusChangedFromController (show) {
    // Show or hide navigation
    this._presenter.showNavigationFromInteractor = show
  }

  /** @param {boolean} center */
  centerContentChangedFromController (center) {
    // Change app content position (top or center)
    this._presenter.centerContentChangedFromInteractor = center
  }

  sendUserOnPushManagerFromController () {
    const userId = this._repository.profileId
    if (userId !== null) {
      PushNotificationManager.shared.sendUserId(userId)
    }
  }
}
