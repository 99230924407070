import SubscriptionsDataModel from '@/data/repository/subscriptions/subscriptionsDataModel'
import SubscriptionAPITarget from '@/data/api/target/subscriptionAPITarget'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import RetrieveDataFromAPI from '@/data/utils/retrieveDataFromAPI'
import DataError from '@/data/error/dataError'

export default class SubscriptionsDataRepository extends RetrieveDataFromAPI {
  // MARK: - Properties

  /** @type {SubscriptionAPITarget} */
  _apiTarget
  /** @type {ObjectDataProviderWorker} */
  _dataProviderWorker

  // MARK: - Constructor

  constructor () {
    super()

    this._apiTarget = new SubscriptionAPITarget()
    this._dataProviderWorker = new ObjectDataProviderWorker(DataProviderType.SUBSCRIPTIONS)
  }

  // MARK: - Getter

  /**
   * Get all subscriptions mapped
   * @return {[SubscriptionsDataModel]}
   */
  _getSubscriptionsMapped (response) {
    return response.map(e => {
      return new SubscriptionsDataModel(e)
    })
  }

  /**
   * Get all subscriptions
   * @param {boolean} forceApiReload
   * @return {Promise<[SubscriptionsDataModel]>}
   */
  subscriptions (forceApiReload) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Force reload or data expired on cache ? Call API
      if (forceApiReload || self._dataProviderWorker.isExpired) {
        self._retrieveArray().then(({ data, details }) => {
          // Save data on local
          self._dataProviderWorker.save(data, details.expiredAt)

          const subscriptions = self._getSubscriptionsMapped(data)
          resolve(subscriptions)
        }).catch(e => {
          const error = DataError.fromAnyError(e)
          reject(error)
        })
      } else { // Get data from cache
        const response = self._dataProviderWorker.get
        const subscriptions = self._getSubscriptionsMapped(response)
        resolve(subscriptions)
      }
    })
  }

  // MARK: - Retrieve Data

  _dataTargetPromise (page) {
    return this._apiTarget.getAll(page)
  }
}
