import { v4 as uuidv4 } from 'uuid'
import CommonString from '@/common/utils/string'

// MARK: - Constants

class Constants {
  static textColorSuffix = '--text'
  static dashSpace = ' - '
}

// MARK: - Class

export default class String extends CommonString {
  // MARK: - Properties

  /** @type {string} */
  static textColorSuffix = Constants.textColorSuffix
  /** @type {string} */
  static dashSpace = Constants.dashSpace

  /**
   * @return {string}
   **/
  static uuid () {
    return uuidv4()
  }

  /**
   * @param {string} value
   * @return {string}
   **/
  static brackets (value) {
    return '(' + value + ')'
  }

  /**
   * @param {string} value
   * @return {string}
   **/
  static capitalizeFirstLetter (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
}
