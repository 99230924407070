import SessionsForWeekReservationDataModel from '@/data/repository/sessions-for-week/model/sessionsForWeekReservationDataModel'
import HallDataModel from '@/data/model/hallDataModel'
import CategoryDataModel from '@/data/model/categoryDataModel'
import Moment from 'moment'

export default class SessionsForWeekDataModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {HallDataModel} */
  _hall
  /** @type {CategoryDataModel} */
  _category
  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {moment.Moment} */
  _reservationLimitAt
  /** @type {int|null} */
  _places
  /** @type {string|null} */
  _description
  /** @type {SessionsForWeekReservationDataModel|null} */
  _reservation
  /** @type {int} */
  _placesAvailable

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._id = response.id
    this._hall = new HallDataModel(response.hall)
    this._category = new CategoryDataModel(response.category)
    this._startAt = Moment(response.startAt)
    this._endAt = Moment(response.endAt)
    this._reservationLimitAt = Moment(response.reservationLimitAt)
    this._places = response.places
    this._description = response.description
    if (response.reservation) {
      this._reservation = new SessionsForWeekReservationDataModel(response.reservation)
    }
    this._placesAvailable = response.placesAvailable
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {HallDataModel} */
  get hall () {
    return this._hall
  }

  /** @return {CategoryDataModel} */
  get category () {
    return this._category
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {moment.Moment} */
  get reservationLimitAt () {
    return this._reservationLimitAt
  }

  /** @return {int|null} */
  get places () {
    return this._places
  }

  /** @return {string|null} */
  get description () {
    return this._description
  }

  /** @return {SessionsForWeekReservationDataModel|null} */
  get reservation () {
    return this._reservation
  }

  /** @return {int} */
  get placesAvailable () {
    return this._placesAvailable
  }
}
