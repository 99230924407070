<template>
  <div>
    <!-- State -->
    <div :class="viewModel.stateClasses"/>

    <!-- Title -->
    <span :class="viewModel.titleClasses">
        {{ viewModel.title }}
    </span>
  </div>
</template>

<script>
import StateInformationHeaderViewModel from '@/presentation/view-model/state-information/stateInformationViewModel'

export default {
  name: 'StateInformationHeaderComponent',

  props: {
    viewModel: {
      type: StateInformationHeaderViewModel,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'state-information-style'
</style>
