<template>
  <div :class="viewModel.containerClasses"
       v-if="viewModel.show">
    <v-btn
      :class="viewModel.classes"
      :x-small="viewModel.small"
      :text="viewModel.text"
      :color="viewModel.color"
      :justify-center="viewModel.justifyCenter"
      :block="viewModel.block"
      @click="clicked()"
    >
      {{ viewModel.title }}
    </v-btn>
  </div>
</template>

<script>
import ButtonViewModel from '@/presentation/view-model/button/buttonViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'ButtonComponent',

  props: {
    viewModel: {
      type: ButtonViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
