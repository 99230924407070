import PasswordUpdateDataEntry from '@/data/repository/password/entry/passwordUpdateDataEntry'

export default class PasswordUpdateDomainEntry {
  // MARK: - Properties

  /** @type {string} */
  _current
  /** @type {string} */
  _new

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate which contains textfields
   */
  constructor (validation) {
    this._current = validation.password.$model
    this._new = validation.newPassword.$model
  }

  // MARK: - Conversion

  /**
   * Convert object to Data entry model
   * @return {PasswordUpdateDataEntry}
   */
  get toData () {
    return new PasswordUpdateDataEntry(this._current, this._new)
  }
}
