import RouteDomain from '@/domain/router/routeDomain'
import Icon from '@/presentation/utils/icon'
import Localization from '@/presentation/utils/localization'

// MARK: - Constants

export class Constants {
  static defaultPath = '/'

  static notFoundPath = '*'
  static notFoundName = 'not_found_name'

  static loginPath = '/login'
  static loginName = 'login_title'
  static loginVue = 'login'

  static sessionsPath = '/sessions'
  static sessionsName = 'sessions_title'

  static reservationsPath = '/reservations'
  static reservationsName = 'my_reservations'

  static dashboardPath = '/dashboard'
  static dashboardName = 'dashboard_title'
  static dashboardVue = 'dashboard'

  static subscriptionsPath = '/subscriptions'
  static subscriptionsName = 'my_subscriptions'

  static profilePath = '/profile'
  static profileName = 'my_account'

  static passwordInitPath = '/init-password'
  static passwordInitName = 'init_password_title'

  static passwordUpdatePath = '/profile/update-password'
  static passwordUpdateName = 'update_password'
}

// MARK: - Class

export class RoutePresentation {
  // MARK: - Properties

  /** @type {RouteDomain} */
  _domain
  /** @type {Localization} */
  _localization = null

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify = null) {
    this._domain = new RouteDomain()

    if (vuetify !== null) {
      this._localization = new Localization(vuetify)
    }
  }

  // MARK: - Getter

  /** @return {boolean} */
  get containsToken () {
    return this._domain.containsToken
  }

  /** @return {string} */
  getText (key) {
    if (this._localization !== null) {
      return this._localization.getText(key)
    } else {
      return key
    }
  }

  /** @return {string|null} */
  getIcon (name) {
    switch (name) {
      case Constants.sessionsName:
        return Icon.navigationSessions
      case Constants.reservationsName:
        return Icon.navigationReservations
      case Constants.dashboardName:
        return Icon.navigationDashboard
      case Constants.subscriptionsName:
        return Icon.navigationSubscriptions
      case Constants.profileName:
        return Icon.navigationProfile
      default:
        return null
    }
  }
}
