export default class DashboardInformationDomainModel {
  // MARK: - Properties

  /** @type {number} */
  _level
  /** @type {string} */
  _message

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardInformationDataModel} dataModel
   */
  constructor (dataModel) {
    this._level = dataModel.level
    this._message = dataModel.message
  }

  // MARK: - Getter

  /** @return {number} */
  get level () {
    return this._level
  }

  /** @return {string} */
  get message () {
    return this._message
  }
}
