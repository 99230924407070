import PresentationStatus from '@/presentation/common/enum/presentationStatus'
import PresentationState from '@/presentation/common/enum/presentationState'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import CardContentItemViewModel from '@/presentation/view-model/card/cardContentItemViewModel'
import ReservationStateInformationViewModel from '@/presentation/view-model/reservation/reservationStateInformationViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'

// MARK: - Constants

class Constants {
  static Text = {
    sessionStartDay: 'date_day_month',
    sessionStartTime: 'session_start_time',
    placeAvailable: 'place_available_value'
  }

  static CssClass = {
    col: 'px-0 py-1',
    fontPattern: FontWeight.italic
  }
}

// MARK: - Class

export default class ReservationsColCardViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _key
  /** @type {ReservationStateInformationViewModel} */
  _stateInformation
  /** @type {CardContentItemViewModel} */
  _description
  /** @type {CardContentItemViewModel} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {ReservationsDomainModel} domainModel
   */
  constructor (localization, domainModel) {
    const col = new ColViewModel(Constants.CssClass.col)
    super(undefined, col)

    this._key = domainModel.id

    const status = PresentationStatus.fromDomain(domainModel.status)
    const state = PresentationStatus.state(status)
    const stateColor = PresentationState.color(state)

    // Header
    this._stateInformation = new ReservationStateInformationViewModel()
    this._stateInformation.setValues(localization, domainModel)

    // Description
    const description = domainModel.session.description
    this._description = new CardContentItemViewModel(!!description, PresentationSpace.CLASSIC, description)

    // Pattern
    const pattern = domainModel.pattern
    const patternTopSpace = !description ? PresentationSpace.CLASSIC : undefined
    const patternClasses = String.fromArray([
      Constants.CssClass.fontPattern,
      stateColor + String.textColorSuffix
    ])
    this._pattern = new CardContentItemViewModel(!!pattern, patternTopSpace, pattern, null, patternClasses)
  }

  // MARK: - Getter

  /** @return {string} */
  get key () {
    return this._key
  }

  /** @return {ReservationStateInformationViewModel} */
  get stateInformation () {
    return this._stateInformation
  }

  /** @return {CardContentItemViewModel} */
  get description () {
    return this._description
  }

  /** @return {CardContentItemViewModel} */
  get pattern () {
    return this._pattern
  }
}
