<template>
  <div>
    <!-- App Bar -->
    <custom-app-bar :title="presenter.viewModel.title"/>

    <!-- Form -->
    <form>
      <!-- Email text field -->
      <custom-text-field
        :view-model="presenter.viewModel.emailTextField"
        :vuelidate="$v.email"
      />

      <!-- Email space -->
      <custom-space :view-model="presenter.viewModel.emailBottomSpace"/>

      <!-- Code text field -->
      <custom-text-field
        :view-model="presenter.viewModel.codeTextField"
        :vuelidate="$v.code"
      />

      <!-- Code space -->
      <custom-space :view-model="presenter.viewModel.codeBottomSpace"/>

      <!-- New password text field -->
      <custom-text-field
        :view-model="presenter.viewModel.newPasswordTextField"
        :vuelidate="$v.newPassword"
      />

      <!-- New password space -->
      <custom-space :view-model="presenter.viewModel.newPasswordBottomSpace"/>

      <!-- Confirm password text field -->
      <custom-text-field
        :view-model="presenter.viewModel.confirmPasswordTextField"
        :vuelidate="$v.confirmPassword"
      />

      <!-- Confirm password space -->
      <custom-space :view-model="presenter.viewModel.confirmPasswordBottomSpace"/>

      <!-- Validate button -->
      <custom-button
        :view-model="presenter.viewModel.validateButton"
        @click="controller.validateTapped()"
      />
    </form>

    <!-- Validate space -->
    <custom-space :view-model="presenter.viewModel.validateBottomSpace"/>

    <!-- Connexion button -->
    <custom-button
      :view-model="presenter.viewModel.connexionButton"
      @click="controller.connexionTapped()"
    />

    <!-- Loader -->
    <custom-loader :show="presenter.viewModel.showLoader"/>

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.information"/>

  </div>
</template>

<script>
import AppBarComponent from '@/app/common/component/app-bar/AppBarComponent'
import TextFieldComponent from '@/app/common/component/textfield/TextFieldComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LoaderComponent from '@/app/common/component/loader/LoaderComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import PresentationInjection from '@/presentation/scene/password-init/passwordInitPresentationInjection'
import Presenter from '@/presentation/scene/password-init/passwordInitPresenter'

export default {
  name: 'PasswordInitVue',

  data: () => ({
    /** @type PasswordInitController */
    controller: null,
    /** @type PasswordInitPresenter */
    presenter: null,

    // TextFields (NOTE: Same names are used on TextFieldViewModel, Presenter and Interactor)
    email: null,
    code: null,
    newPassword: null,
    confirmPassword: null
  }),

  components: {
    customAppBar: AppBarComponent,
    customTextField: TextFieldComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent,
    customLoader: LoaderComponent,
    customAlert: AlertComponent
  },

  validations: Presenter.textFieldsValidations,

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$v, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted()
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'password-init-style'
</style>
