import DashboardSectionViewModel from '@/presentation/scene/dashboard/view-model/dashboardSectionViewModel'
import DashboardColCardMessageViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardMessageViewModel'
import DashboardColCardDetailsViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardDetailsViewModel'
import DashboardMessageType from '@/presentation/scene/dashboard/enum/dashboardMessageType'
import DashboardSectionType from '@/presentation/scene/dashboard/enum/dashboardSectionType'
import DateFormat from '@/common/utils/dateFormat'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static titleKey = 'my_subscriptions'
  static subscriberSinceKey = 'subscriber_since'
  static subtitleKey = 'still_valid'
  static ValidSubscriptionKeys = {
    byDuration: 'until',
    bySession: 'for',
    numberSession: 'number_session',
    limitEnd: 'limit_end_date'
  }
}

// MARK: - Class

export default class DashboardSubscriptionsViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization

  /** @type {DashboardSectionViewModel} */
  _title
  /** @type {DashboardColCardDetailsViewModel} */
  _subcriberSince

  /** @type {DashboardSectionViewModel} */
  _subtitle
  /** @type {boolean} */
  _showValidSubcription
  /** @type {DashboardColCardDetailsViewModel} */
  _validSubscription
  /** @type {boolean} */
  _showNoValidSubcription
  /** @type {DashboardColCardMessageViewModel} */
  _noValidSubcription

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    this._localization = localization

    this._title = new DashboardSectionViewModel(localization.getText(Constants.titleKey))
    this._subcriberSince = new DashboardColCardDetailsViewModel()

    this._subtitle = new DashboardSectionViewModel(localization.getText(Constants.subtitleKey), DashboardSectionType.SUBTITLE)
    this._showValidSubcription = false
    this._validSubscription = new DashboardColCardDetailsViewModel()
    this._showNoValidSubcription = false

    // No valid subscription ?
    const noValidSubcriptionType = DashboardMessageType.NO_VALID_SUBSCRIPTION
    this._noValidSubcription = new DashboardColCardMessageViewModel(DashboardMessageType.toCard(noValidSubcriptionType))
    this._noValidSubcription.message = localization.getText(DashboardMessageType.messageKey(noValidSubcriptionType))
  }

  // MARK: - Getter

  /** @return {DashboardSectionViewModel} */
  get title () {
    return this._title
  }

  /** @return {DashboardColCardDetailsViewModel} */
  get subcriberSince () {
    return this._subcriberSince
  }

  /** @return {DashboardSectionViewModel} */
  get subtitle () {
    return this._subtitle
  }

  /** @return {boolean} */
  get showValidSubcription () {
    return this._showValidSubcription && !this._noValidSubcription.showLoader
  }

  /** @return {DashboardColCardDetailsViewModel} */
  get validSubscription () {
    return this._validSubscription
  }

  /** @return {boolean} */
  get showNoValidSubcription () {
    return this._showNoValidSubcription || this._noValidSubcription.showLoader
  }

  /** @return {DashboardColCardMessageViewModel} */
  get noValidSubcription () {
    return this._noValidSubcription
  }

  // MARK: - Setter

  /** @param {DashboardDomainModel} domainModel */
  setValues (domainModel) {
    // Subscriber since
    this._subcriberSince.title = this._localization.getText(Constants.subscriberSinceKey)
    this._subcriberSince.value = DateFormat.string(domainModel.startDateFirstSubscription, DateFormat.DD_MM_YYYY)

    // Valid susbcription ?
    const isValidSubscription = !!domainModel.validSubscriptionEnding
    this._showValidSubcription = isValidSubscription
    this._showNoValidSubcription = !isValidSubscription

    // Valid subscription ?
    let validSubscriptionTitle
    let validSubscriptionValue
    if (domainModel.validSubscriptionEnding) {
      const validSubscriptionEnding = domainModel.validSubscriptionEnding
      const endAtString = DateFormat.string(validSubscriptionEnding.endAt, DateFormat.DD_MM_YYYY)

      // By Duration
      if (validSubscriptionEnding.isForDuration) {
        validSubscriptionTitle = this._localization.getText(Constants.ValidSubscriptionKeys.byDuration)
        validSubscriptionValue = endAtString
      } else { // By number of session
        validSubscriptionTitle = this._localization.getText(Constants.ValidSubscriptionKeys.bySession)
        const remainingSession = validSubscriptionEnding.remainingSession || 0
        const sessionString = this._localization.getPluralText(Constants.ValidSubscriptionKeys.numberSession,
          remainingSession > 0,
          [remainingSession])
        const endDateString = this._localization.getText(Constants.ValidSubscriptionKeys.limitEnd, endAtString)
        validSubscriptionValue = sessionString + String.space + endDateString
      }
    } else {
      validSubscriptionTitle = String.empty
      validSubscriptionValue = String.empty
    }
    this._validSubscription.title = validSubscriptionTitle
    this._validSubscription.value = validSubscriptionValue
  }

  /** @param {boolean} value */
  set showLoader (value) {
    this._subcriberSince.showLoader = value
    this._noValidSubcription.showLoader = value
  }
}
