import Route from '@/app/utils/route'
import NavigationTabbarViewModel from '@/presentation/view-model/navigation/navigationTabbarViewModel'
import NavigationFloatingViewModel from '@/presentation/view-model/navigation/navigationFloatingViewModel'

export default class NavigationViewModel {
  // MARK: - Properties

  /** @type {[Route]} */
  _router
  /** @type {NavigationFloatingViewModel} */
  _floatingBar
  /** @type {NavigationTabbarViewModel} */
  _tabbar

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._router = Route.getAllForNavigation(vuetify)
    this._floatingBar = new NavigationFloatingViewModel(vuetify)
    this._tabbar = new NavigationTabbarViewModel()
  }

  // MARK: - Getter

  /** @return {[Route]} */
  get router () {
    return this._router
  }

  /** @return {NavigationFloatingViewModel} */
  get floatingBar () {
    return this._floatingBar
  }

  /** @return {NavigationTabbarViewModel} */
  get tabbar () {
    return this._tabbar
  }
}
