import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import ValueDataProviderWorker from '@/data/data-provider/worker/valueDataProviderWorker'

export default class AppDataRepository {
  // MARK: - Properties

  /** @type {ValueDataProviderWorker} */
  _askInstallAppDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _profileDataProviderWorker

  // MARK: - Constructor

  constructor () {
    this._askInstallAppDataProviderWorker = new ValueDataProviderWorker(DataProviderType.ASK_INSTALL_APP)
    this._profileDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.PROFILE)
  }

  // MARK: - Getter

  /**
   * Get install app expiration
   * @return {moment.Moment|null}
   */
  get installAppExpiration () {
    return this._askInstallAppDataProviderWorker.get
  }

  /**
   * Get client id
   * @return {string|null}
   */
  get profileId () {
    return this._profileDataProviderWorker.get.id
  }

  // MARK: - Setter

  /** @param {moment.Moment} value */
  set installAppExpiration (value) {
    this._askInstallAppDataProviderWorker.save(value)
  }
}
