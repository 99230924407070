import PushNotificationManager from '@/domain/manager/pushNotificationManager'

export default class LogoutInteractor {
  // MARK: - Properties

  /** @type {LogoutDataRepository} */
  _repository
  /** @type {LogoutPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LogoutDataRepository} repository
   * @param {LogoutPresenter} presenter
   */
  constructor (repository, presenter) {
    this._repository = repository
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {}

  // MARK: - To Repository

  // Logout is confirmed, we remove all data from repository
  confirmTappedFromController () {
    // Remove all cache data
    this._repository.removeAll()

    // Unregister for push notification
    PushNotificationManager.shared.unregistration()

    // Go to login success message
    this._presenter.prepareLoginFromInteractor()
  }

  // MARK: - From Controller

  /**
   * Show or hide vue
   * @param {boolean} value
   */
  showCalledFromController (value) {
    this._presenter.showFromInteractor = value
  }

  // Close vue
  cancelTappedFromController () {
    this._presenter.prepareReturnToParentFromInteractor()
  }
}
