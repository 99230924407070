import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'
import { email, required } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static labelKey = 'email'
  static required = true
  static type = 'email'
  static requiredKey = 'email_required'
  static validKey = 'error_field_email'
}

// MARK: - Class

export default class TextFieldEmailViewModel extends TextFieldViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization.getText(Constants.labelKey),
      Constants.required,
      Constants.type
    )

    this._localization = localization
    this._validation = validation
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return {
      email: {
        required,
        email
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.email.$dirty) return errors
    !this._validation.email.email && errors.push(this._localization.getText(Constants.validKey))
    !this._validation.email.required && errors.push(this._localization.getText(Constants.requiredKey))
    return errors
  }
}
