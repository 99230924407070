import ReservationAPITarget from '@/data/api/target/reservationAPITarget'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import RetrieveDataFromAPI from '@/data/utils/retrieveDataFromAPI'
import DataError from '@/data/error/dataError'

export default class ReservationDataRepository extends RetrieveDataFromAPI {
  // MARK: - Properties

  /** @type {ReservationAPITarget} */
  _apiTarget
  /** @type {ObjectDataProviderWorker} */
  _dataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _sessionsForWeekDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _reservationsDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _dashboardDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _subscriptionsDataProviderWorker

  // MARK: - Constructor

  constructor () {
    super()

    this._apiTarget = new ReservationAPITarget()
    this._dataProviderWorker = new ObjectDataProviderWorker(DataProviderType.RESERVATIONS)

    this._sessionsForWeekDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.SESSIONS)
    this._reservationsDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.RESERVATIONS)
    this._dashboardDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.DASHBOARD)
    this._subscriptionsDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.SUBSCRIPTIONS)
  }

  // MARK: - Getter

  /**
   * Add reservation
   * @param {ReservationDataEntry} parameter
   */
  addReservation (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Call API
      self._apiTarget.add(parameter.toAPIBody).then(_ => {
        // Remove all reservations on local
        self._dataProviderWorker.remove()
        resolve()
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }

  /**
   * Update reservation
   * @param {string} reservationId
   * @param {ReservationDataEntry} parameter
   */
  updateReservation (reservationId, parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Call API
      self._apiTarget.update(reservationId, parameter.toAPIBody).then(_ => {
        // Remove all reservations on local
        self._dataProviderWorker.remove()
        resolve()
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }

  removeOtherAfterReservation () {
    this._sessionsForWeekDataProviderWorker.remove()
    this._reservationsDataProviderWorker.remove()
    this._dashboardDataProviderWorker.remove()
    this._subscriptionsDataProviderWorker.remove()
  }
}
