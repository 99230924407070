import String from '@/presentation/utils/string'
import ContainerLayoutFlexViewModel from '@/presentation/view-model/container-layout-flex/containerLayoutFlexViewModel'
import ContainerViewModel from '@/presentation/view-model/container/containerViewModel'
import LayoutViewModel from '@/presentation/view-model/layout/layoutViewModel'
import FlexViewModel from '@/presentation/view-model/flex/flexViewModel'

// MARK: - Constants

class Constants {
  static Container = {
    fluid: true,
    classes: null,
    fillHeight: true
  }

  static Layout = {
    classes: String.empty,
    alignCenter: true,
    justifyCenter: true
  }

  static Flex = {
    defaultClasses: String.empty,
    childClasses: 'xs10 sm5 md5 lg3 xl2'
  }
}

// MARK: - Class

export default class ContainerLayoutFlexCenterViewModel extends ContainerLayoutFlexViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {boolean} isSubChildContainer
   */
  constructor (isSubChildContainer = false) {
    const container = new ContainerViewModel(Constants.Container.fluid,
      Constants.Container.classes,
      Constants.Container.fillHeight)

    const layout = new LayoutViewModel(Constants.Layout.classes,
      Constants.Layout.alignCenter,
      Constants.Layout.justifyCenter)

    const flexClasses = isSubChildContainer ? Constants.Flex.childClasses : Constants.Flex.defaultClasses
    const flex = new FlexViewModel(flexClasses)

    super(container, layout, flex)
  }
}
