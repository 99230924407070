import ValueDataProviderWorker from '@/data/data-provider/worker/valueDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'

// MARK: - Constants

class Constants {
  static Authorization = {
    key: 'Authorization',
    valuePrefix: 'Bearer '
  }

  static QueryParams = {
    pageKey: 'page'
  }

  static ContentType = {
    key: 'Content-Type',
    value: 'application/json'
  }
}

// MARK: - Class

export default class ApiConfiguration {
  // MARK: - Properties

  /** @type {boolean} */
  _needAuthorization
  /** @type {ValueDataProviderWorker} */
  _tokenDataProviderWorker

  // MARK: - Constructor

  constructor (needAuthorization) {
    this._needAuthorization = needAuthorization
    this._tokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.TOKEN)
  }

  // MARK: - Getter

  /** @type {[string]} */
  get _headers () {
    const headers = {}
    headers[Constants.ContentType.key] = Constants.ContentType.value

    // Contains authorisation ?
    if (this._needAuthorization) {
      const token = this._tokenDataProviderWorker.get
      if (token) {
        headers[Constants.Authorization.key] = Constants.Authorization.valuePrefix + this._tokenDataProviderWorker.get
      }
    }

    return headers
  }

  /**
   * @param {int?} page
   * @return {[string]}
   */
  _params (page) {
    const params = {}
    // Contains authorisation ?
    if (page !== undefined) {
      params[Constants.QueryParams.pageKey] = page
    }

    return params
  }

  /**
   * @param {int?} page
   * @return {AxiosRequestConfig}
   */
  config (page = undefined) {
    return {
      baseURL: process.env.VUE_APP_API_URL,
      headers: this._headers,
      params: this._params(page)
    }
  }
}
