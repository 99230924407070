import LoginAPIBody from '@/data/api/body/loginAPIBody'

export default class LoginDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _email
  /** @type {string} */
  _password

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} email
   * @param {string} password
   */
  constructor (email, password) {
    this._email = email
    this._password = password
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {LoginAPIBody}
   */
  get toAPIBody () {
    return new LoginAPIBody(this._email, this._password)
  }
}
