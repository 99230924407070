import TextFieldPasswordViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordViewModel'
import { required, minLength } from 'vuelidate/lib/validators'

// MARK: - Constants

class Constants {
  static labelKey = 'new_password'
  static autocomplete = 'new-password'
  static requiredKey = 'new_password_required'
  static minLengthKey = 'error_password_new'
  static minLength = 6
}

// MARK: - Class

export default class TextFieldPasswordNewViewModel extends TextFieldPasswordViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization,
      validation,
      Constants.labelKey,
      Constants.autocomplete
    )

    this._localization = localization
    this._validation = validation
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return {
      newPassword: {
        required,
        minLength: minLength(Constants.minLength)
      }
    }
  }

  /** @return {string} */
  static get validationKey () {
    return Object.keys(this.validation)[0]
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.newPassword.$dirty) return errors
    !this._validation.newPassword.required && errors.push(this._localization.getText(Constants.requiredKey))
    !this._validation.newPassword.minLength && errors.push(this._localization.getText(Constants.minLengthKey))
    return errors
  }
}
