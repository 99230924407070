import String from '@/common/utils/string'

export default class HallDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _address
  /** @type {string} */
  _city
  /** @type {string} */
  _zipcode

  // MARK: - Constructor

  /**
   * @constructor
   * @param {HallDataModel} dataModel
   */
  constructor (dataModel) {
    this._address = dataModel.address
    this._city = dataModel.city
    this._zipcode = dataModel.zipcode
  }

  // MARK: - Getter

  /** @return {string} */
  get completeName () {
    return this._city + String.space + String.toBracketsInto(this._zipcode)
  }

  /** @return {string} */
  get address () {
    return this._address
  }

  /** @return {string} */
  get city () {
    return this._city
  }

  /** @return {string} */
  get zipcode () {
    return this._zipcode
  }
}
