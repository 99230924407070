import TextFieldEmailViewModel from '@/presentation/view-model/textfield/textFieldEmailViewModel'
import ButtonClassicViewModel from '@/presentation/view-model/button/buttonClassicViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import SheetViewModel from '@/presentation/view-model/sheet/sheetViewModel'

// MARK: - Constants

class Constants {
  static TextKeys = {
    title: 'forgot_password_title',
    description: 'forgot_description'
  }

  static Buttons = {
    validateTitleKey: 'validate',
    cancelTitleKey: 'cancel'
  }
}

// MARK: - Class

export default class PasswordForgotViewModel extends SheetViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {TextFieldEmailViewModel} */
  _emailTextField
  /** @type {SpaceViewModel} */
  _emailBottomSpace
  /** @type {ButtonClassicViewModel} */
  _validateButton
  /** @type {SpaceViewModel} */
  _validateBottomSpace
  /** @type {boolean} */
  _showLoader
  /** @type {PresentationInformation|null} */
  _information

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization,
      Constants.TextKeys.title,
      Constants.TextKeys.description,
      Constants.Buttons.cancelTitleKey
    )

    this._localization = localization
    this._validation = validation

    this._emailTextField = new TextFieldEmailViewModel(localization, validation)
    this._emailBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._validateButton = new ButtonClassicViewModel(localization, Constants.Buttons.validateTitleKey)
    this._validateBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._showLoader = false
    this._information = null
  }

  // MARK: - Getter

  /** @return {array} */
  static get textFieldsValidations () {
    return TextFieldEmailViewModel.validation
  }

  /** @type {TextFieldEmailViewModel} */
  get emailTextField () {
    return this._emailTextField
  }

  /** @type {SpaceViewModel} */
  get emailBottomSpace () {
    return this._emailBottomSpace
  }

  /** @type {ButtonClassicViewModel} */
  get validateButton () {
    return this._validateButton
  }

  /** @type {SpaceViewModel} */
  get validateBottomSpace () {
    return this._validateBottomSpace
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {PresentationInformation|null} */
  get information () {
    return this._information
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }

  /** @param {PresentationInformation} value */
  set information (value) {
    this._information = value
  }
}
