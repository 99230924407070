<template>
  <div>
    <!-- TextField component -->
    <v-text-field
      v-model="vuelidate.$model"
      v-if="viewModel.showForm"
      :label="viewModel.label"
      :error-messages="viewModel.errorMessage"
      :required="viewModel.required"
      :placeholder="viewModel.placeholder"
      :type="viewModel.type"
      :color="viewModel.color"
      :outlined="viewModel.outlined"
      :clearable="viewModel.clearable"
      :autocomplete="viewModel.autocomplete"
      @input="vuelidate.$touch()"
      @blur="vuelidate.$touch()"
    />

    <!-- Loader component -->
    <custom-skeleton
      v-if="viewModel.showLoader"
      :viewModel="viewModel.loader"
    />
  </div>
</template>

<script>
import SkeletonComponent from '@/app/common/component/skeleton/SkeletonComponent'
import TextFieldViewModel from '@/presentation/view-model/textfield/textFieldViewModel'

export default {
  name: 'TextFieldComponent',

  props: {
    viewModel: {
      type: TextFieldViewModel,
      required: true
    },
    vuelidate: {
      required: true
    }
  },

  components: {
    customSkeleton: SkeletonComponent
  }
}
</script>
