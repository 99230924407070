import Animation from '@/presentation/utils/animation'
import AnimationViewModel from '@/presentation/view-model/animation/animationViewModel'

// MARK: - Constants

class Constants {
  static loop = true
  static speed = 1
}

// MARK: - Class

export default class NotFoundAnimationViewModel extends AnimationViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    const animation = new Animation(vuetify)
    super(animation.notFoundPath, Constants.loop, true, Constants.speed, Constants.size)
  }
}
