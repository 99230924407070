import PasswordInitDomainInjection from '@/domain/scene/password-init/passwordInitDomainInjection'
import PasswordInitController from '@/presentation/scene/password-init/passwordInitController'
import PasswordInitPresenter from '@/presentation/scene/password-init/passwordInitPresenter'

export default class PasswordInitPresentationInjection {
  // MARK: - Properties

  /** @type {PasswordInitController} */
  _controller
  /** @type {PasswordInitPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    this._presenter = new PasswordInitPresenter(vuetify, validation, router)

    // Domain
    const domainInjection = new PasswordInitDomainInjection(validation, this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new PasswordInitController(interactor)
  }

  // MARK: - Getter

  /** @return {PasswordInitController} */
  get controller () {
    return this._controller
  }

  /** @return {PasswordInitPresenter} */
  get presenter () {
    return this._presenter
  }
}
