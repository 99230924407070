// MARK: - Constants

class Constants {
  static dense = true
}

// MARK: - Class

export default class RowViewModel {
  // MARK: - Properties

  /** @type {string} */
  _classes
  /** @type {boolean} */
  _dense

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string|null} classes
   * @param {boolean} dense
   */
  constructor (
    classes = null,
    dense = Constants.dense
  ) {
    this._classes = classes
    this._dense = dense
  }

  // MARK: - Getter

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {boolean} */
  get dense () {
    return this._dense
  }
}
