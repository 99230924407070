import ContainerLayoutFlexFullViewModel from '@/presentation/view-model/container-layout-flex/containerLayoutFlexFullViewModel'
import ContainerLayoutFlexCenterViewModel from '@/presentation/view-model/container-layout-flex/containerLayoutFlexCenterViewModel'
import BannerViewModel from '@/presentation/view-model/banner/bannerViewModel'
import Localization from '@/presentation/utils/localization'

// MARK: - Constants

class Constants {
  static Theme = {
    dark: 'dark',
    light: 'light'
  }

  static Banner = {
    title: 'install_app_title',
    buttons: ['close', 'install']
  }
}

// MARK: - Class

export default class AppViewModel {
  // MARK: - Properties

  /** @type {Framework} */
  _vuetify
  /** @type {BannerViewModel} */
  _installAppBanner
  /** @type {ContainerLayoutFlexViewModel} */
  _container
  /** @type {boolean} */
  _showNavigation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._vuetify = vuetify
    const localization = new Localization(vuetify)

    this._installAppBanner = new BannerViewModel(localization,
      Constants.Banner.title,
      Constants.Banner.buttons
    )

    this._showNavigation = false
    this._container = new ContainerLayoutFlexFullViewModel()
  }

  // MARK: - Getter

  /** @return {BannerViewModel} */
  get installAppBanner () {
    return this._installAppBanner
  }

  /** @return {ContainerLayoutFlexViewModel} */
  get container () {
    return this._container
  }

  /** @return {boolean} */
  get showNavigation () {
    return this._showNavigation
  }

  /** @return {string} */
  get theme () {
    return this._vuetify.theme.dark ? Constants.Theme.dark : Constants.Theme.light
  }

  /** @return {string} */
  get backgroundColor () {
    return this._vuetify.theme.themes[this.theme].background
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showNavigation (value) {
    this._showNavigation = value
  }

  /** @param {boolean} value */
  set centerContent (value) {
    if (value) {
      this._container = new ContainerLayoutFlexCenterViewModel()
    } else {
      this._container = new ContainerLayoutFlexFullViewModel()
    }
  }
}
