export default class Router {
  // MARK: - Properties

  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {VueRouter} router
   */
  constructor (router) {
    this._router = router
  }

  // MARK: - Getter

  /** @return {boolean} */
  get _hasHistory () {
    return window.history.length > 2
  }

  /** @param {string} route */
  backTo (route) {
    if (this._hasHistory) {
      this._router.back()
    } else {
      this._router.push(route)
    }
  }
}
