// MARK: - Constants

const RESERVE = 0
const WAITING = 1
const CANCEL = 2

// MARK: - Enum

export default {
  RESERVE,
  WAITING,
  CANCEL,

  // MARK: - Methods

  /**
   * @param {int} value corresponding to this enum
   * @return {boolean}
   */
  checkIfTextFieldIsValid (value) {
    switch (value) {
      case CANCEL:
        return true
      default:
        return false
    }
  }
}
