export default class LogoutController {
  // MARK: - Properties

  /** @type {LogoutInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LogoutInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  // MARK: - Methods

  /** @param {boolean} value */
  showCalled (value) {
    this._interactor.showCalledFromController(value)
  }

  confirmTapped () {
    this._interactor.confirmTappedFromController()
  }

  cancelTapped () {
    this._interactor.cancelTappedFromController()
  }
}
