import Moment from 'moment'
import HallDataModel from '@/data/model/hallDataModel'
import CategoryDataModel from '@/data/model/categoryDataModel'

export default class ReservationsSessionDataModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {HallDataModel} */
  _hall
  /** @type {CategoryDataModel} */
  _category
  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {string|null} */
  _description

  // MARK: - Constructor

  constructor (response) {
    this._id = response.id
    this._hall = new HallDataModel(response.hall)
    this._category = new CategoryDataModel(response.category)
    this._startAt = Moment(response.startAt)
    this._endAt = Moment(response.endAt)
    this._description = response.description
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {HallDataModel} */
  get hall () {
    return this._hall
  }

  /** @return {CategoryDataModel} */
  get category () {
    return this._category
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {string|null} */
  get description () {
    return this._description
  }
}
