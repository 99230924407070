import DashboardSectionType from '@/presentation/scene/dashboard/enum/dashboardSectionType'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static type = DashboardSectionType.TITLE
  static showTopSpace = true
}

// MARK: - Class

export default class DashboardSectionViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _showTopSpace
  /** @type {SpaceViewModel} */
  _topSpace
  /** @type {string} */
  _text
  /** @type {string} */
  _classes
  /** @type {ColViewModel} */
  _col

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} text
   * @param {string} type of text
   * @param {boolean} showTopSpace
   */
  constructor (text,
    type = Constants.type,
    showTopSpace = Constants.showTopSpace) {
    this._showTopSpace = showTopSpace
    this._topSpace = new SpaceViewModel(DashboardSectionType.space(type))
    this._text = text
    this._classes = DashboardSectionType.classes(type) + String.space + DashboardSectionType.color()
    this._col = new ColViewModel()
  }

  // MARK: - Getter

  /** @return {boolean} value */
  get showTopSpace () {
    return this._showTopSpace
  }

  /** @return {SpaceViewModel} value */
  get topSpace () {
    return this._topSpace
  }

  /** @return {string} */
  get text () {
    return this._text
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {ColViewModel} */
  get col () {
    return this._col
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showTopSpace (value) {
    this._showTopSpace = value
  }
}
