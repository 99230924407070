<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path d="M21,8 L21,5.125 C21,4.50367966 20.4963203,4 19.875,4 L18,4 L18,5 C18,5.55228475 17.5522847,6 17,6 C16.4477153,6 16,5.55228475 16,5 L16,4 L8,4 L8,5 C8,5.55228475 7.55228475,6 7,6 C6.44771525,6 6,5.55228475 6,5 L6,4 L4.125,4 C3.50367966,4 3,4.50367966 3,5.125 L3,8 L21,8 Z M21,10 L3,10 L3,20.875 C3,21.4963203 3.50367966,22 4.125,22 L19.875,22 C20.4963203,22 21,21.4963203 21,20.875 L21,10 Z M8,2 L16,2 L16,1 C16,0.44771525 16.4477153,0 17,0 C17.5522847,0 18,0.44771525 18,1 L18,2 L19.7,2 C21.5225397,2 23,3.47746033 23,5.3 L23,20.7 C23,22.5225397 21.5225397,24 19.7,24 L4.3,24 C2.47746033,24 1,22.5225397 1,20.7 L1,5.3 C1,3.47746033 2.47746033,2 4.3,2 L6,2 L6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 L8,2 Z" id="path-1"></path>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationSessionsIcon'
})
</script>
