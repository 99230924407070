import PasswordInitDataEntry from '@/data/repository/password/entry/passwordInitDataEntry'

export default class PasswordInitDomainEntry {
  // MARK: - Properties

  /** @type {string} */
  _email
  /** @type {string} */
  _code
  /** @type {string} */
  _password

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate which contains textfields
   */
  constructor (validation) {
    this._email = validation.email.$model
    this._code = validation.code.$model
    this._password = validation.newPassword.$model
  }

  // MARK: - Conversion

  /**
   * Convert object to Data entry model
   * @return {PasswordInitDataEntry}
   */
  get toData () {
    return new PasswordInitDataEntry(this._email, this._code, this._password)
  }
}
