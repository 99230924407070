import SceneCardViewModel from '@/presentation/view-model/scene/sceneCardViewModel'

// MARK: - Constants

class Constants {
  static titleKey = 'my_reservations'

  static CardLoader = {
    count: 20,
    height: '70px'
  }
}

// MARK: - Class

export default class ReservationsViewModel extends SceneCardViewModel {
  // MARK: - Properties

  /** @type {[ReservationsColCardViewModel]} */
  _cards

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super(localization,
      Constants.titleKey,
      Constants.CardLoader.count,
      Constants.CardLoader.height
    )
  }

  // MARK: - Getter

  /** @return {boolean} */
  get showCards () {
    return !this.showLoader && this._cards
  }

  /** @return {[ReservationsColCardViewModel]} */
  get cards () {
    return this._cards
  }

  // MARK: - Setter

  /** @param {[ReservationsColCardViewModel]} values */
  set cards (values) {
    this._cards = values
  }
}
