import SessionsForWeekReservationDomainModel from '@/domain/scene/sessions-for-week/model/sessionsForWeekReservationDomainModel'
import DomainStatus from '@/domain/enum/domainStatus'

export default class SessionsForWeekDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _reservationLimitAt
  /** @type {string|null} */
  _description
  /** @type {int} */
  _placesAvailable
  /** @type {SessionsForWeekReservationDomainModel|null} */
  _reservation
  /** @type {boolean} */
  _isComplete
  /** @type {int} */
  _nextStatus

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SessionsForWeekDomainModel} dataModel
   */
  constructor (dataModel) {
    this._id = dataModel.id
    this._startAt = dataModel.startAt
    this._reservationLimitAt = dataModel.reservationLimitAt
    this._description = dataModel.description
    this._placesAvailable = dataModel.placesAvailable
    if (dataModel.reservation) {
      this._reservation = new SessionsForWeekReservationDomainModel(dataModel.reservation)
    }
    this._isComplete = dataModel.isComplete

    if (dataModel.reservation && dataModel.reservation.status === DomainStatus.RESERVE) {
      this._nextStatus = DomainStatus.CANCEL
    } else if (this._placesAvailable === 0) {
      this._nextStatus = DomainStatus.WAITING
    } else {
      this._nextStatus = DomainStatus.RESERVE
    }
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get reservationLimitAt () {
    return this._reservationLimitAt
  }

  /** @return {string|null} */
  get description () {
    return this._description
  }

  /** @return {int} */
  get placesAvailable () {
    return this._placesAvailable
  }

  /** @return {SessionsForWeekReservationDomainModel|null} */
  get reservation () {
    return this._reservation
  }

  /** @return {boolean} */
  get isComplete () {
    return this._isComplete
  }

  /** @return {int} */
  get nextStatus () {
    return this._nextStatus
  }
}
