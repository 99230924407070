import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'
import NotFoundAnimationViewModel from '@/presentation/scene/not-found/view-model/notFoundAnimationViewModel'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import Localization from '@/presentation/utils/localization'
import FontWeight from '@/presentation/utils/fontWeight'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'

// MARK: - Constants

class Constants {
  static TextKey = {
    description: 'not_found_description',
    redirect: 'not_found_redirection'
  }

  static descriptionClasses = 'text-h6 text-center ' + FontWeight.medium
}

// MARK: - Class

export default class NotFoundViewModel {
  // MARK: - Properties

  /** @type {ColViewModel} */
  _animationCol
  /** @type {NotFoundAnimationViewModel} */
  _animation
  /** @type {string} */
  _description
  /** @type {string} */
  _descriptionClasses
  /** @type {SpaceViewModel} */
  _descriptionBottomSpace
  /** @type {ButtonSmallViewModel} */
  _redirectButton

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    const localization = new Localization(vuetify)

    this._animationCol = new ColViewModel(null, 12, null, 8, null, null, 0, null, 2)
    this._animation = new NotFoundAnimationViewModel(vuetify)
    this._description = localization.getText(Constants.TextKey.description)
    this._descriptionClasses = Constants.descriptionClasses
    this._descriptionBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._redirectButton = new ButtonSmallViewModel(localization, Constants.TextKey.redirect)
  }

  // MARK: - Getter

  /** @return {ColViewModel} */
  get animationCol () {
    return this._animationCol
  }

  /** @return {NotFoundAnimationViewModel} */
  get animation () {
    return this._animation
  }

  /** @return {string} */
  get description () {
    return this._description
  }

  /** @return {string} */
  get descriptionClasses () {
    return this._descriptionClasses
  }

  /** @return {SpaceViewModel} */
  get descriptionBottomSpace () {
    return this._descriptionBottomSpace
  }

  /** @return {ButtonSmallViewModel} */
  get redirectButton () {
    return this._redirectButton
  }
}
