import ValueDataProviderWorker from '@/data/data-provider/worker/valueDataProviderWorker'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'

export default class LogoutDataRepository {
  // MARK: - Properties

  /** @type {ValueDataProviderWorker} */
  _tokenDataProviderWorker
  /** @type {ValueDataProviderWorker} */
  _refreshTokenDataProviderWorker

  /** @type {ObjectDataProviderWorker} */
  _sessionsForWeekDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _reservationsDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _dashboardDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _subscriptionsDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _profileDataProviderWorker

  // MARK: - Constructor

  constructor () {
    this._tokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.TOKEN)
    this._refreshTokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.REFRESH_TOKEN)

    this._sessionsForWeekDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.SESSIONS)
    this._reservationsDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.RESERVATIONS)
    this._dashboardDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.DASHBOARD)
    this._subscriptionsDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.SUBSCRIPTIONS)
    this._profileDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.PROFILE)
  }

  // MARK: - Methods

  removeAll () {
    // Sensitive data
    this._tokenDataProviderWorker.remove()
    this._refreshTokenDataProviderWorker.remove()

    // Other data
    this._sessionsForWeekDataProviderWorker.remove()
    this._reservationsDataProviderWorker.remove()
    this._dashboardDataProviderWorker.remove()
    this._subscriptionsDataProviderWorker.remove()
    this._profileDataProviderWorker.remove()
  }
}
