import ProfileViewModel from '@/presentation/scene/profile/view-model/profileViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import ContentPresenter from '@/presentation/utils/contentPresenter'
import Route from '@/app/utils/route'
import DateFormat from '@/common/utils/dateFormat'

export default class ProfilePresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {ProfileViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    super()

    this._localization = new Localization(vuetify)
    this._validation = validation
    this._viewModel = new ProfileViewModel(vuetify, this._localization, validation)
    this._router = router
  }

  // MARK: - Getter

  /** @return {ProfileViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return ProfileViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    // Show or hide skeleton on form
    this._viewModel.nameTextField.showLoader = value
    this._viewModel.lastnameTextField.showLoader = value
    this._viewModel.emailTextField.showLoader = value
    this._viewModel.birthdayTextField.showLoader = value
    this._viewModel.phoneTextField.showLoader = value
    this._viewModel.subscribeSwitch.showLoader = value

    // Show or hide buttons
    const showButton = !value
    this._viewModel.saveButton.show = showButton
    this._viewModel.passwordUpdateButton.show = showButton
    this._viewModel.logoutButton.show = showButton
    this._viewModel.legalNoticesButton.show = showButton
  }

  /** @param {boolean} value */
  set showUpdateLoaderFromInteractor (value) {
    this._viewModel.showUpdateLoader = value
  }

  /** @param {boolean} value */
  set showRefreshFromInteractor (value) {
    this._viewModel.showRefresh = value
  }

  showTextFieldsErrorFromInteractor () {
    this._validation.name.$touch()
    this._validation.lastname.$touch()
    this._validation.email.$touch()
    this._validation.birthday.$touch()
    this._validation.phone.$touch()
  }

  /** @param {ProfileDomainModel} value */
  set profileFromInteractor (value) {
    this._validation.name.$model = value.name
    this._validation.lastname.$model = value.lastname
    this._validation.email.$model = value.email
    this._validation.birthday.$model = DateFormat.string(value.birthday, DateFormat.DD_MM_YYYY)
    this._validation.phone.$model = value.phone
    this.viewModel.subscribeSwitch.value = value.subscribe
  }

  birthdayChanged () {
    this._viewModel.birthdayTextField.updateAfterChange()
  }

  preparePasswordUpdateFromInteractor () {
    this._router.push(Route.passwordUpdatePath)
  }

  prepareLegalNoticesFromInteractor () {
    this._viewModel.showLegalNotices = true
  }

  prepareLogoutConfirmationFromInteractor () {
    this._viewModel.showLogoutConfirmation = true
  }

  /**
   * @param {boolean} redirectToLogin
   */
  prepareCloseLogoutConfirmationFromInteractor (redirectToLogin) {
    this._viewModel.showLogoutConfirmation = false

    // Redirect to login
    if (redirectToLogin) {
      this._router.replace(Route.loginVue)
    }
  }

  prepareCloseLegalNoticesFromInteractor () {
    this._viewModel.showLegalNotices = false
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.error = presentationError.toInformation
  }
}
