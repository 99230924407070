<template>
  <div>
    <!-- Reservation form -->
    <custom-sheet
      :view-model="presenter.viewModel"
      @closeClick="controller.cancelTapped()">

      <!-- Pattern text field -->
      <custom-text-field
        :view-model="presenter.viewModel.patternTextField"
        :vuelidate="$v.pattern"
      />

      <!-- Space -->
      <custom-space :view-model="presenter.viewModel.patternBottomSpace"/>

      <!-- Validate button -->
      <custom-button
        :view-model="presenter.viewModel.validateButton"
        @click="controller.validateTapped()"
      />

      <!-- Space -->
      <custom-space :view-model="presenter.viewModel.validateBottomSpace"/>
    </custom-sheet>

    <!-- Loader -->
    <custom-loader :show="presenter.viewModel.showLoader"/>

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.information"/>
  </div>
</template>

<script>
import SheetComponent from '@/app/common/component/sheet/SheetComponent'
import TextFieldComponent from '@/app/common/component/textfield/TextFieldComponent'

import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LoaderComponent from '@/app/common/component/loader/LoaderComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import PresentationInjection from '@/presentation/scene/reservation/reservationPresentationInjection'
import Presenter from '@/presentation/scene/reservation/reservationPresenter'
import Parameter from '@/presentation/scene/reservation/parameter/reservationPresentationParameter'

export default {
  name: 'ReservationVue',

  data: () => ({
    /** @type ReservationController */
    controller: null,
    /** @type ReservationPresenter */
    presenter: null,

    // TextFields (NOTE: Same names are used on TextFieldViewModel,Presenter and Interactor)
    pattern: null
  }),

  props: {
    show: {
      type: Boolean,
      required: true
    },

    parameter: {
      type: Parameter,
      required: false
    }
  },

  components: {
    customSheet: SheetComponent,
    customTextField: TextFieldComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent,
    customLoader: LoaderComponent,
    customAlert: AlertComponent
  },

  validations: Presenter.textFieldsValidations,

  watch: {
    show () {
      this.controller.showCalled(this.show)
    },
    parameter () {
      this.controller.parameter = this.parameter
    }
  },

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$v, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>
