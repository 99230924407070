// MARK: - Constants

class Constants {
  static normal = 200
}

// MARK: - Class

export default class Delay {
  // MARK: - Properties

  /** @type {int} */
  static normal = Constants.normal
}
