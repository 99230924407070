// MARK: - Constants

class Constants {
  static emailKey = 'email'
}

// MARK: - Class

export default class PasswordForgotAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} email
   */
  constructor (email) {
    this._body[Constants.emailKey] = email
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
