import ProfileDataModel from '@/data/repository/profile/profileDataModel'
import ProfileAPITarget from '@/data/api/target/profileAPITarget'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import RetrieveDataFromAPI from '@/data/utils/retrieveDataFromAPI'
import DataError from '@/data/error/dataError'
import DataErrorType from '@/data/error/dataErrorType'

export default class ProfileDataRepository extends RetrieveDataFromAPI {
  // MARK: - Properties

  /** @type {ProfileAPITarget} */
  _apiTarget
  /** @type {ObjectDataProviderWorker} */
  _dataProviderWorker

  // MARK: - Constructor

  constructor () {
    super()

    this._apiTarget = new ProfileAPITarget()
    this._dataProviderWorker = new ObjectDataProviderWorker(DataProviderType.PROFILE)
  }

  // MARK: - Getter

  /**
   * Get profile mapped
   * @return {ProfileDataModel}
   */
  _getProfileMapped (response) {
    return new ProfileDataModel(response)
  }

  /**
   * Get client profile
   * @param {boolean} forceApiReload
   * @return {Promise<ProfileDataModel>}
   */
  profile (forceApiReload) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Force reload or data expired on cache ? Call API
      if (forceApiReload || self._dataProviderWorker.isExpired) {
        // Get profile id from DAO
        const profileId = self._profileId
        if (profileId) {
          self._retrieveObject(self._apiTarget.get(profileId)).then(({ data, details }) => {
            // Save data on local
            self._dataProviderWorker.save(data, details.expiredAt)

            const profile = self._getProfileMapped(data)
            resolve(profile)
          }).catch(e => {
            const error = DataError.fromAnyError(e)
            reject(error)
          })
        } else {
          const error = new DataError(DataErrorType.NO_VALUE)
          reject(error)
        }
      } else { // Get data from cache
        const response = self._dataProviderWorker.get
        const profile = self._getProfileMapped(response)
        resolve(profile)
      }
    })
  }

  /**
   * Update client profile
   * @param {ProfileDataEntry} parameter
   */
  updateProfile (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Get profile id from DAO
      const profileId = self._profileId
      if (profileId) {
        // Call API
        self._apiTarget.update(profileId, parameter.toAPIBody).then(response => {
          // Update data on local
          self._dataProviderWorker.update(response)
          resolve()
        }).catch(e => {
          const error = DataError.fromAnyError(e)
          reject(error)
        })
      } else {
        const error = new DataError(DataErrorType.NO_VALUE)
        reject(error)
      }
    })
  }

  /**
   * @return {string|null}
   */
  get _profileId () {
    return this._dataProviderWorker.get.id
  }
}
