// MARK: - Constants

class Constants {
  static refreshTokenKey = 'refreshToken'
}

// MARK: - Class

export default class ReloginAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} refreshToken
   */
  constructor (refreshToken) {
    this._body[Constants.refreshTokenKey] = refreshToken
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
