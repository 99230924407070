import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'

// MARK: - Constants

class Constants {
  static CssClass = {
    lineHeaderState: 'line-header-state',
    lineHeaderStateForm: 'rounded-pill',
    lineHeaderTitle: 'line-header-title',

    fontWeightMedium: FontWeight.medium
  }
}

// MARK: - Class

export default class StateInformationViewModel {
  // MARK: - Properties

  /** @type {string} */
  _stateClasses
  /** @type {string} */
  _title
  /** @type {string} */
  _titleClasses

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} stateColor
   * @param {string} title
   */
  constructor (stateColor, title) {
    this.setStateClasses(stateColor)

    // Title
    this._title = title
    this._titleClasses = String.fromArray([
      Constants.CssClass.fontWeightMedium,
      Constants.CssClass.lineHeaderTitle
    ])
  }

  // MARK: - Getter

  /** @return {string} */
  get stateClasses () {
    return this._stateClasses
  }

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get titleClasses () {
    return this._titleClasses
  }

  // MARK: - Setter

  /** @param {string} stateColor */
  setStateClasses (stateColor) {
    this._stateClasses = String.fromArray([
      Constants.CssClass.lineHeaderStateForm,
      Constants.CssClass.lineHeaderState,
      stateColor
    ])
  }

  /** @param {string} value */
  set stateColor (value) {
    this.setStateClasses(value)
  }

  /** @param {string} value */
  set title (value) {
    this._title = value
  }
}
