import ReservationsDomainInjection from '@/domain/scene/reservations/reservationsDomainInjection'
import ReservationsController from '@/presentation/scene/reservations/reservationsController'
import ReservationsPresenter from '@/presentation/scene/reservations/reservationsPresenter'

export default class ReservationsPresentationInjection {
  // MARK: - Properties

  /** @type {ReservationsController} */
  _controller
  /** @type {ReservationsPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._presenter = new ReservationsPresenter(vuetify)

    // Domain
    const domainInjection = new ReservationsDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new ReservationsController(interactor)
  }

  // MARK: - Getter

  /** @return {ReservationsController} */
  get controller () {
    return this._controller
  }

  /** @return {ReservationsPresenter} */
  get presenter () {
    return this._presenter
  }
}
