export default class LayoutViewModel {
  // MARK: - Properties

  /** @type {string} */
  _classes
  /** @type {boolean} */
  _alignCenter
  /** @type {boolean} */
  _justifyCenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} classes
   * @param {boolean} alignCenter
   * @param {boolean} justifyCenter
   */
  constructor (classes,
    alignCenter,
    justifyCenter) {
    this._classes = classes
    this._alignCenter = alignCenter
    this._justifyCenter = justifyCenter
  }

  // MARK: - Getter

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {boolean} */
  get alignCenter () {
    return this._alignCenter
  }

  /** @return {boolean} */
  get justifyCenter () {
    return this._justifyCenter
  }
}
