<template>
  <div v-if="viewModel.showMain">
    <custom-structure
      :view-model="viewModel.structure"
      :show="viewModel.showContent">

      <!-- Content -->
      <custom-content :view-model="viewModel.content">

        <!-- Title -->
        <custom-title :view-model="viewModel.title"/>

        <!-- Description -->
        <custom-description :view-model="viewModel.description"/>

        <!-- Space -->
        <custom-space :view-model="viewModel.descriptionBottomSpace"/>

        <!-- Slot needed to put content here -->
        <slot></slot>

        <!-- Close button -->
        <custom-button
          :view-model="viewModel.closeButton"
          @click="closeClicked()"
        />

        <!-- Space -->
        <custom-space :view-model="viewModel.closeBottomSpace"/>

      </custom-content>
    </custom-structure>
  </div>
</template>

<script>
import SheetStructureComponent from '@/app/common/component/sheet/SheetStructureComponent'
import SheetContentComponent from '@/app/common/component/sheet/SheetContentComponent'
import SheetTitleComponent from '@/app/common/component/sheet/SheetTitleComponent'
import SheetDescriptionComponent from '@/app/common/component/sheet/description/SheetDescriptionComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import SheetViewModel from '@/presentation/view-model/sheet/sheetViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'SheetComponent',

  props: {
    viewModel: {
      type: SheetViewModel,
      required: true
    }
  },

  components: {
    customStructure: SheetStructureComponent,
    customContent: SheetContentComponent,
    customTitle: SheetTitleComponent,
    customDescription: SheetDescriptionComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent
  },

  methods: {
    closeClicked () {
      this.$emit(Events.closeButtonClicked)
    }
  }
}
</script>
