<template>
  <div v-if="viewModel.showRefresh">
    <!-- Refresh top space -->
    <custom-space :view-model="viewModel.refreshTopSpace"/>

    <!-- Refresh button -->
    <custom-button
      :view-model="viewModel.refreshButton"
      @click="clicked()"
    />

    <!-- Refresh bottom space -->
    <custom-space :view-model="viewModel.refreshBottomSpace"/>
  </div>
</template>

<script>
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SceneRefreshViewModel from '@/presentation/view-model/scene/sceneRefreshViewModel'
import { Events } from '@/presentation/main'

export default {
  name: 'RefreshComponent',

  components: {
    customSpace: SpaceComponent,
    customButton: ButtonComponent
  },

  props: {
    viewModel: {
      type: SceneRefreshViewModel,
      required: true
    }
  },

  methods: {
    clicked () {
      this.$emit(Events.clicked)
    }
  }
}
</script>
