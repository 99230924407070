import LogoutViewModel from '@/presentation/scene/logout/logoutViewModel'
import Localization from '@/presentation/utils/localization'
import ContentPresenter from '@/presentation/utils/contentPresenter'
import { Bus, Events } from '@/presentation/main'

export default class LogoutPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {LogoutViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {VueRouter} router
   */
  constructor (vuetify, router) {
    super()
    this._localization = new Localization(vuetify)
    this._viewModel = new LogoutViewModel(this._localization)
    this._router = router
  }

  // MARK: - Getter

  /** @return {LogoutViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showFromInteractor (value) {
    // Send status for App
    this._viewModel.show = value
  }

  prepareLoginFromInteractor () {
    // Send status for App
    Bus.$emit(Events.closeLogoutAsked, true)
  }

  prepareReturnToParentFromInteractor () {
    // Send status for App
    Bus.$emit(Events.closeLogoutAsked, false)
  }
}
