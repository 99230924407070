<template>
  <v-layout
    :justify-center="viewModel.layoutJustifyCenter"
    :align-center="viewModel.layoutAlignCenter">
    <v-flex
      :class="viewModel.flexClasses">

      <!-- Slot needed to add content here -->
      <slot></slot>

    </v-flex>
  </v-layout>
</template>

<script>
import SheetContentViewModel from '@/presentation/view-model/sheet/sheetContentViewModel'

export default {
  name: 'SheetContentComponent',

  props: {
    viewModel: {
      type: SheetContentViewModel,
      required: true
    }
  }
}
</script>
