import HallDomainModel from '@/domain/model/hallDomainModel'
import CategoryDomainModel from '@/domain/model/categoryDomainModel'

export default class ReservationsSessionDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {HallDomainModel} */
  _hall
  /** @type {CategoryDomainModel} */
  _category
  /** @type {moment.Moment} */
  _startAt
  /** @type {moment.Moment} */
  _endAt
  /** @type {string|null} */
  _description

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationsSessionDataModel} dataModel
   */
  constructor (dataModel) {
    this._id = dataModel.id
    this._hall = new HallDomainModel(dataModel.hall)
    this._category = new CategoryDomainModel(dataModel.category)
    this._startAt = dataModel.startAt
    this._endAt = dataModel.endAt
    this._description = dataModel.description
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @return {HallDomainModel} */
  get hall () {
    return this._hall
  }

  /** @return {CategoryDomainModel} */
  get category () {
    return this._category
  }

  /** @return {moment.Moment} */
  get startAt () {
    return this._startAt
  }

  /** @return {moment.Moment} */
  get endAt () {
    return this._endAt
  }

  /** @return {string|null} */
  get description () {
    return this._description
  }
}
