export default class ProfileDomainModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {string} */
  _name
  /** @type {string} */
  _lastname
  /** @type {string} */
  _email
  /** @type {moment.Moment} */
  _birthday
  /** @type {string} */
  _phone
  /** @type {boolean} */
  _subscribe

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ProfileDataModel} dataModel
   */
  constructor (dataModel) {
    this._id = dataModel.id
    this._name = dataModel.name
    this._lastname = dataModel.lastname
    this._email = dataModel.email
    this._birthday = dataModel.birthday
    this._phone = dataModel.phone
    this._subscribe = dataModel.subscribe
  }

  // MARK: - Getter

  /** @type {string} */
  get id () {
    return this._id
  }

  /** @type {string} */
  get name () {
    return this._name
  }

  /** @type {string} */
  get lastname () {
    return this._lastname
  }

  /** @type {string} */
  get email () {
    return this._email
  }

  /** @type {moment.Moment} */
  get birthday () {
    return this._birthday
  }

  /** @type {string} */
  get phone () {
    return this._phone
  }

  /** @type {boolean} */
  get subscribe () {
    return this._subscribe
  }
}
