import PasswordForgotAPIBody from '@/data/api/body/password/passwordForgotAPIBody'

export default class PasswordForgotDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _email

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} email
   */
  constructor (email) {
    this._email = email
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {PasswordForgotAPIBody}
   */
  get toAPIBody () {
    return new PasswordForgotAPIBody(this._email)
  }
}
