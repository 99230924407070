import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static Path = {
    login: 'login/client',
    refresh: 'token/refresh'
  }
}

// MARK: - Class

export default class LoginAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _worker

  // MARK: - Constructor

  constructor () {
    const configuration = new ApiConfiguration(false)
    this._worker = new ApiWorker(configuration)
  }

  // MARK: - Post

  /**
   * Call API to log client
   * @param {LoginAPIBody} parameter
   * @return {Promise}
   */
  login (parameter) {
    return this._worker.postRequest(Constants.Path.login, parameter, false)
  }

  /**
   * Call API to relog client
   * @param {ReloginAPIBody} parameter
   * @return {Promise}
   */
  refreshToken (parameter) {
    return this._worker.postRequest(Constants.Path.refresh, parameter, false)
  }
}
