import DomainError from '@/domain/error/domainError'
import ReservationDomainEntry from '@/domain/scene/reservation/reservationDomainEntry'
import DomainStatus from '@/domain/enum/domainStatus'
import { Bus, Events } from '@/presentation/main'

export default class ReservationInteractor {
  // MARK: - Properties

  /** @type {ReservationDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {ReservationPresenter} */
  _presenter

  /** @type {ReservationDomainModel} */
  _reservation
  /** @type {string|null} */
  _reservationId

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {ReservationPresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter
  }

  // MARK: - From Controller

  /**
   * Show or hide vue
   * @param {boolean} value
   */
  showCalledFromController (value) {
    // Clear textfields value before show reservation sheet
    if (value) {
      this._presenter.resetTexfFieldsFromInteractor()
    }

    this._presenter.showFromInteractor = value
  }

  // Reserve place on session from repository
  validateTappedFromController () {
    // Check if textfield is invalid ?
    if (DomainStatus.checkIfTextFieldIsValid(this._reservation.status) && this._validation.$invalid) {
      // Show error on all invalids text fields
      this._presenter.showTextFieldsErrorFromInteractor()
      return
    }

    // Close form
    this._presenter.showFromInteractor = false

    // Show loader
    this._presenter.showLoaderFromInteractor = true

    const self = this
    const entry = new ReservationDomainEntry(this._reservation.sessionId, this._reservation.status, this._validation)

    // Call request
    let request
    if (this._reservationId) {
      request = this._repository.updateReservation(this._reservationId, entry.toDataEntry)
    } else {
      request = this._repository.addReservation(entry.toDataEntry)
    }

    request.then(function () {
      // Remove sessions and dashboard on database
      self._repository.removeOtherAfterReservation()

      // Reload sessions for week
      Bus.$emit(Events.sessionsForWeekChanged)

      // Show success message
      self._presenter.prepareSuccessMessageFromInteractor()

      // Hide loader
      self._presenter.showLoaderFromInteractor = false
    }).catch(e => {
      // Send error to presenter
      self._presenter.prepareErrorFromInteractor = new DomainError(e)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false
    })
  }

  // MARK: - From Controller

  cancelTappedFromController () {
    // Close refresh
    this._closeThisSceneFromController()
  }

  closeThisSceneFromController () {
    // Close refresh
    this._closeThisSceneFromController()
  }

  // MARK: - Methods

  // Close refresh
  _closeThisSceneFromController () {
    this._presenter.prepareReturnToParentFromInteractor()
  }

  // MARK: - Setter

  /** @param {ReservationDomainParameter} value */
  set parameter (value) {
    this._reservation = value.toDomainModel
    this._reservationId = value.reservationId

    // Send reservation details to presentation
    this._presenter.reservationFromInteractor = this._reservation
  }
}
