import ValueDataProviderWorker from '@/data/data-provider/worker/valueDataProviderWorker'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import LoginAPITarget from '@/data/api/target/loginAPITarget'
import TokenAPIResponse from '@/data/api/response/tokenAPIResponse'
import DataError from '@/data/error/dataError'
import DataErrorType from '@/data/error/dataErrorType'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import LogoutDataRepository from '@/data/repository/logout/logoutDataRepository'

export default class LoginDataRepository {
  // MARK: - Properties

  /** @type {LoginAPITarget} */
  _apiTarget

  /** @type {ValueDataProviderWorker} */
  _tokenDataProviderWorker
  /** @type {ValueDataProviderWorker} */
  _refreshTokenDataProviderWorker
  /** @type {ObjectDataProviderWorker} */
  _profileDataProviderWorker

  // MARK: - Constructor

  constructor () {
    this._apiTarget = new LoginAPITarget()

    this._tokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.TOKEN)
    this._refreshTokenDataProviderWorker = new ValueDataProviderWorker(DataProviderType.REFRESH_TOKEN)
    this._profileDataProviderWorker = new ObjectDataProviderWorker(DataProviderType.PROFILE)

    // Remove all cache data
    this._removeAllDataProvider()
  }

  // MARK: - Getter

  /**
   * Update current password
   * @param {LoginDataEntry} parameter
   */
  login (parameter) {
    const self = this
    return new Promise(function (resolve, reject) {
      self._apiTarget.login(parameter.toAPIBody).then(response => {
        const apiResponse = new TokenAPIResponse(response)
        if (apiResponse.isValid) {
          // Save token and profile on cache
          self._tokenDataProviderWorker.save(apiResponse.token)
          self._refreshTokenDataProviderWorker.save(apiResponse.refreshToken)
          self._profileDataProviderWorker.save(apiResponse.content.data, apiResponse.content.details.expiredAt)

          resolve()
        } else {
          const error = new DataError(DataErrorType.BAD_CREDENTIALS)
          reject(error)
        }
      }).catch(e => {
        const error = DataError.fromAnyError(e)
        reject(error)
      })
    })
  }

  // MARK: - Others

  /** Remove all cache data */
  _removeAllDataProvider () {
    const logoutDataRepository = new LogoutDataRepository()
    logoutDataRepository.removeAll()
  }
}
