<template>
  <v-container
    :fluid="viewModel.fluid"
    :class="viewModel.classes"
    :fill-height="viewModel.fillHeight"
  >
    <!-- Slot needed to put content here -->
    <slot></slot>
  </v-container>
</template>

<script>
import ContainerViewModel from '@/presentation/view-model/container/containerViewModel'

export default {
  name: 'ContainerComponent',

  props: {
    viewModel: {
      type: ContainerViewModel,
      required: true
    }
  }
}
</script>
