import PasswordForgotViewModel from '@/presentation/scene/password-forgot/passwordForgotViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import PresentationSuccess from '@/presentation/common/success/presentationSuccess'
import PresentationSuccessType from '@/presentation/common/enum/presentationSuccess'
import ContentPresenter from '@/presentation/utils/contentPresenter'
import { Bus, Events } from '@/presentation/main'
import String from '@/presentation/utils/string'

export default class PasswordForgotPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {PasswordForgotViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    super()
    this._localization = new Localization(vuetify)
    this._validation = validation
    this._viewModel = new PasswordForgotViewModel(this._localization, validation)
    this._router = router
  }

  // MARK: - Getter

  /** @return {PasswordForgotViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return PasswordForgotViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showFromInteractor (value) {
    this._viewModel.show = value
  }

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  showTextFieldsErrorFromInteractor () {
    this._validation.email.$touch()
  }

  prepareReturnToParentFromInteractor () {
    // Send status for App
    Bus.$emit(Events.closeForgotAsked)
  }

  prepareSuccessMessageFromInteractor () {
    const success = new PresentationSuccess(this._localization, PresentationSuccessType.PASSWORD_FORGOT)
    this._viewModel.information = success.toInformation
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.information = presentationError.toInformation
  }

  resetTexfFieldsFromInteractor () {
    this._validation.email.$model = String.empty
    this._validation.$reset()
  }
}
