import DAOWrapper from '@/data/data-provider/wrapper/daoWrapper'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'

// MARK: - Class

export default class ValueDataProviderWorker {
  // MARK: - Properties

  _wrapper

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} type corresponding to DataProviderType
   */
  constructor (type) {
    const key = DataProviderType.key(type)
    this._wrapper = new DAOWrapper(key)
  }

  // MARK: - Getter

  get get () {
    return this._wrapper.get
  }

  // MARK: - Setter

  save (value) {
    this._wrapper.save(value)
  }

  remove () {
    this._wrapper.remove()
  }
}
