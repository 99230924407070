import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static classes = String.empty
}

// MARK: - Class

export default class SheetTextViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {string} */
  _text
  /** @type {string} */
  _classes

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} textKey
   * @param {string} classes
   */
  constructor (localization, textKey,
    classes = Constants.classes) {
    this._localization = localization
    this._text = localization.getText(textKey)
    this._classes = classes
  }

  // MARK: - Getter

  /** @return {string} */
  get text () {
    return this._text
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  // MARK: - Setter

  /** @param {string} value */
  set text (value) {
    this._text = value
  }

  /** @param {string} value */
  set textKey (value) {
    this._text = this._localization.getText(value)
  }
}
