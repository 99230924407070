import Axios from 'axios'
import ApiResponseError from '@/data/api/response-error/apiResponseError'
import ReloginDataRepository from '@/data/repository/relogin/reloginDataRepository'

// MARK: - Class

export default class ApiWorker {
  // MARK: - Properties

  /** @type {ApiConfiguration} */
  _configuration

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ApiConfiguration} configuration
   */
  constructor (configuration) {
    this._configuration = configuration
  }

  // MARK: - Get

  /**
   * @param {string} url
   * @param {int?} page
   * @param {boolean} refreshToken
   */
  getRequest (url, page = undefined, refreshToken = true) {
    const self = this
    return new Promise(function (resolve, reject) {
      Axios.get(url, self._configuration.config(page)).then(response => {
        resolve(response.data)
      }).catch(e => {
        self._interpretError(e, refreshToken).then(_ => {
          // Refresh token works, recall current API
          self.getRequest(url, page, false).then(response => {
            resolve(response)
          }).catch(e => {
            reject(e)
          })
        }).catch(e => {
          reject(e)
        })
      })
    })
  }

  // MARK: - Post

  /**
   * @param {string} url
   * @param {any} parameter
   * @param {boolean} refreshToken
   */
  postRequest (url, parameter, refreshToken = true) {
    const self = this
    return new Promise(function (resolve, reject) {
      Axios.post(url, parameter.body, self._configuration.config()).then(response => {
        resolve(response.data)
      }).catch(e => {
        self._interpretError(e, refreshToken).then(_ => {
          // Refresh token works, recall current API
          self.postRequest(url, parameter, false).then(response => {
            resolve(response)
          }).catch(e => {
            reject(e)
          })
        }).catch(e => {
          reject(e)
        })
      })
    })
  }

  // MARK: - Put

  /**
   * @param {string} url
   * @param {any} parameter
   * @param {boolean} refreshToken
   */
  putRequest (url, parameter, refreshToken = true) {
    const self = this
    return new Promise(function (resolve, reject) {
      Axios.put(url, parameter.body, self._configuration.config()).then(response => {
        resolve(response.data)
      }).catch(e => {
        self._interpretError(e, refreshToken).then(_ => {
          // Refresh token works, recall current API
          self.putRequest(url, parameter, false).then(response => {
            resolve(response)
          }).catch(e => {
            reject(e)
          })
        }).catch(e => {
          reject(e)
        })
      })
    })
  }

  // MARK: - Error

  /**
   * Check if it's token error, try to get new token and recall request
   * @param {any} exception
   * @param {boolean} refreshToken
   */
  _interpretError (exception, refreshToken) {
    return new Promise(function (resolve, reject) {
      const error = new ApiResponseError(exception.response.status, exception.response.data)

      // Token error ?
      if (refreshToken && error.isTokenError) {
        // Try to get new token
        const reloginDataRepository = new ReloginDataRepository()
        reloginDataRepository.refreshToken.then(_ => {
          resolve()
        }).catch(e => {
          reject(e)
        })
      } else {
        reject(error)
      }
    })
  }
}
