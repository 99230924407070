export default class SessionsForWeekReservationDataModel {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {int} */
  _status
  /** @type {string|null} */
  _pattern

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._id = response.id
    this._status = response.status
    this._pattern = response.pattern
  }

  // MARK: - Getter

  /** @return {string} */
  get id () {
    return this._id
  }

  /** @type {int} */
  get status () {
    return this._status
  }

  /** @type {string|null} */
  get pattern () {
    return this._pattern
  }
}
