import ButtonViewModel from '@/presentation/view-model/button/buttonViewModel'
import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static Classes = {
    textColor: Color.black + String.textColorSuffix,
    paddingPrefix: 'px-',
    defaultPadding: 10,
    otherClasses: String.empty
  }
}

// MARK: - Class

export default class ButtonClassicViewModel extends ButtonViewModel {
  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} titleKey
   * @param {string} color
   * @param {int} padding
   * @param {string} otherClasses
   */
  constructor (localization,
    titleKey,
    color = Color.main,
    padding = Constants.Classes.defaultPadding,
    otherClasses = Constants.Classes.otherClasses) {
    const classes = String.fromArray([
      Constants.Classes.textColor,
      otherClasses,
      Constants.Classes.paddingPrefix + padding.toString()
    ])

    super(localization.getText(titleKey),
      color,
      classes
    )
  }
}
