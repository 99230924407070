export default class FlexViewModel {
  // MARK: - Properties

  /** @type {string} */
  _classes

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} classes
   */
  constructor (classes) {
    this._classes = classes
  }

  // MARK: - Getter

  /** @return {string} */
  get classes () {
    return this._classes
  }
}
