import DomainError from '@/domain/error/domainError'
import LoginDomainEntry from '@/domain/scene/login/loginDomainEntry'
import PushNotificationManager from '@/domain/manager/pushNotificationManager'

// MARK: - Constants

class Constants {
  static anchor = '#legalNotices'
}

export default class LoginInteractor {
  // MARK: - Properties

  /** @type {LoginDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {LoginPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LoginDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {LoginPresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  /** @param {string|null} anchor  */
  vueMounted (anchor) {
    // Show navigation
    this._presenter.showNavigationFromInteractor = false

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = true

    // Prepare legal notices ?
    if (anchor === Constants.anchor) {
      this._prepareLegalNotices()
    }

    // Unregister for push notification
    PushNotificationManager.shared.unregistration()
  }

  // MARK: - To Repository

  /**
   * Log client from repository
   * @param {boolean} legalNoticesReaded
   * */
  loginTappedFromController (legalNoticesReaded) {
    // Form is valid ?
    if (!this._validation.$invalid && legalNoticesReaded) {
      // Show loader
      this._presenter.showLoaderFromInteractor = true

      const self = this
      const entry = new LoginDomainEntry(this._validation)
      this._repository.login(entry.toData).then(function () {
        // Go to connected space
        self._presenter.prepareConnectedSpaceFromInteractor()

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      }).catch(e => {
        // Send error to presenter
        self._presenter.prepareErrorFromInteractor = new DomainError(e)

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      })
    } else {
      // Show error on form
      this._presenter.showFormErrorFromInteractor()
    }
  }

  // MARK: - From Controller

  legalNoticesTappedFromController () {
    this._prepareLegalNotices()
  }

  passwordForgotTappedFromController () {
    this._presenter.preparePasswordForgotFromInteractor()
  }

  closeLegalNoticesAskedFromController () {
    this._presenter.prepareCloseLegalNoticesFromInteractor()
  }

  closePasswordForgotAskedFromController () {
    this._presenter.prepareClosePasswordForgotFromInteractor()
  }

  // MARK: - Methods

  _prepareLegalNotices () {
    this._presenter.prepareLegalNoticesFromInteractor()
  }
}
