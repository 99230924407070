import Moment from 'moment'

// MARK: - Constants

class Constants {
  static ddMmYyyy = 'DD/MM/YYYY'
  static dddd = 'dddd'
  static ddddDd = 'dddd DD'
  static ddddDdMMMM = 'dddd DD MMMM'
  static hhMm = 'HH:mm'

  static dayFilterGranuliraty = 'day'
  static addDayKey = 'd'
}

const DD_MM_YYYY = Constants.ddMmYyyy
const DDDD = Constants.dddd
const DDDD_DD = Constants.ddddDd
const DDDD_DD_MMMM = Constants.ddddDdMMMM
const HH_MM = Constants.hhMm

// MARK: - Enum

export default {
  DD_MM_YYYY,
  DDDD,
  DDDD_DD,
  DDDD_DD_MMMM,
  HH_MM,

  /**
   * @param {moment.Moment} date
   * @param {string} format
   * @return {string}
   **/
  string (date, format) {
    return date.format(format)
  },

  /**
   * @param {string} string
   * @param {string} format
   * @return {moment.Moment}
   **/
  date (string, format) {
    return Moment(string, format)
  },

  /**
   * @param {string} format
   * @return {string}
   **/
  currentString (format) {
    return Moment().format(format)
  },

  /**
   * @param {string} date
   * @param {string} format
   * @return {boolean}
   **/
  isSameOrBeforeNow (date, format) {
    const value = Moment(date, format, true)
    return value.isSameOrBefore(Moment())
  },

  /**
   * @param {moment.Moment} date
   * @return {boolean}
   **/
  isSameOrAfterNow (date) {
    const currentDate = Moment()
    return date.isSameOrAfter(currentDate)
  },

  /**
   * @param {moment.Moment} date
   * @param {moment.Moment} compareDate
   * @return {boolean}
   **/
  isSameDay (date, compareDate) {
    return date.isSame(compareDate, Constants.dayFilterGranuliraty)
  },

  /**
   * @param {moment.Moment} date
   * @param {int} day
   * @return {moment.Moment}
   **/
  addDay (date, day) {
    return date.add(day, Constants.addDayKey)
  }
}
