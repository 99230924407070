import ProfileInteractor from '@/domain/scene/profile/profileInteractor'
import ProfileDataRepository from '@/data/repository/profile/profileDataRepository'

export default class ProfileDomainInjection {
  // MARK: - Properties

  /** @type {ProfileInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} validation from vuelidate
   * @param {ProfilePresenter} presenter
   */
  constructor (validation, presenter) {
    const repository = new ProfileDataRepository()
    this._interactor = new ProfileInteractor(repository, validation, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {ProfileInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
