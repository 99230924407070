import SessionsForWeekDomainInjection from '@/domain/scene/sessions-for-week/sessionsForWeekDomainInjection'
import SessionsForWeekController from '@/presentation/scene/sessions-for-week/sessionsForWeekController'
import SessionsForWeekPresenter from '@/presentation/scene/sessions-for-week/sessionsForWeekPresenter'

export default class SessionsForWeekPresentationInjection {
  // MARK: - Properties

  /** @type {SessionsForWeekController} */
  _controller
  /** @type {SessionsForWeekPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._presenter = new SessionsForWeekPresenter(vuetify)

    // Domain
    const domainInjection = new SessionsForWeekDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new SessionsForWeekController(interactor)
  }

  // MARK: - Getter

  /** @return {SessionsForWeekController} */
  get controller () {
    return this._controller
  }

  /** @return {SessionsForWeekPresenter} */
  get presenter () {
    return this._presenter
  }
}
