<template>
  <div>
    <v-navigation-drawer
      :floating="viewModel.floatingBar.floating"
      :permanent="viewModel.floatingBar.permanent"
      :fixed="viewModel.floatingBar.fixed"
      :stateless="viewModel.floatingBar.stateless"
      :height="viewModel.floatingBar.height"
      :width="viewModel.floatingBar.width"
      :color="viewModel.floatingBar.backgroundColor"
      :mini-variant="viewModel.floatingBar.miniVariant"
      :mini-variant-width="viewModel.floatingBar.miniVariantWidth"
      :class="viewModel.floatingBar.classes"
    >
      <div :class="viewModel.floatingBar.paddingClasses">
        <v-list
          :dense="viewModel.floatingBar.listDense"
          :rounded="viewModel.floatingBar.listRounded"
          :color="viewModel.floatingBar.barColor"
          :role="viewModel.floatingBar.listRole"
        >
          <v-list-item-group
            :aria-label="viewModel.floatingBar.listAriaLabel"
            :role="viewModel.floatingBar.listRole">
            <v-list-item
              v-for="route in viewModel.router"
              :key="route.path"
              :to="`${ route.path }`"
              :class="viewModel.floatingBar.getDrawerItemClasses(route, $route)"
              :color="viewModel.floatingBar.selectedItemColor"
              :aria-label="route.name"
              :role="viewModel.floatingBar.itemRole"
            >
              <v-list-item-icon>
                <v-icon>{{ route.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ route.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-bottom-navigation
      :app="viewModel.tabbar.app"
      :fixed="viewModel.tabbar.fixed"
      :grow="viewModel.tabbar.grow"
      :class="viewModel.tabbar.classes"
      :background-color="viewModel.tabbar.backgroundColor"
      :color="viewModel.tabbar.selectedColor"
    >
      <v-btn v-for="route in viewModel.router"
             :key="route.path"
             :class="viewModel.tabbar.getBottomItemButtonClasses(route, $route)"
             :to="`${ route.path }`"
             :aria-label="route.name">
        <v-icon>{{ route.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>

</template>

<script>
import NavigationViewModel from '@/presentation/view-model/navigation/navigationViewModel'

export default {
  name: 'Navigation',

  data: () => ({
    /** @type NavigationViewModel */
    viewModel: null
  }),

  beforeMount () {
    this.viewModel = new NavigationViewModel(this.$vuetify)
  }
}
</script>

<style lang="sass" scoped>
  @import 'navigation-style'
</style>
