export default class ContainerViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _fluid
  /** @type {string} */
  _classes
  /** @type {boolean} */
  _fillHeight

  // MARK: - Constructor

  /**
   * @constructor
   * @param {boolean} fluid
   * @param {string} classes
   * @param {boolean} fillHeight
   */
  constructor (fluid,
    classes,
    fillHeight) {
    this._fluid = fluid
    this._classes = classes
    this._fillHeight = fillHeight
  }

  // MARK: - Getter

  /** @return {boolean} */
  get fluid () {
    return this._fluid
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {boolean} */
  get fillHeight () {
    return this._fillHeight
  }
}
