// MARK: - Constants

class Constants {
  static sessionIdKey = 'sessionId'
  static statusKey = 'status'
  static patternKey = 'pattern'
}

// MARK: - Class

export default class ReservationAPIBody {
  // MARK: - Properties

  _body = {}

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} sessionId
   * @param {string} status
   * @param {string|null} pattern
   */
  constructor (sessionId, status, pattern) {
    this._body[Constants.sessionIdKey] = sessionId
    this._body[Constants.statusKey] = status
    this._body[Constants.patternKey] = pattern || null
  }

  // MARK: - Getter

  get body () {
    return this._body
  }
}
