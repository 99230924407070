import NotFoundViewModel from '@/presentation/scene/not-found/view-model/notFoundViewModel'
import Route from '@/app/utils/route'
import ContentPresenter from '@/presentation/utils/contentPresenter'

export default class NotFoundPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {NotFoundViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {VueRouter} router
   */
  constructor (vuetify, router) {
    super()
    this._viewModel = new NotFoundViewModel(vuetify)
    this._router = router
  }

  // MARK: - Getter

  /** @return {NotFoundViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  prepareConnectedSpaceFromInteractor () {
    this._router.push(Route.dashboardVue)
  }

  prepareLoginFromInteractor () {
    this._router.push(Route.loginVue)
  }
}
