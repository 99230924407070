import PresentationInformation from '@/presentation/common/information/presentationInformation'
import PresentationSuccessType from '@/presentation/common/enum/presentationSuccess'

// MARK: - Constants

class Constants {
  static titleKey = 'success_title'
}

// MARK: - Class

export default class PresentationSuccess {
  // MARK: - Properties

  /** @type {string} */
  _title
  /** @type {string} */
  _message
  /** @type {boolean} */
  _redirectToLogin

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {int} type corresponding to PresentationSuccess
   * @param {boolean} redirectToLogin redirect to login after close ?
   */
  constructor (localization, type, redirectToLogin) {
    this._title = localization.getText(Constants.titleKey)
    this._message = localization.getText(PresentationSuccessType.key(type))
    this._redirectToLogin = redirectToLogin
  }

  // MARK: - Getter

  /** @return {PresentationInformation} */
  get toInformation () {
    return new PresentationInformation(this._title, this._message, this._redirectToLogin)
  }
}
