import Moment from 'moment'

export default class SessionDate {
  // MARK: - Getter

  /**
   * Check if reservation limit for session isn't passed
   * @param {SessionsForWeekDomainModel} session
   * @return {boolean}
   */
  static stillTimeToReserve (session) {
    return Moment().isBefore(session.reservationLimitAt)
  }

  /**
   * Check if start at for session isn't passed
   * @param {SessionsForWeekDomainModel} session
   * @return {boolean}
   */
  static stillTimeToCancel (session) {
    return Moment().isBefore(session.startAt)
  }
}
