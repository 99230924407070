// MARK: - Constants

class Constants {
  static italic = ' font-italic '
  static regular = ' font-weight-regular '
  static medium = ' font-weight-medium '
  static bold = ' font-weight-bold '
}

// MARK: - Class

export default class FontWeight {
  // MARK: - Properties

  static italic = Constants.italic
  static regular = Constants.regular
  static medium = Constants.medium
  static bold = Constants.bold
}
