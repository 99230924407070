import PasswordInitViewModel from '@/presentation/scene/password-init/view-model/passwordInitViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import Route from '@/app/utils/route'
import PresentationSuccess from '@/presentation/common/success/presentationSuccess'
import PresentationSuccessType from '@/presentation/common/enum/presentationSuccess'
import ContentPresenter from '@/presentation/utils/contentPresenter'

// MARK: - Class

export default class PasswordInitPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation
  /** @type {PasswordInitViewModel} */
  _viewModel
  /** @type {VueRouter} */
  _router

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    super()
    this._localization = new Localization(vuetify)
    this._validation = validation
    this._viewModel = new PasswordInitViewModel(vuetify, this._localization, validation)
    this._router = router
  }

  // MARK: - Getter

  /** @return {PasswordInitViewModel} */
  get viewModel () {
    return this._viewModel
  }

  /** @return {array} */
  static get textFieldsValidations () {
    return PasswordInitViewModel.textFieldsValidations
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  showTextFieldsErrorFromInteractor () {
    this._validation.email.$touch()
    this._validation.code.$touch()
    this._validation.newPassword.$touch()
    this._validation.confirmPassword.$touch()
  }

  prepareLoginFromInteractor () {
    this._router.push(Route.loginVue)
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.information = presentationError.toInformation
  }

  /** @param {boolean} redirectToLogin */
  prepareSuccessFromInteractor (redirectToLogin) {
    const presentationSuccess = new PresentationSuccess(this._localization, PresentationSuccessType.PASSWORD_INIT, redirectToLogin)
    this._viewModel.information = presentationSuccess.toInformation
  }
}
