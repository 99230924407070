<template>
  <div v-if="viewModel.showMain">
    <v-dialog
      v-model="viewModel.showContent"
      :max-width="viewModel.maxWidth"
      :persistent="viewModel.persistent"
    >
      <!-- Slot needed to put content here -->
      <slot></slot>
    </v-dialog>
  </div>
</template>

<script>
import DialogViewModel from '@/presentation/view-model/dialog/dialogViewModel'

export default {
  name: 'DialogComponent',

  props: {
    viewModel: {
      type: DialogViewModel,
      required: true
    }
  }
}
</script>
