import DashboardReservationDomainModel from '@/domain/scene/dashboard/model/dashboardReservationDomainModel'
import DashboardSubscriptionDomainModel from '@/domain/scene/dashboard/model/dashboardSubscriptionDomainModel'
import DashboardInformationDomainModel from '@/domain/scene/dashboard/model/dashboardInformationDomainModel'

export default class DashboardDomainModel {
  // MARK: - Properties

  /** @type {boolean} */
  _askPushRegistration
  /** @type {int} */
  _reservations
  /** @type {int} */
  _participatingSession
  /** @type {int} */
  _canceledReservations
  /** @type {int} */
  _monthReservations
  /** @type {int} */
  _yearReservations
  /** @type {DashboardReservationDomainModel|null} */
  _nextReservation
  /** @type {moment.Moment} */
  _startDateFirstSubscription
  /** @type {DashboardSubscriptionDomainModel|null} */
  _validSubscriptionEnding
  /** @type {boolean} */
  _subscriptionExpiresSoon
  /** @type {DashboardInformationDomainModel[]|null} */
  _informations

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardDataModel} dataModel
   * @param {boolean} askPushRegistration
   */
  constructor (dataModel, askPushRegistration) {
    this._askPushRegistration = askPushRegistration
    this._reservations = dataModel.reservations
    this._participatingSession = dataModel.participatingSession
    this._canceledReservations = dataModel.canceledReservations
    this._monthReservations = dataModel.monthReservations
    this._yearReservations = dataModel.yearReservations
    if (dataModel.nextReservation) {
      this._nextReservation = new DashboardReservationDomainModel(dataModel.nextReservation)
    }
    this._startDateFirstSubscription = dataModel.startDateFirstSubscription
    if (dataModel.validSubscriptionEnding) {
      this._validSubscriptionEnding = new DashboardSubscriptionDomainModel(dataModel.validSubscriptionEnding)
    }
    this._subscriptionExpiresSoon = dataModel.subscriptionExpiresSoon
    if (dataModel.informations) {
      this._informations = dataModel.informations.map(e => {
        return new DashboardInformationDomainModel(e)
      })
    }
  }

  // MARK: - Getter

  /** @return {boolean} */
  get askPushRegistration () {
    return this._askPushRegistration
  }

  /** @return {int} */
  get reservations () {
    return this._reservations
  }

  /** @return {int} */
  get participatingSession () {
    return this._participatingSession
  }

  /** @return {int} */
  get canceledReservations () {
    return this._canceledReservations
  }

  /** @return {int} */
  get monthReservations () {
    return this._monthReservations
  }

  /** @return {int} */
  get yearReservations () {
    return this._yearReservations
  }

  /** @return {DashboardReservationDomainModel|null} */
  get nextReservation () {
    return this._nextReservation
  }

  /** @return {moment.Moment} */
  get startDateFirstSubscription () {
    return this._startDateFirstSubscription
  }

  /** @return {DashboardSubscriptionDomainModel|null} */
  get validSubscriptionEnding () {
    return this._validSubscriptionEnding
  }

  /** @return {boolean} */
  get subscriptionExpiresSoon () {
    return this._subscriptionExpiresSoon
  }

  /** @return {DashboardInformationDomainModel[]|null} */
  get informations () {
    return this._informations
  }
}
