export default class AnimationViewModel {
  // MARK: - Properties

  /** @type {string} */
  _path
  /** @type {boolean} */
  _loop
  /** @type {boolean} */
  _play
  /** @type {int} */
  _speed
  /** @type {int|null} */
  _size

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} path
   * @param {boolean} loop
   * @param {boolean} play
   * @param {int} speed
   * @param {int|null} size
   */
  constructor (path, loop, play, speed, size) {
    this._path = path
    this._loop = loop
    this._play = play
    this._speed = speed
    this._size = size
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set play (value) {
    this._play = value
  }

  // MARK: - Getter

  /** @return {string} */
  get path () {
    return this._path
  }

  /** @return {boolean} */
  get loop () {
    return this._loop
  }

  /** @return {boolean} */
  get play () {
    return this._play
  }

  /** @return {int} */
  get speed () {
    return this._speed
  }

  /** @return {int|null} */
  get size () {
    return this._size
  }
}
