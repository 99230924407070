<template>
  <div v-if="viewModel.showMain">
    <custom-structure
      :view-model="viewModel.structure"
      :show="viewModel.showContent">

        <!-- Lottie animation -->
        <custom-animation
          :path="viewModel.animation.path"
          :loop="viewModel.animation.loop"
          :autoPlay="viewModel.animation.play"
          :speed="viewModel.animation.speed"
          :width="viewModel.animation.size"
          :height="viewModel.animation.size"
        />

    </custom-structure>
  </div>
</template>

<script>
import SheetStructureComponent from '@/app/common/component/sheet/SheetStructureComponent'
import LoaderViewModel from '@/presentation/view-model/loader/loaderViewModel'
import Animation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
  name: 'LoaderComponent',

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  components: {
    customStructure: SheetStructureComponent,
    customAnimation: Animation
  },

  watch: {
    show () {
      this.viewModel.show = this.show
    }
  },

  data: () => ({
    /** @type LoaderViewModel */
    viewModel: null,
    sheet: false
  }),

  beforeMount () {
    this.viewModel = new LoaderViewModel(this.$vuetify)
    this.sheet = this.viewModel.sheet
  }
}
</script>
