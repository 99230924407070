import FormViewModel from '@/presentation/view-model/form/formViewModel'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static outlined = true
  static placeholder = String.space
}

// MARK: - Class

export default class InputViewModel extends FormViewModel {
  // MARK: - Properties

  /** @type {string} */
  _type
  /** @type {string} */
  _placeholder
  /** @type {boolean} */
  _outlined

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} label
   * @param {string} type
   * @param {string} placeholder
   * @param {string} color
   * @param {boolean} outlined
   * @param {boolean} showLoader
   */
  constructor (label,
    type,
    placeholder = Constants.placeholder,
    color = undefined,
    showLoader = undefined,
    outlined = Constants.outlined) {
    super(label, color, showLoader)
    this._type = type
    this._placeholder = placeholder
    this._outlined = outlined
  }

  // MARK: - Getter

  /** @return {string} */
  get type () {
    return this._type
  }

  /** @return {string} */
  get placeholder () {
    return this._placeholder
  }

  /** @return {boolean} */
  get outlined () {
    return this._outlined
  }

  // MARK: - Setter

  /** @param {string} value */
  set type (value) {
    this._type = value
  }

  /** @param {string} value */
  set placeholder (value) {
    this._placeholder = value
  }

  /** @param {boolean} value */
  set outlined (value) {
    this._outlined = value
  }
}
