import DomainError from '@/domain/error/domainError'
import PasswordUpdateDomainEntry from '@/domain/scene/password-update/passwordUpdateDomainEntry'

export default class PasswordUpdateInteractor {
  // MARK: - Properties

  /** @type {PasswordDataRepository} */
  _repository
  /** @type {any} */
  _validation
  /** @type {PasswordUpdatePresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {PasswordDataRepository} repository
   * @param {any} validation from vuelidate
   * @param {PasswordUpdatePresenter} presenter
   */
  constructor (repository, validation, presenter) {
    this._repository = repository
    this._validation = validation
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = true

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false
  }

  // MARK: - From Controller

  // Update client password from repository
  saveTappedFromController () {
    // Form is valid ?
    if (!this._validation.$invalid) {
      // Show loader
      this._presenter.showLoaderFromInteractor = true

      const self = this
      const entry = new PasswordUpdateDomainEntry(this._validation)
      this._repository.update(entry.toData).then(function () {
        // Go to profile
        self._presenter.prepareProfileFromInteractorFromInteractor()

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      }).catch(e => {
        // Send error to presenter
        self._presenter.prepareErrorFromInteractor = new DomainError(e)

        // Hide loader
        self._presenter.showLoaderFromInteractor = false
      })
    } else {
      // Show error on all invalids text fields
      this._presenter.showTextFieldsErrorFromInteractor()
    }
  }

  backTappedFromController () {
    this._presenter.prepareProfileFromInteractorFromInteractor()
  }
}
