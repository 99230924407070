import { Bus, Events } from '@/presentation/main'

export default class SessionsForWeekController {
  // MARK: - Properties

  /** @type {SessionsForWeekInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SessionsForWeekInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  vueDestroyed () {
    this._clearBus()
  }

  // MARK: - Methods

  /**
   * @param {string} key
   */
  selectSession (key) {
    this._interactor.selectSessionFromController(key)
  }

  refreshTapped () {
    this._interactor.refreshTappedFromController()
  }

  // MARK: - Bus

  _loadBus () {
    // Check when navigation status changed
    const self = this
    Bus.$on(Events.closeReservationAsked, (value) => {
      self._interactor.closeReservationAskedFromController(value)
    })

    Bus.$on(Events.sessionsForWeekChanged, _ => {
      self._interactor.sessionsForWeekChangedFromController()
    })
  }

  _clearBus () {
    Bus.$off(Events.closeReservationAsked)
    Bus.$off(Events.sessionsForWeekChanged)
  }
}
