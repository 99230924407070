<template>
  <div>
    <!-- Switch component -->
    <v-switch
      v-model="viewModel.value"
      v-if="viewModel.showForm"
      :class="viewModel.classes"
      :label="viewModel.label"
      :messages="viewModel.message"
      :color="viewModel.color"
      :inset="viewModel.inset"
    />

    <!-- Loader component -->
    <custom-skeleton
      v-if="viewModel.showLoader"
      :viewModel="viewModel.loader"
    />
  </div>
</template>

<script>
import SkeletonComponent from '@/app/common/component/skeleton/SkeletonComponent'
import SwitchViewModel from '@/presentation/view-model/switch/switchViewModel'

export default {
  name: 'SwitchComponent',

  props: {
    viewModel: {
      type: SwitchViewModel,
      required: true
    }
  },

  components: {
    customSkeleton: SkeletonComponent
  }
}
</script>
