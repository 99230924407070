import InputViewModel from '@/presentation/view-model/input/inputViewModel'

// MARK: - Constants

class Constants {
  static type = 'text'
  static autocomplete = 'off'
  static clearable = false
}

// MARK: - Class

export default class TextFieldViewModel extends InputViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _required
  /** @type {string} */
  _autocomplete
  /** @type {boolean} */
  _clearable

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} label
   * @param {boolean} required
   * @param {string} type
   * @param {string} autocomplete
   * @param {string} placeholder
   * @param {string} color
   * @param {boolean} clearable
   * @param {boolean} showLoader
   */
  constructor (label, required,
    type = Constants.type, autocomplete = Constants.autocomplete,
    placeholder = undefined,
    color = undefined,
    clearable = Constants.clearable,
    showLoader = undefined) {
    super(label, type, placeholder, color, showLoader)
    this._required = required
    this._autocomplete = autocomplete
    this._clearable = clearable
  }

  // MARK: - Getter

  /** @return {boolean} */
  get required () {
    return this._required
  }

  /** @return {string} */
  get autocomplete () {
    return this._autocomplete
  }

  /** @return {boolean} */
  get clearable () {
    return this._clearable
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set required (value) {
    this._required = value
  }

  /** @param {string} value */
  set autocomplete (value) {
    this._autocomplete = value
  }

  /** @param {boolean} value */
  set clearable (value) {
    this._clearable = value
  }
}
