<template>
  <div>
    <custom-container-layout :view-model="presenter.viewModel.container">

      <!-- Logo -->
      <v-icon
        :size="presenter.viewModel.icon.size">
        {{ presenter.viewModel.icon.name }}
      </v-icon>

      <!-- Icon bottom space -->
      <custom-space :view-model="presenter.viewModel.iconBottomSpace"/>

      <!-- Form -->
      <form>
        <!-- Email text field -->
        <custom-text-field
          :view-model="presenter.viewModel.emailTextField"
          :vuelidate="$v.email"
        />

        <!-- Email space -->
        <custom-space :view-model="presenter.viewModel.emailBottomSpace"/>

        <!-- Password text field -->
        <custom-text-field
          :view-model="presenter.viewModel.passwordTextField"
          :vuelidate="$v.password"
        />

        <!-- Password space -->
        <custom-space :view-model="presenter.viewModel.passwordBottomSpace"/>

        <!-- Legal notice and cookies checkbox -->
        <custom-legal-notices-checkbox
          :view-model="presenter.viewModel.legalNoticesCheckbox"
          @click="controller.legalNoticesTapped()"
        />

        <!-- Legal notice and cookies space -->
        <custom-space :view-model="presenter.viewModel.legalNoticesBottomSpace"/>

        <!-- Connexion button -->
        <custom-button
          :view-model="presenter.viewModel.connexionButton"
          @click="loginTapped()"
        />
      </form>

      <!-- Connexion space -->
      <custom-space :view-model="presenter.viewModel.connexionBottomSpace"/>

      <!-- Forgot password button -->
      <custom-button
        :view-model="presenter.viewModel.forgotPasswordButton"
        @click="controller.passwordForgotTapped()"
      />

    </custom-container-layout>

    <!-- Loader -->
    <custom-loader :show="presenter.viewModel.showLoader"/>

    <!-- Alert -->
    <custom-alert :information="presenter.viewModel.error"/>

    <!-- Legal notices Vue -->
    <custom-legal-notices :show="presenter.viewModel.showLegalNotices"/>

    <!-- Password Forgot -->
    <custom-password-forgot :show="presenter.viewModel.showPasswordForgot"/>

  </div>
</template>

<script>
import ContainerLayoutFlexComponent from '@/app/common/component/container-layout-flex/ContainerLayoutFlexComponent'
import TextFieldComponent from '@/app/common/component/textfield/TextFieldComponent'
import LegalNoticesCheckboxComponent from '@/app/scene/login/component/LoginLegalNoticesCheckboxComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import LoaderComponent from '@/app/common/component/loader/LoaderComponent'
import AlertComponent from '@/app/common/component/alert/AlertComponent'
import LegalNoticesVue from '@/app/scene/legal-notices/LegalNoticesVue'
import PasswordForgotVue from '@/app/scene/password-forgot/PasswordForgotVue'
import PresentationInjection from '@/presentation/scene/login/loginPresentationInjection'
import Presenter from '@/presentation/scene/login/loginPresenter'

export default {
  name: 'LoginVue',

  data: () => ({
    /** @type LoginController */
    controller: null,
    /** @type LoginPresenter */
    presenter: null,

    // TextFields (NOTE: Same names are used on TextFieldViewModel,Presenter and Interactor)
    email: null,
    password: null
  }),

  components: {
    customContainerLayout: ContainerLayoutFlexComponent,
    customTextField: TextFieldComponent,
    customLegalNoticesCheckbox: LegalNoticesCheckboxComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent,
    customLoader: LoaderComponent,
    customAlert: AlertComponent,
    customLegalNotices: LegalNoticesVue,
    customPasswordForgot: PasswordForgotVue
  },

  validations: Presenter.textFieldsValidations,

  methods: {
    loginTapped () {
      const legalNoticesReaded = this.presenter.viewModel.legalNoticesCheckbox.value
      this.controller.loginTapped(legalNoticesReaded)
    }
  },

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify, this.$v, this.$router)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  mounted () {
    this.controller.vueMounted(this.$route.hash)
  },

  destroyed () {
    this.controller.vueDestroyed()

    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>
