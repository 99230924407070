import SessionsForWeekVue from '@/app/scene/sessions-for-week/SessionsForWeekVue'
import Reservations from '@/app/scene/reservations/ReservationsVue'
import Dashboard from '@/app/scene/dashboard/DashboardVue'
import Subscriptions from '@/app/scene/subscriptions/SubscriptionsVue'
import Profile from '@/app/scene/profile/ProfileVue'
import Login from '@/app/scene/login/LoginVue'
import NotFound from '@/app/scene/not-found/NotFoundVue'
import PasswordInit from '@/app/scene/password-init/PasswordInitVue'
import PasswordUpdate from '@/app/scene/password-update/PasswordUpdateVue'
import { RoutePresentation, Constants } from '@/presentation/router/routePresentation'

// MARK: - Class

export default class Route {
  // MARK: - Properties

  /** @type {RoutePresentation} */
  _routePresentation
  /** @type {string} */
  _path
  /** @type {string} */
  _name
  /** @type {any} */
  _component = null
  /** @type {boolean} */
  _needAuth = false

  // MARK: - Constructor

  constructor (path, name, component = null, needAuth = false, vuetify = null) {
    this._routePresentation = new RoutePresentation(vuetify)
    this._path = path
    this._name = name
    this._component = component
    this._needAuth = needAuth
  }

  // MARK: - Getter

  /** @return {string} */
  get path () {
    return this._path
  }

  /** @return {string} */
  get name () {
    return this._routePresentation.getText(this._name)
  }

  /** @return {any} */
  get component () {
    return this._component
  }

  /** @return {string} */
  get icon () {
    return this._routePresentation.getIcon(this._name)
  }

  beforeEnter (to, from, next) {
    const goToConnectedVue = Route._getConnectedPaths.includes(to.path)

    // App contains token ?
    const routePresentation = new RoutePresentation()

    // If user is connected, go to selected page
    if (goToConnectedVue) {
      // App contains token ?
      if (routePresentation.containsToken) {
        return next()
      } else { // Either, go to login
        return next(Constants.loginPath)
      }
    } else {
      // App contains token ? Can't go on unconnected pages, redirect to dashboard
      const goToUnconnectedVue = Route._getUnconnectedPaths.includes(to.path)
      if (routePresentation.containsToken && goToUnconnectedVue) {
        return next(Constants.dashboardPath)
      } else { // Either, go on guest vue
        return next()
      }
    }
  }

  // MARK: - Static Getter

  /** @return {string} */
  static get loginVue () {
    return Constants.loginVue
  }

  /** @return {string} */
  static get dashboardVue () {
    return Constants.dashboardVue
  }

  /** @return {string} */
  static get sessionsPath () {
    return Constants.sessionsPath
  }

  /** @return {string} */
  static get passwordUpdatePath () {
    return Constants.passwordUpdatePath
  }

  /** @return {string} */
  static get profilePath () {
    return Constants.profilePath
  }

  /** @return {Route[]} */
  static get allForRouter () {
    return [
      // Guest vues
      new Route(Constants.notFoundPath, Constants.notFoundName, NotFound),
      new Route(Constants.loginPath, Constants.loginName, Login),
      new Route(Constants.passwordInitPath, Constants.passwordInitName, PasswordInit),

      // Connected vues
      new Route(Constants.sessionsPath, Constants.sessionsName, SessionsForWeekVue, true),
      new Route(Constants.reservationsPath, Constants.reservationsName, Reservations, true),
      new Route(Constants.dashboardPath, Constants.dashboardName, Dashboard, true),
      new Route(Constants.subscriptionsPath, Constants.subscriptionsName, Subscriptions, true),
      new Route(Constants.profilePath, Constants.profileName, Profile, true),
      new Route(Constants.passwordUpdatePath, Constants.passwordUpdateName, PasswordUpdate, true),

      // Redirection
      {
        path: Constants.defaultPath,
        redirect: {
          path: Constants.dashboardPath
        }
      }
    ]
  }

  /** @return {Route[]} */
  static getAllForNavigation (vuetify) {
    return [
      new Route(Constants.sessionsPath, Constants.sessionsName, null, false, vuetify),
      new Route(Constants.reservationsPath, Constants.reservationsName, null, false, vuetify),
      new Route(Constants.dashboardPath, Constants.dashboardName, null, false, vuetify),
      new Route(Constants.subscriptionsPath, Constants.subscriptionsName, null, false, vuetify),
      new Route(Constants.profilePath, Constants.profileName, null, false, vuetify)
    ]
  }

  // MARK: - Private Static Getter

  /** @return {string[]} */
  static get _getConnectedPaths () {
    return [
      Constants.sessionsPath,
      Constants.reservationsPath,
      Constants.dashboardPath,
      Constants.subscriptionsPath,
      Constants.profilePath,
      Constants.passwordUpdatePath
    ]
  }

  /** @return {string[]} */
  static get _getUnconnectedPaths () {
    return [
      Constants.loginPath,
      Constants.passwordInitPath
    ]
  }
}
