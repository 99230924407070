import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static Path = {
    getAllForWeek: 'sessions/week'
  }
}

// MARK: - Class

export default class SessionAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _worker

  // MARK: - Constructor

  constructor () {
    const configuration = new ApiConfiguration(true)
    this._worker = new ApiWorker(configuration)
  }

  // MARK: - Get

  /**
   * Call API to get sessions of the week
   * @param {int} page of the request
   * @return {Promise}
   */
  getAllForWeek (page) {
    return this._worker.getRequest(Constants.Path.getAllForWeek, page)
  }
}
