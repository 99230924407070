import ButtonClassicResizeViewModel from '@/presentation/view-model/button/buttonClassicResizeViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import TextFieldPasswordViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordViewModel'
import TextFieldPasswordConfirmViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordConfirmViewModel'
import TextFieldPasswordNewViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordNewViewModel'
import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'

// MARK: - Constants

class Constants {
  static TextKeys = {
    title: 'password',
    currentPasswordLabel: 'current_password'
  }

  static Buttons = {
    saveTitleKey: 'save',
    backTitleKey: 'back'
  }
}

// MARK: - Class

export default class PasswordUpdateViewModel {
  // MARK: - Properties

  /** @type {any} */
  _validation
  /** @type {string} */
  _title
  /** @type {SpaceViewModel} */
  _titleBottomSpace
  /** @type {TextFieldPasswordViewModel} */
  _currentPasswordTextField
  /** @type {SpaceViewModel} */
  _currentPasswordBottomSpace
  /** @type {TextFieldPasswordNewViewModel} */
  _newPasswordTextField
  /** @type {SpaceViewModel} */
  _newPasswordBottomSpace
  /** @type {TextFieldPasswordConfirmViewModel} */
  _confirmPasswordTextField
  /** @type {SpaceViewModel} */
  _confirmPasswordBottomSpace
  /** @type {ButtonClassicResizeViewModel} */
  _saveButton
  /** @type {SpaceViewModel} */
  _saveBottomSpace
  /** @type {ButtonSmallViewModel} */
  _backButton
  /** @type {boolean} */
  _showLoader
  /** @type {PresentationInformation|null} */
  _error

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (vuetify, localization, validation) {
    this._validation = validation

    this._title = localization.getText(Constants.TextKeys.title)

    this._currentPasswordTextField = new TextFieldPasswordViewModel(localization, validation, Constants.TextKeys.currentPasswordLabel)
    this._currentPasswordBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._newPasswordTextField = new TextFieldPasswordNewViewModel(localization, validation)
    this._newPasswordBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._confirmPasswordTextField = new TextFieldPasswordConfirmViewModel(localization, validation)
    this._confirmPasswordBottomSpace = new SpaceViewModel(PresentationSpace.BIG)
    this._saveButton = new ButtonClassicResizeViewModel(vuetify, localization, Constants.Buttons.saveTitleKey)
    this._saveBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._backButton = new ButtonSmallViewModel(localization, Constants.Buttons.backTitleKey)
    this._showLoader = false
    this._error = null
  }

  // MARK: - Getter

  /** @return {array} */
  static get textFieldsValidations () {
    return Object.assign(
      TextFieldPasswordViewModel.validation,
      TextFieldPasswordNewViewModel.validation,
      TextFieldPasswordConfirmViewModel.validation
    )
  }

  /** @type {string} */
  get title () {
    return this._title
  }

  /** @type {TextFieldPasswordViewModel} */
  get currentPasswordTextField () {
    return this._currentPasswordTextField
  }

  /** @type {SpaceViewModel} */
  get currentPasswordBottomSpace () {
    return this._currentPasswordBottomSpace
  }

  /** @type {TextFieldPasswordNewViewModel} */
  get newPasswordTextField () {
    return this._newPasswordTextField
  }

  /** @type {SpaceViewModel} */
  get newPasswordBottomSpace () {
    return this._newPasswordBottomSpace
  }

  /** @type {TextFieldPasswordConfirmViewModel} */
  get confirmPasswordTextField () {
    return this._confirmPasswordTextField
  }

  /** @type {SpaceViewModel} */
  get confirmPasswordBottomSpace () {
    return this._confirmPasswordBottomSpace
  }

  /** @type {ButtonClassicResizeViewModel} */
  get saveButton () {
    return this._saveButton
  }

  /** @type {SpaceViewModel} */
  get saveBottomSpace () {
    return this._saveBottomSpace
  }

  /** @type {ButtonSmallViewModel} */
  get backButton () {
    return this._backButton
  }

  /** @return {boolean} */
  get showLoader () {
    return this._showLoader
  }

  /** @return {PresentationInformation|null} */
  get error () {
    return this._error
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showLoader (value) {
    this._showLoader = value
  }

  /** @param {PresentationInformation} value */
  set error (value) {
    this._error = value
  }
}
