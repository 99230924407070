import TextFieldEmailViewModel from '@/presentation/view-model/textfield/textFieldEmailViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import ProfileSubscribeSwitchViewModel
  from '@/presentation/scene/profile/view-model/profileSubscribeSwitchViewModel'
import ProfileTextTextFieldViewModel
  from '@/presentation/scene/profile/view-model/profileTextTextFieldViewModel'
import ProfilePhoneTextFieldViewModel
  from '@/presentation/scene/profile/view-model/profilePhoneTextFieldViewModel'
import ProfileBirthdayTextFieldViewModel
  from '@/presentation/scene/profile/view-model/profileBirthdayTextFieldViewModel'
import ProfileTextTextFieldType from '@/presentation/scene/profile/enum/profileTextTextFieldType'
import ButtonClassicResizeViewModel from '@/presentation/view-model/button/buttonClassicResizeViewModel'
import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'
import Color from '@/presentation/utils/color'
import SceneRefreshViewModel from '@/presentation/view-model/scene/sceneRefreshViewModel'

// MARK: - Constants

class Constants {
  static TextKeys = {
    title: 'my_account'
  }

  static Buttons = {
    saveTitleKey: 'save',
    passwordUpdateTitleKey: 'password',
    legalNoticesTitleKey: 'legal_notices',
    logoutTitleKey: 'logout'
  }
}

// MARK: - Class

export default class ProfileViewModel extends SceneRefreshViewModel {
  // MARK: - Properties

  /** @type {any} */
  _validation
  /** @type {string} */
  _title
  /** @type {ProfileTextTextFieldViewModel} */
  _nameTextField
  /** @type {SpaceViewModel} */
  _nameBottomSpace
  /** @type {ProfileTextTextFieldViewModel} */
  _lastnameTextField
  /** @type {SpaceViewModel} */
  _lastnameBottomSpace
  /** @type {TextFieldEmailViewModel} */
  _emailTextField
  /** @type {SpaceViewModel} */
  _emailBottomSpace
  /** @type {ProfileBirthdayTextFieldViewModel} */
  _birthdayTextField
  /** @type {SpaceViewModel} */
  _birthdayBottomSpace
  /** @type {ProfilePhoneTextFieldViewModel} */
  _phoneTextField
  /** @type {SpaceViewModel} */
  _phoneBottomSpace
  /** @type {ProfileSubscribeSwitchViewModel} */
  _subscribeSwitch
  /** @type {SpaceViewModel} */
  _subscribeBottomSpace
  /** @type {ButtonClassicResizeViewModel} */
  _saveButton
  /** @type {SpaceViewModel} */
  _saveBottomSpace
  /** @type {ButtonClassicResizeViewModel} */
  _passwordUpdateButton
  /** @type {SpaceViewModel} */
  _passwordUpdateBottomSpace
  /** @type {ButtonClassicResizeViewModel} */
  _logoutButton
  /** @type {SpaceViewModel} */
  _logoutBottomSpace
  /** @type {ButtonSmallViewModel} */
  _legalNoticesButton
  /** @type {SpaceViewModel} */
  _legalNoticesBottomSpace
  /** @type {boolean} */
  _showUpdateLoader
  /** @type {boolean} */
  _showLogoutConfirmation
  /** @type {boolean} */
  _showLegalNotices
  /** @type {PresentationInformation|null} */
  _error

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (vuetify, localization, validation) {
    super(localization)

    this._validation = validation

    this._title = localization.getText(Constants.TextKeys.title)

    this._nameTextField = new ProfileTextTextFieldViewModel(localization, validation, ProfileTextTextFieldType.NAME)
    this._nameBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._lastnameTextField = new ProfileTextTextFieldViewModel(localization, validation, ProfileTextTextFieldType.LASTNAME)
    this._lastnameBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._emailTextField = new TextFieldEmailViewModel(localization, validation)
    this._emailBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._birthdayTextField = new ProfileBirthdayTextFieldViewModel(localization, validation)
    this._birthdayBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._phoneTextField = new ProfilePhoneTextFieldViewModel(localization, validation)
    this._phoneBottomSpace = new SpaceViewModel(PresentationSpace.TINY)
    this._subscribeSwitch = new ProfileSubscribeSwitchViewModel(localization)
    this._subscribeBottomSpace = new SpaceViewModel(PresentationSpace.BIG)

    this._saveButton = new ButtonClassicResizeViewModel(vuetify, localization, Constants.Buttons.saveTitleKey)
    this._saveBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._passwordUpdateButton = new ButtonClassicResizeViewModel(vuetify, localization, Constants.Buttons.passwordUpdateTitleKey, Color.brownishGrey)
    this._passwordUpdateBottomSpace = new SpaceViewModel(PresentationSpace.LITTLE)
    this._logoutButton = new ButtonClassicResizeViewModel(vuetify, localization, Constants.Buttons.logoutTitleKey, Color.red)
    this._logoutBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._legalNoticesButton = new ButtonSmallViewModel(localization, Constants.Buttons.legalNoticesTitleKey)
    this._legalNoticesBottomSpace = new SpaceViewModel(PresentationSpace.BIG)

    this._showUpdateLoader = false
    this._showLogoutConfirmation = false
    this._showLegalNotices = false

    this._error = null
  }

  // MARK: - Getter

  /** @return {array} */
  static get textFieldsValidations () {
    return Object.assign(
      ProfileTextTextFieldViewModel.validation(ProfileTextTextFieldType.NAME),
      ProfileTextTextFieldViewModel.validation(ProfileTextTextFieldType.LASTNAME),
      TextFieldEmailViewModel.validation,
      ProfileBirthdayTextFieldViewModel.validation,
      ProfilePhoneTextFieldViewModel.validation
    )
  }

  /** @type {string} */
  get title () {
    return this._title
  }

  /** @type {ProfileTextTextFieldViewModel} */
  get nameTextField () {
    return this._nameTextField
  }

  /** @type {SpaceViewModel} */
  get nameBottomSpace () {
    return this._nameBottomSpace
  }

  /** @type {ProfileTextTextFieldViewModel} */
  get lastnameTextField () {
    return this._lastnameTextField
  }

  /** @type {SpaceViewModel} */
  get lastnameBottomSpace () {
    return this._lastnameBottomSpace
  }

  /** @type {TextFieldEmailViewModel} */
  get emailTextField () {
    return this._emailTextField
  }

  /** @type {SpaceViewModel} */
  get emailBottomSpace () {
    return this._emailBottomSpace
  }

  /** @type {ProfileBirthdayTextFieldViewModel} */
  get birthdayTextField () {
    return this._birthdayTextField
  }

  /** @type {SpaceViewModel} */
  get birthdayBottomSpace () {
    return this._birthdayBottomSpace
  }

  /** @type {ProfilePhoneTextFieldViewModel} */
  get phoneTextField () {
    return this._phoneTextField
  }

  /** @type {SpaceViewModel} */
  get phoneBottomSpace () {
    return this._phoneBottomSpace
  }

  /** @type {ProfileSubscribeSwitchViewModel} */
  get subscribeSwitch () {
    return this._subscribeSwitch
  }

  /** @type {SpaceViewModel} */
  get subscribeBottomSpace () {
    return this._subscribeBottomSpace
  }

  /** @type {ButtonClassicResizeViewModel} */
  get saveButton () {
    return this._saveButton
  }

  /** @type {SpaceViewModel} */
  get saveBottomSpace () {
    return this._saveBottomSpace
  }

  /** @type {ButtonClassicResizeViewModel} */
  get passwordUpdateButton () {
    return this._passwordUpdateButton
  }

  /** @type {SpaceViewModel} */
  get passwordUpdateBottomSpace () {
    return this._passwordUpdateBottomSpace
  }

  /** @type {ButtonClassicResizeViewModel} */
  get logoutButton () {
    return this._logoutButton
  }

  /** @type {SpaceViewModel} */
  get logoutBottomSpace () {
    return this._logoutBottomSpace
  }

  /** @type {ButtonSmallViewModel} */
  get legalNoticesButton () {
    return this._legalNoticesButton
  }

  /** @type {SpaceViewModel} */
  get legalNoticesBottomSpace () {
    return this._legalNoticesBottomSpace
  }

  /** @return {boolean} */
  get showUpdateLoader () {
    return this._showUpdateLoader
  }

  /** @return {boolean} */
  get showLogoutConfirmation () {
    return this._showLogoutConfirmation
  }

  /** @return {boolean} */
  get showLegalNotices () {
    return this._showLegalNotices
  }

  /** @type {PresentationInformation|null} */
  get error () {
    return this._error
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set showUpdateLoader (value) {
    this._showUpdateLoader = value
  }

  /** @param {boolean} value */
  set showLogoutConfirmation (value) {
    this._showLogoutConfirmation = value
  }

  /** @param {boolean} value */
  set showLegalNotices (value) {
    this._showLegalNotices = value
  }

  /** @param {PresentationInformation} value */
  set error (value) {
    this._error = value
  }
}
