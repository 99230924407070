import SceneCardViewModel from '@/presentation/view-model/scene/sceneCardViewModel'

// MARK: - Constants

class Constants {
  static titleKey = 'my_subscriptions'

  static CardLoader = {
    count: 15,
    height: '200px'
  }
}

// MARK: - Class

export default class SubscriptionsViewModel extends SceneCardViewModel {
  // MARK: - Properties

  /** @type {[SubscriptionsColCardViewModel]} */
  _cards

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super(localization,
      Constants.titleKey,
      Constants.CardLoader.count,
      Constants.CardLoader.height
    )
  }

  // MARK: - Getter

  /** @return {boolean} */
  get showCards () {
    return !this.showLoader && this._cards
  }

  /** @return {[SubscriptionsColCardViewModel]} */
  get cards () {
    return this._cards
  }

  // MARK: - Setter

  /** @param {[SubscriptionsColCardViewModel]} values */
  set cards (values) {
    this._cards = values
  }
}
