import AppInteractor from '@/domain/scene/app/appInteractor'
import AppDataRepository from '@/data/repository/app/appDataRepository'

export default class AppDomainInjection {
  // MARK: - Properties

  /** @type {AppInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {VueRouter} router
   * @param {AppPresenter} presenter
   */
  constructor (router, presenter) {
    const repository = new AppDataRepository()
    this._interactor = new AppInteractor(router, repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {AppInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
