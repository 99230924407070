<template>
  <custom-sheet
    :view-model="viewModel"
    @closeClick="viewModel.closeAction()"
  />
</template>

<script>
import SheetComponent from '@/app/common/component/sheet/SheetComponent'
import AlertViewModel from '@/presentation/view-model/alert/alertViewModel'
import PresentationInformation from '@/presentation/common/information/presentationInformation'

export default {
  name: 'AlertComponent',

  props: {
    information: {
      type: PresentationInformation,
      required: false
    }
  },

  components: {
    customSheet: SheetComponent
  },

  watch: {
    information () {
      this.viewModel.information = this.information
    }
  },

  data: () => ({
    /** @type AlertViewModel */
    viewModel: null
  }),

  beforeMount () {
    this.viewModel = new AlertViewModel(this.$vuetify, this.$router)
  }
}
</script>
