import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import PresentationCard from '@/presentation/common/enum/presentationCard'
import ColViewModel from '@/presentation/view-model/col/colViewModel'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'
import TextLinkViewModel from '@/presentation/view-model/text-link/textLinkViewModel'
import CardViewModel from '@/presentation/view-model/card/cardViewModel'

// MARK: - Constants

class Constants {
  static defaultText = String.dashSpace
  static messageClasses = 'text-subtitle-1' + FontWeight.regular
}

// MARK: - Class

export default class DashboardColCardMessageViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {TextLinkViewModel} */
  _textlink

  // MARK: - Constructor

  /**
   * @constructor
   * @param {int} type of component (corresponding to PresentationCard)
   * @param {string|null} colClasses of component
   * @param {boolean} isClickable of component
   */
  constructor (type, colClasses, isClickable = false) {
    const col = new ColViewModel(colClasses)
    const card = new CardViewModel(PresentationCard.backgroundColor(type), isClickable)
    super(undefined, col, card)

    const textClasses = Constants.messageClasses + String.space + PresentationCard.valueColor(type)
    this._textlink = new TextLinkViewModel(Constants.defaultText, textClasses)
  }

  // MARK: - Getter

  /** @return {TextLinkViewModel} */
  get textlink () {
    return this._textlink
  }

  // MARK: - Setter

  /** @param {string} message */
  set message (message) {
    this._textlink.text = message
  }
}
