import PasswordInitAPIBody from '@/data/api/body/password/passwordInitAPIBody'

export default class PasswordInitDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _email
  /** @type {string} */
  _code
  /** @type {string} */
  _password

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} email
   * @param {string} code
   * @param {string} password
   */
  constructor (email, code, password) {
    this._email = email
    this._code = code
    this._password = password
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {PasswordInitAPIBody}
   */
  get toAPIBody () {
    return new PasswordInitAPIBody(this._email, this._code, this._password)
  }
}
