export default class SkeletonLoaderViewModel {
  // MARK: - Properties

  /** @type {string} */
  _type
  /** @type {string} */
  _height
  /** @type {string|null} */
  _classes

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} type
   * @param {string} height
   * @param {string|null} classes
   */
  constructor (type,
    height,
    classes = null) {
    this._type = type
    this._height = height
    this._classes = classes
  }

  // MARK: - Getter

  /** @return {string} */
  get type () {
    return this._type
  }

  /** @return {string} */
  get height () {
    return this._height
  }

  /** @return {string|null} */
  get classes () {
    return this._classes
  }
}
