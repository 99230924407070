import TextFieldPasswordViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordViewModel'
import { required, sameAs } from 'vuelidate/lib/validators'
import TextFieldPasswordNewViewModel from '@/presentation/view-model/textfield/password/textFieldPasswordNewViewModel'

// MARK: - Constants

class Constants {
  static labelKey = 'confirm_new_password'
  static autocomplete = 'new-password'
  static requiredKey = 'confirm_password_required'
  static sameAsKey = 'error_password_on_confirm'
}

// MARK: - Class

export default class TextFieldPasswordConfirmViewModel extends TextFieldPasswordViewModel {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {any} */
  _validation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {any} validation
   */
  constructor (localization, validation) {
    super(localization,
      validation,
      Constants.labelKey,
      Constants.autocomplete
    )

    this._localization = localization
    this._validation = validation
  }

  // MARK: - Getter

  /** @return {array} */
  static get validation () {
    return {
      confirmPassword: {
        required,
        sameAsPassword: sameAs(TextFieldPasswordNewViewModel.validationKey)
      }
    }
  }

  /** @return {string[]} */
  get errorMessage () {
    const errors = []
    if (!this._validation.confirmPassword.$dirty) return errors
    !this._validation.confirmPassword.required && errors.push(this._localization.getText(Constants.requiredKey))
    !this._validation.confirmPassword.sameAsPassword && errors.push(this._localization.getText(Constants.sameAsKey))
    return errors
  }
}
