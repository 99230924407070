import ReservationsDomainModel from '@/domain/scene/reservations/model/reservationsDomainModel'
import DomainError from '@/domain/error/domainError'

export default class ReservationsInteractor {
  // MARK: - Properties

  /** @type {ReservationsDataRepository} */
  _repository
  /** @type {ReservationsPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationsDataRepository} repository
   * @param {ReservationsPresenter} presenter
   */
  constructor (repository, presenter) {
    this._repository = repository
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = true

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false

    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Get reservations from repository
    this._recoverReservationsFromRepository()
  }

  // MARK: - To Repository

  /**
   * Get reservations from repository
   * @param {boolean} forceApiReload
   */
  _recoverReservationsFromRepository (forceApiReload = false) {
    const self = this
    this._repository.reservations(forceApiReload).then(function (reservations) {
      self._reservationsFromRepository = reservations
    }).catch(e => {
      // Send error to presenter
      self._presenter.prepareErrorFromInteractor = new DomainError(e)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false

      // Show refresh
      self._presenter.showRefreshFromInteractor = true
    })
  }

  // MARK: - From Repository

  /**
   * Convert object to Data entry model
   * @param {[ReservationsDataModel]} values
   */
  set _reservationsFromRepository (values) {
    // Convert to domain model array
    const reservations = values.map(e => {
      return new ReservationsDomainModel(e)
    })

    // Sort array from start date and send it to presenter
    this._presenter.reservationsFromInteractor = reservations.sort((a, b) => {
      return b.session.startAt - a.session.startAt
    })

    // Hide loader
    this._presenter.showLoaderFromInteractor = false

    // Show refresh
    this._presenter.showRefreshFromInteractor = true
  }

  // MARK: - From Controller

  refreshTappedFromController () {
    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Refresh content from repository
    this._recoverReservationsFromRepository(true)
  }
}
