import ReservationSessionDomainParameter from '@/domain/scene/reservation/parameter/reservationSessionDomainParameter'

export default class ReservationSessionPresentationParameter {
  // MARK: - Properties

  /** @type {string} */
  _id
  /** @type {moment.Moment} */
  _startAt

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ReservationSessionDomainParameter} domainParameter
   */
  constructor (domainParameter) {
    this._id = domainParameter.id
    this._startAt = domainParameter.startAt
  }

  // MARK: - Conversion

  /**
   * Convert object to domain parameter
   * @return {ReservationSessionDomainParameter}
   */
  get toDomainParameter () {
    return new ReservationSessionDomainParameter(this._id, this._startAt)
  }
}
