import SessionsForWeekColCardViewModel from '@/presentation/scene/sessions-for-week/view-model/sessionsForWeekColCardViewModel'
import DateFormat from '@/common/utils/dateFormat'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'

// MARK: - Constants

class Constants {
  static dayClasses = 'text-h6' + FontWeight.regular
}

// MARK: - Class

export default class SessionsForWeekSectionViewModel {
  // MARK: - Properties

  /** @type {string} */
  _key
  /** @type {boolean} */
  _showTopSpace
  /** @type {SpaceViewModel} */
  _topSpace
  /** @type {string} */
  _day
  /** @type {string} */
  _dayClasses
  /** @type {[SessionsForWeekColCardViewModel]} */
  _cards

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {SessionsForWeekDayDomainModel} domainModel
   * @param {boolean} isFirstSection
   */
  constructor (localization, domainModel, isFirstSection) {
    const day = DateFormat.string(domainModel.day, DateFormat.DDDD_DD_MMMM)
    this._key = day
    this._showTopSpace = !isFirstSection
    this._topSpace = new SpaceViewModel(PresentationSpace.NORMAL)
    this._day = String.capitalizeFirstLetter(day)
    this._dayClasses = Constants.dayClasses
    this._cards = domainModel.sessions.map(e => {
      return new SessionsForWeekColCardViewModel(localization, e)
    })
  }

  // MARK: - Getter

  /** @return {string} */
  get key () {
    return this._key
  }

  /** @return {boolean} */
  get showTopSpace () {
    return this._showTopSpace
  }

  /** @return {SpaceViewModel} */
  get topSpace () {
    return this._topSpace
  }

  /** @return {string} */
  get day () {
    return this._day
  }

  /** @return {string} */
  get dayClasses () {
    return this._dayClasses
  }

  /** @return {[SessionsForWeekColCardViewModel]} */
  get cards () {
    return this._cards
  }
}
