import DashboardDomainModel from '@/domain/scene/dashboard/model/dashboardDomainModel'
import DomainError from '@/domain/error/domainError'
import PushNotificationManager from '@/domain/manager/pushNotificationManager'
import { Bus, Events } from '@/presentation/main'

export default class DashboardInteractor {
  // MARK: - Properties

  /** @type {DashboardDataRepository} */
  _repository
  /** @type {DashboardPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardDataRepository} repository
   * @param {DashboardPresenter} presenter
   */
  constructor (repository, presenter) {
    this._repository = repository
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Show navigation
    this._presenter.showNavigationFromInteractor = true

    // Put content on top of screen
    this._presenter.centerContentFromInteractor = false

    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Get dashboard from repository
    this._recoverDashboardFromRepository()

    // Try to send user id on push
    Bus.$emit(Events.sendUserOnPushManager)
  }

  // MARK: - To Repository

  /**
   * Get dashboard from repository
   * @param {boolean} forceApiReload
   */
  _recoverDashboardFromRepository (forceApiReload = false) {
    const self = this
    this._repository.dashboard(forceApiReload).then(function (dashboard) {
      // Ask to register to push notification ?
      PushNotificationManager.shared.canRegister.then(canRegister => {
        // Convert to domain model array and send it to presenter
        self._presenter.dashboardFromInteractor = new DashboardDomainModel(dashboard, canRegister)

        // Hide loader
        self._presenter.showLoaderFromInteractor = false

        // Show refresh
        self._presenter.showRefreshFromInteractor = true
      })
    }).catch(e => {
      // Send error to presenter
      self._presenter.prepareErrorFromInteractor = new DomainError(e)

      // Hide loader
      self._presenter.showLoaderFromInteractor = false

      // Show refresh
      self._presenter.showRefreshFromInteractor = true
    })
  }

  // MARK: - From Controller

  refreshTappedFromController () {
    this._refreshContent(true)
  }

  registerToPushTappedFromController () {
    // Register to push
    PushNotificationManager.shared.registration()

    // Refresh content to hide push card
    this._refreshContent(false)
  }

  // MARK: - Methods

  /**
   * Get dashboard from repository
   * @param {boolean} forceApiReload
   */
  _refreshContent (forceApiReload) {
    // Show loader
    this._presenter.showLoaderFromInteractor = true

    // Hide refresh
    this._presenter.showRefreshFromInteractor = false

    // Refresh content from repository
    this._recoverDashboardFromRepository(forceApiReload)
  }
}
