<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M15,3.15468174 L15,7 L18.9551845,7 L15,3.15468174 Z M20,9 L14,9 C13.4477153,9 13,8.55228475 13,8 L13,2 L5.14285714,2 C4.51167457,2 4,2.49746139 4,3.11111111 L4,20.8888889 C4,21.5025386 4.51167457,22 5.14285714,22 L18.8571429,22 C19.4883254,22 20,21.5025386 20,20.8888889 L20,9 Z M14.2222222,0 C14.5169072,0 14.7995227,0.114934735 15.0078964,0.319519875 L21.6745631,6.86497442 C21.8829368,7.06955956 22,7.34703656 22,7.63636364 L22,20.7272727 C22,22.5347501 20.5076158,24 18.6666667,24 L5.33333333,24 C3.49238417,24 2,22.5347501 2,20.7272727 L2,3.27272727 C2,1.46524991 3.49238417,0 5.33333333,0 L14.2222222,0 Z M16.8,13 C17.4627417,13 18,13.4477153 18,14 C18,14.5522847 17.4627417,15 16.8,15 L7.2,15 C6.5372583,15 6,14.5522847 6,14 C6,13.4477153 6.5372583,13 7.2,13 L16.8,13 Z M16.8,17 C17.4627417,17 18,17.4477153 18,18 C18,18.5522847 17.4627417,19 16.8,19 L7.2,19 C6.5372583,19 6,18.5522847 6,18 C6,17.4477153 6.5372583,17 7.2,17 L16.8,17 Z M9,7 C9.55228475,7 10,7.44771525 10,8 C10,8.55228475 9.55228475,9 9,9 L7,9 C6.44771525,9 6,8.55228475 6,8 C6,7.44771525 6.44771525,7 7,7 L9,7 Z" id="path-1"></path>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationSubscriptionsIcon'
})
</script>
