<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M23,22.75 C23,23.4403559 22.4527925,24 21.7777778,24 C21.1027631,24 20.5555556,23.4403559 20.5555556,22.75 L20.5555556,20.25 C20.5555556,18.1789322 18.913933,16.5 16.8888889,16.5 L7.11111111,16.5 C5.08606703,16.5 3.44444444,18.1789322 3.44444444,20.25 L3.44444444,22.75 C3.44444444,23.4403559 2.89723692,24 2.22222222,24 C1.54720753,24 1,23.4403559 1,22.75 L1,20.25 C1,16.7982203 3.73603764,14 7.11111111,14 L16.8888889,14 C20.2639624,14 23,16.7982203 23,20.25 L23,22.75 Z M12,12 C8.6862915,12 6,9.3137085 6,6 C6,2.6862915 8.6862915,0 12,0 C15.3137085,0 18,2.6862915 18,6 C18,9.3137085 15.3137085,12 12,12 Z M12,10 C14.209139,10 16,8.209139 16,6 C16,3.790861 14.209139,2 12,2 C9.790861,2 8,3.790861 8,6 C8,8.209139 9.790861,10 12,10 Z" id="path-1"></path>
  </svg>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'NavigationProfilIcon'
})
</script>
