import PopupVisibilityViewModel from '@/presentation/view-model/popup/popupVisibilityViewModel'

// MARK: - Constants

class Constants {
  static persistent = true
  static maxWidth = '800px'
}

// MARK: - Class

export default class DialogViewModel extends PopupVisibilityViewModel {
  // MARK: - Properties

  /** @type {string} */
  _maxWidth
  /** @type {boolean} */
  _persistent

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    super()

    this._maxWidth = Constants.maxWidth
    this._persistent = Constants.persistent
  }

  // MARK: - Getter

  /** @return {string} */
  get maxWidth () {
    return this._maxWidth
  }

  /** @return {boolean} */
  get persistent () {
    return this._persistent
  }
}
