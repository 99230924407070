export default class ContainerLayoutFlexViewModel {
  // MARK: - Properties

  /** @type {ContainerViewModel} */
  _container
  /** @type {LayoutViewModel} */
  _layout
  /** @type {FlexViewModel} */
  _flex

  // MARK: - Constructor

  /**
   * @constructor
   * @param {ContainerViewModel} container
   * @param {LayoutViewModel} layout
   * @param {FlexViewModel} flex
   */
  constructor (container,
    layout,
    flex) {
    this._container = container
    this._layout = layout
    this._flex = flex
  }

  // MARK: - Getter

  /** @return {ContainerViewModel} */
  get container () {
    return this._container
  }

  /** @return {LayoutViewModel} */
  get layout () {
    return this._layout
  }

  /** @return {FlexViewModel} */
  get flex () {
    return this._flex
  }
}
