import DashboardReservationDataModel from '@/data/repository/dashboard/model/dashboardReservationDataModel'
import DashboardSubscriptionDataModel from '@/data/repository/dashboard/model/dashboardSubscriptionDataModel'
import DashboardInformationDataModel from '@/data/repository/dashboard/model/dashboardInformationDataModel'
import Moment from 'moment'

export default class DashboardDataModel {
  // MARK: - Properties

  /** @type {int} */
  _reservations
  /** @type {int} */
  _participatingSession
  /** @type {int} */
  _canceledReservations
  /** @type {int} */
  _monthReservations
  /** @type {int} */
  _yearReservations
  /** @type {DashboardReservationDataModel|null} */
  _nextReservation
  /** @type {moment.Moment} */
  _startDateFirstSubscription
  /** @type {DashboardSubscriptionDataModel|null} */
  _validSubscriptionEnding
  /** @type {boolean} */
  _subscriptionExpiresSoon
  /** @type {DashboardInformationDataModel[]|null} */
  _informations

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._reservations = response.reservations
    this._participatingSession = response.participatingSession
    this._canceledReservations = response.canceledReservations
    this._monthReservations = response.monthReservations
    this._yearReservations = response.yearReservations
    if (response.nextReservation) {
      this._nextReservation = new DashboardReservationDataModel(response.nextReservation)
    }
    this._startDateFirstSubscription = Moment(response.startDateFirstSubscription)
    if (response.validSubscriptionEnding) {
      this._validSubscriptionEnding = new DashboardSubscriptionDataModel(response.validSubscriptionEnding)
    }
    this._subscriptionExpiresSoon = response.subscriptionExpiresSoon
    if (response.informations) {
      this._informations = response.informations.map(e => {
        return new DashboardInformationDataModel(e)
      })
    }
  }

  // MARK: - Getter

  /** @return {int} */
  get reservations () {
    return this._reservations
  }

  /** @return {int} */
  get participatingSession () {
    return this._participatingSession
  }

  /** @return {int} */
  get canceledReservations () {
    return this._canceledReservations
  }

  /** @return {int} */
  get monthReservations () {
    return this._monthReservations
  }

  /** @return {int} */
  get yearReservations () {
    return this._yearReservations
  }

  /** @return {DashboardReservationDataModel|null} */
  get nextReservation () {
    return this._nextReservation
  }

  /** @return {moment.Moment} */
  get startDateFirstSubscription () {
    return this._startDateFirstSubscription
  }

  /** @return {DashboardSubscriptionDataModel|null} */
  get validSubscriptionEnding () {
    return this._validSubscriptionEnding
  }

  /** @return {boolean} */
  get subscriptionExpiresSoon () {
    return this._subscriptionExpiresSoon
  }

  /** @return {DashboardInformationDataModel[]|null} */
  get informations () {
    return this._informations
  }
}
