// MARK: - Constants

const TOKEN = 0
const BAD_CREDENTIALS = 1
const ACCOUNT_DISABLED = 2
const NOT_FOUND = 3
const UNKNOW = 4

// MARK: - Enum

export default {
  TOKEN,
  BAD_CREDENTIALS,
  ACCOUNT_DISABLED,
  NOT_FOUND,
  UNKNOW
}
