import NotFoundInteractor from '@/domain/scene/not-found/notFoundInteractor'
import RouteDataRepository from '@/data/repository/router/routeDataRepository'

export default class NotFoundDomainInjection {
  // MARK: - Properties

  /** @type {NotFoundInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {NotFoundPresenter} presenter
   */
  constructor (presenter) {
    const repository = new RouteDataRepository()
    this._interactor = new NotFoundInteractor(repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {NotFoundInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
