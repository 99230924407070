// MARK: - Constants

class Constants {
  static keyPrefix = '$'

  static logo = 'iconLogo'
  static logoTitle = 'iconLogoTitle'

  static navigationDashboard = 'iconNavigationDashboard'
  static navigationProfile = 'iconNavigationProfile'
  static navigationReservations = 'iconNavigationReservations'
  static navigationSessions = 'iconNavigationSessions'
  static navigationSubscriptions = 'iconNavigationSubscriptions'
}

// MARK: - Class

export default class Icon {
  // MARK: - Properties

  static logo = Icon._getIcon(Constants.logo)
  static logoTitle = Icon._getIcon(Constants.logoTitle)

  static navigationDashboard = Icon._getIcon(Constants.navigationDashboard)
  static navigationProfile = Icon._getIcon(Constants.navigationProfile)
  static navigationReservations = Icon._getIcon(Constants.navigationReservations)
  static navigationSessions = Icon._getIcon(Constants.navigationSessions)
  static navigationSubscriptions = Icon._getIcon(Constants.navigationSubscriptions)

  // MARK: - Getter

  /**
   * @param {string} name of icon
   * @return {string}
   **/
  static _getIcon (name) {
    return Constants.keyPrefix + name
  }
}
