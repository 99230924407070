import { Bus, Events } from '@/presentation/main'

export default class LoginController {
  // MARK: - Properties

  /** @type {LoginInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {LoginInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  /** @param {string|null} anchor  */
  vueMounted (anchor) {
    this._interactor.vueMounted(anchor)
  }

  vueDestroyed () {
    this._clearBus()
  }

  // MARK: - Methods

  legalNoticesTapped () {
    this._interactor.legalNoticesTappedFromController()
  }

  /** @param {boolean} legalNoticesReaded  */
  loginTapped (legalNoticesReaded) {
    this._interactor.loginTappedFromController(legalNoticesReaded)
  }

  passwordForgotTapped () {
    this._interactor.passwordForgotTappedFromController()
  }

  // MARK: - Bus

  _loadBus () {
    // Check when forgot will be closed
    const self = this

    Bus.$on(Events.closeLegalNoticesAsked, _ => {
      self._interactor.closeLegalNoticesAskedFromController()
    })

    Bus.$on(Events.closeForgotAsked, _ => {
      self._interactor.closePasswordForgotAskedFromController()
    })
  }

  _clearBus () {
    Bus.$off(Events.closeForgotAsked)
    Bus.$off(Events.closeLegalNoticesAsked)
  }
}
