export default class Date {
  // MARK: - Properties

  /**
   * @param {moment.Moment} date
   * @return {string}
   **/
  static stringForApi (date) {
    return date.toISOString()
  }
}
