import SheetStructureViewModel from '@/presentation/view-model/sheet/sheetStructureViewModel'
import LoaderAnimationViewModel from '@/presentation/view-model/animation/loaderAnimationViewModel'
import PopupVisibilityViewModel from '@/presentation/view-model/popup/popupVisibilityViewModel'

// MARK: - Class

export default class LoaderViewModel extends PopupVisibilityViewModel {
// MARK: - Properties

  /** @type {SheetStructureViewModel} */
  _structure
  /** @type {LoaderAnimationViewModel} */
  _animation

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    super()

    this._structure = new SheetStructureViewModel()
    this._animation = new LoaderAnimationViewModel(vuetify)
  }

  // MARK: - Getter

  /** @return {SheetStructureViewModel} */
  get structure () {
    return this._structure
  }

  /** @return {LoaderAnimationViewModel} */
  get animation () {
    return this._animation
  }

  // MARK: - Setter

  set show (show) {
    super.show = show
    this._animation.play = show
  }
}
