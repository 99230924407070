// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from '@/app/scene/app/AppVue'
import router from '@/app/router'
import vuetify from '@/app/plugins/vuetify'
import { validationMixin } from 'vuelidate'
import '@/presentation/main'

import '@/app/worker/registerServiceWorker'
import 'vuetify/dist/vuetify.min.css'

Vue.config.productionTip = false

// TODO: ne pas commit les .env en pp (gestion sur Heroku) et prod (à voir pour la gestion sur OVH : via Gitlab peut-être)

// TODO: mettre en place le même fonctionnement que le web-front pour le vuetify

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  mixins: [validationMixin],
  components: { App },
  template: '<App/>'
})

window.OneSignal = window.OneSignal || []
