import ReservationsViewModel from '@/presentation/scene/reservations/view-model/reservationsViewModel'
import ReservationsColCardViewModel from '@/presentation/scene/reservations/view-model/reservationsColCardViewModel'
import Localization from '@/presentation/utils/localization'
import PresentationError from '@/presentation/common/error/presentationError'
import ContentPresenter from '@/presentation/utils/contentPresenter'

export default class ReservationsPresenter extends ContentPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {ReservationsViewModel} */
  _viewModel

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    super()
    this._localization = new Localization(vuetify)
    this._viewModel = new ReservationsViewModel(this._localization)
  }

  // MARK: - Getter

  /** @return {ReservationsViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showLoaderFromInteractor (value) {
    this._viewModel.showLoader = value
  }

  /** @param {boolean} value */
  set showRefreshFromInteractor (value) {
    this._viewModel.showRefresh = value
  }

  /** @param {[ReservationsDomainModel]} values */
  set reservationsFromInteractor (values) {
    // Convert to view model array
    this._viewModel.cards = values.map(e => {
      return new ReservationsColCardViewModel(this._localization, e)
    })
  }

  /** @param {DomainError} error */
  set prepareErrorFromInteractor (error) {
    const presentationError = PresentationError.fromDomain(this._localization, error)
    this._viewModel.information = presentationError.toInformation
  }
}
