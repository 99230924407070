import ApiConfiguration from '@/data/api/configuration/apiConfiguration'
import ApiWorker from '@/data/api/worker/apiWorker'

// MARK: - Constants

class Constants {
  static Path = {
    get: 'dashboard/client'
  }
}

// MARK: - Class

export default class DashboardAPITarget {
  // MARK: - Properties

  /** @type {ApiWorker} */
  _worker

  // MARK: - Constructor

  constructor () {
    const configuration = new ApiConfiguration(true)
    this._worker = new ApiWorker(configuration)
  }

  // MARK: - Get

  /**
   * Call API to get client dashboard
   * @return {Promise}
   */
  get () {
    return this._worker.getRequest(Constants.Path.get)
  }
}
