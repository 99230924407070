import SheetContentViewModel from '@/presentation/view-model/sheet/sheetContentViewModel'
import SheetStructureViewModel from '@/presentation/view-model/sheet/sheetStructureViewModel'
import PopupVisibilityViewModel from '@/presentation/view-model/popup/popupVisibilityViewModel'
import SheetTitleViewModel from '@/presentation/view-model/sheet/text/sheetTitleViewModel'
import SheetDescriptionViewModel from '@/presentation/view-model/sheet/text/sheetDescriptionViewModel'
import SpaceViewModel from '@/presentation/view-model/space/spaceViewModel'
import PresentationSpace from '@/presentation/common/enum/presentationSpace'
import ButtonSmallViewModel from '@/presentation/view-model/button/buttonSmallViewModel'

// MARK: - Class

export default class SheetViewModel extends PopupVisibilityViewModel {
  // MARK: - Properties

  /** @type {SheetStructureViewModel} */
  _structure
  /** @type {SheetContentViewModel} */
  _content
  /** @type {SheetTitleViewModel} */
  _title
  /** @type {SheetDescriptionViewModel} */
  _description
  /** @type {SpaceViewModel} */
  _descriptionBottomSpace
  /** @type {ButtonSmallViewModel} */
  _closeButton
  /** @type {SpaceViewModel} */
  _closeBottomSpace

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} titleKey
   * @param {string} descriptionKey
   * @param {string} closeTitleKey
   * @param {boolean} showMain
   * @param {boolean} showContent
   */
  constructor (localization,
    titleKey,
    descriptionKey,
    closeTitleKey,
    showMain,
    showContent) {
    super()

    this._structure = new SheetStructureViewModel()
    this._title = new SheetTitleViewModel(localization, titleKey)
    this._content = new SheetContentViewModel()
    this._description = new SheetDescriptionViewModel(localization, descriptionKey)
    this._descriptionBottomSpace = new SpaceViewModel(PresentationSpace.CLASSIC)
    this._closeButton = new ButtonSmallViewModel(localization, closeTitleKey)
    this._closeBottomSpace = new SpaceViewModel(PresentationSpace.BIG)
  }

  // MARK: - Getter

  /** @return {SheetStructureViewModel} */
  get structure () {
    return this._structure
  }

  /** @return {SheetContentViewModel} */
  get content () {
    return this._content
  }

  /** @return {SheetTitleViewModel} */
  get title () {
    return this._title
  }

  /** @return {SheetDescriptionViewModel} */
  get description () {
    return this._description
  }

  /** @return {SpaceViewModel} */
  get descriptionBottomSpace () {
    return this._descriptionBottomSpace
  }

  /** @return {ButtonSmallViewModel} */
  get closeButton () {
    return this._closeButton
  }

  /** @return {SpaceViewModel} */
  get closeBottomSpace () {
    return this._closeBottomSpace
  }
}
