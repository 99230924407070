// MARK: - Constants

class Constants {
  static layoutJustifyCenter = true
  static layoutAlignCenter = true
  static flexClasses = 'xs10'
}

// MARK: - Class

export default class SheetContentViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _layoutJustifyCenter
  /** @type {boolean} */
  _layoutAlignCenter
  /** @type {string} */
  _flexClasses

  // MARK: - Constructor

  constructor () {
    this._layoutJustifyCenter = Constants.layoutJustifyCenter
    this._layoutAlignCenter = Constants.layoutAlignCenter
    this._flexClasses = Constants.flexClasses
  }

  // MARK: - Getter

  /** @return {boolean} */
  get layoutJustifyCenter () {
    return this._layoutJustifyCenter
  }

  /** @return {boolean} */
  get layoutAlignCenter () {
    return this._layoutAlignCenter
  }

  /** @return {string} */
  get flexClasses () {
    return this._flexClasses
  }
}
