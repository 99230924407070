<template>
  <custom-col-card :viewModel="viewModel">
    <!-- Reservation value -->
    <custom-state-information :viewModel="viewModel.stateInformation"/>
  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import StateInformationComponent from '@/app/common/component/state-information/StateInformationComponent'
import DashboardColCardReservationViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardReservationViewModel'

export default {
  name: 'DashboardColCardReservationComponent',

  components: {
    customColCard: ColCardComponent,
    customStateInformation: StateInformationComponent
  },

  props: {
    viewModel: {
      type: DashboardColCardReservationViewModel,
      required: true
    }
  }
}
</script>
