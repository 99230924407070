<template>
  <custom-col-card :viewModel="viewModel">

    <!-- Value -->
    <div :class="viewModel.valueClasses">
      {{ viewModel.value }}
    </div>

    <!-- Title -->
    <div :class="viewModel.descriptionClasses">
      {{ viewModel.description }}
    </div>

  </custom-col-card>
</template>

<script>
import ColCardComponent from '@/app/common/component/col/ColCardComponent'
import DashboardColCardValueViewModel from '@/presentation/scene/dashboard/view-model/col-card/dashboardColCardValueViewModel'

export default {
  name: 'DashboardColCardValueComponent',

  components: {
    customColCard: ColCardComponent
  },

  props: {
    viewModel: {
      type: DashboardColCardValueViewModel,
      required: true
    }
  }
}
</script>
