import DashboardInteractor from '@/domain/scene/dashboard/dashboardInteractor'
import DashboardDataRepository from '@/data/repository/dashboard/dashboardDataRepository'

export default class DashboardDomainInjection {
  // MARK: - Properties

  /** @type {DashboardInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {DashboardPresenter} presenter
   */
  constructor (presenter) {
    const repository = new DashboardDataRepository()
    this._interactor = new DashboardInteractor(repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {DashboardInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
