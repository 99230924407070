import { Bus, Events } from '@/presentation/main'

export default class AppController {
  // MARK: - Properties

  /** @type {AppInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {AppInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
    this._loadBus()
  }

  // MARK: - Life cycle vue

  vueDestroyed () {
    this._clearBus()
  }

  // MARK: - Methods

  installAppTapped (key) {
    this._interactor.installAppTappedFromController(key)
  }

  // MARK: - Bus

  _loadBus () {
    // Check when navigation status changed
    const self = this
    Bus.$on(Events.navigationChanged, (value) => {
      self._interactor.navigationStatusChangedFromController(value)
    })

    Bus.$on(Events.centerContentChanged, (value) => {
      self._interactor.centerContentChangedFromController(value)
    })

    Bus.$on(Events.sendUserOnPushManager, (value) => {
      self._interactor.sendUserOnPushManagerFromController(value)
    })

    window.addEventListener(Events.nativeAppNotInstalled, e => {
      self._interactor.nativeAppIsNotInstalledFromController(e)
    })

    window.addEventListener(Events.nativeAppInstalled, () => {
      self._interactor.nativeAppIsInstalledFromController()
    })
  }

  _clearBus () {
    Bus.$off(Events.navigationChanged)
    Bus.$off(Events.centerContentChanged)
    Bus.$off(Events.sendUserOnPushManager)
  }
}
