import DashboardDataModel from '@/data/repository/dashboard/model/dashboardDataModel'
import DashboardAPITarget from '@/data/api/target/dashboardAPITarget'
import ObjectDataProviderWorker from '@/data/data-provider/worker/objectDataProviderWorker'
import DataProviderType from '@/data/data-provider/enum/dataProviderType'
import RetrieveDataFromAPI from '@/data/utils/retrieveDataFromAPI'
import DataError from '@/data/error/dataError'

export default class DashboardDataRepository extends RetrieveDataFromAPI {
  // MARK: - Properties

  /** @type {DashboardAPITarget} */
  _apiTarget
  /** @type {ObjectDataProviderWorker} */
  _dataProviderWorker

  // MARK: - Constructor

  constructor () {
    super()

    this._apiTarget = new DashboardAPITarget()
    this._dataProviderWorker = new ObjectDataProviderWorker(DataProviderType.DASHBOARD)
  }

  // MARK: - Getter

  /**
   * Get dashboard mapped
   * @return {DashboardDataModel}
   */
  _getDashboardMapped (response) {
    return new DashboardDataModel(response)
  }

  /**
   * Get client dashboard
   * @param {boolean} forceApiReload
   * @return {Promise<DashboardDataModel>}
   */
  dashboard (forceApiReload) {
    const self = this
    return new Promise(function (resolve, reject) {
      // Force reload or data expired on cache ? Call API
      if (forceApiReload || self._dataProviderWorker.isExpired) {
        self._retrieveObject(self._apiTarget.get()).then(({ data, details }) => {
          // Save data on local
          self._dataProviderWorker.save(data, details.expiredAt)

          const dashboard = self._getDashboardMapped(data)
          resolve(dashboard)
        }).catch(e => {
          const error = DataError.fromAnyError(e)
          reject(error)
        })
      } else { // Get data from cache
        const response = self._dataProviderWorker.get
        const dashboard = self._getDashboardMapped(response)
        resolve(dashboard)
      }
    })
  }
}
