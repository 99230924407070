// MARK: - Constants

const TOKEN = 0
const BAD_CREDENTIALS = 1
const ACCOUNT_DISABLED = 2
const REFRESH_TOKEN = 3
const UNDECODABLE = 4
const UNKNOW = 5

// MARK: - Enum

export default {
  TOKEN,
  BAD_CREDENTIALS,
  ACCOUNT_DISABLED,
  REFRESH_TOKEN,
  UNDECODABLE,
  UNKNOW
}
