export default class NotFoundInteractor {
  // MARK: - Properties

  /** @type {RouteDataRepository} */
  _repository
  /** @type {NotFoundPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {RouteDataRepository} repository
   * @param {NotFoundPresenter} presenter
   */
  constructor (repository, presenter) {
    this._repository = repository
    this._presenter = presenter
  }

  // MARK: - Life cycle vue

  vueMounted () {
    // Hide navigation
    this._presenter.showNavigationFromInteractor = false

    // Put content on center of screen
    this._presenter.centerContentFromInteractor = true
  }

  // MARK: - From Controller

  redirectTappedFromController () {
    if (this._repository.containsToken) {
      this._presenter.prepareConnectedSpaceFromInteractor()
    } else {
      this._presenter.prepareLoginFromInteractor()
    }
  }
}
