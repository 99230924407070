import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'
import PresentationCard from '@/presentation/common/enum/presentationCard'
import ColCardViewModel from '@/presentation/view-model/col/colCardViewModel'

// MARK: - Constants

class Constants {
  static defaultType = PresentationCard.DEFAULT
  static defaultText = String.dashSpace
  static valueClasses = 'text-subtitle-1' + FontWeight.medium
  static titleClasses = 'text-subtitle-1 pb-1' + FontWeight.italic
}

// MARK: - Class

export default class DashboardColCardDetailsViewModel extends ColCardViewModel {
  // MARK: - Properties

  /** @type {string} */
  _title
  /** @type {string} */
  _titleClasses
  /** @type {string} */
  _value
  /** @type {string} */
  _valueClasses

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    const type = Constants.defaultType
    super(PresentationCard.backgroundColor(type))

    this._title = Constants.defaultText
    this._titleClasses = Constants.titleClasses + String.space + PresentationCard.textColor()
    this._value = Constants.defaultText
    this._valueClasses = Constants.valueClasses + String.space + PresentationCard.valueColor(type)
  }

  // MARK: - Getter

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get titleClasses () {
    return this._titleClasses
  }

  /** @return {string} */
  get value () {
    return this._value
  }

  /** @return {string} */
  get valueClasses () {
    return this._valueClasses
  }

  // MARK: - Setter

  /** @param {string} value */
  set title (value) {
    this._title = value
  }

  /** @param {string} value */
  set value (value) {
    this._value = value
  }
}
