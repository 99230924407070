import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'

// MARK: - Constants

const DEFAULT = 0
const YELLOW = 1
const RED = 2
const GREEN = 3

// MARK: - Enum

export default {
  DEFAULT,
  YELLOW,
  RED,
  GREEN,

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  backgroundColor (value) {
    switch (value) {
      case YELLOW:
        return Color.main
      case RED:
        return Color.red
      case GREEN:
        return Color.greenMiDark
      default:
        return Color.darkLight
    }
  },

  /**
   * @param {int} value corresponding to this enum
   * @return {string}
   */
  valueColor (value) {
    let color
    switch (value) {
      case YELLOW: case RED: case GREEN:
        color = Color.black
        break
      default:
        color = Color.white
    }
    return color + String.textColorSuffix
  },

  /** @return {string} */
  textColor () {
    return Color.veryLightGray + String.textColorSuffix
  }
}
