import CheckboxViewModel from '@/presentation/view-model/checkbox/checkboxViewModel'
import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static completeLabelKey = 'read_legal_notices_and_cookies'
  static linkLabelKey = 'read_legal_notices_and_cookies_link'
  static linkColor = Color.white + String.textColorSuffix
}

// MARK: - Class

export default class LoginLegalNoticesCheckboxViewModel extends CheckboxViewModel {
  // MARK: - Properties

  /** @type {string} */
  _labelStart
  /** @type {string} */
  _labelLink
  /** @type {string} */
  _labelLinkClasses
  /** @type {string} */
  _labelEnd

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   */
  constructor (localization) {
    super()

    const text = localization.getText(Constants.completeLabelKey)
    const linkText = localization.getText(Constants.linkLabelKey)

    this._labelStart = text.split(linkText)[0]
    this._labelLink = linkText
    this._labelLinkClasses = Constants.linkColor
    this._labelEnd = text.split(linkText)[1]
  }

  // MARK: - Getter

  /** @return {string} */
  get labelStart () {
    return this._labelStart
  }

  /** @return {string} */
  get labelLink () {
    return this._labelLink
  }

  /** @return {string} */
  get labelLinkClasses () {
    return this._labelLinkClasses
  }

  /** @return {string} */
  get labelEnd () {
    return this._labelEnd
  }
}
