import LegalNoticesViewModel from '@/presentation/scene/legal-notices/legalNoticesViewModel'
import Localization from '@/presentation/utils/localization'
import { Bus, Events } from '@/presentation/main'

export default class LegalNoticesPresenter {
  // MARK: - Properties

  /** @type {Localization} */
  _localization
  /** @type {LegalNoticesViewModel} */
  _viewModel

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._localization = new Localization(vuetify)
    this._viewModel = new LegalNoticesViewModel(this._localization)
  }

  // MARK: - Getter

  /** @return {LegalNoticesViewModel} */
  get viewModel () {
    return this._viewModel
  }

  // MARK: - From Interactor

  /** @param {boolean} value */
  set showFromInteractor (value) {
    // Send status for App
    this._viewModel.show = value
  }

  prepareReturnToParentFromInteractor () {
    // Send status for App
    Bus.$emit(Events.closeLegalNoticesAsked)
  }
}
