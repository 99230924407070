export default class DashboardInformationDataModel {
  // MARK: - Properties

  /** @type {int} */
  _level
  /** @type {string} */
  _message

  // MARK: - Constructor

  /**
   * @constructor
   * @param {any} response corresponding to ArrayAPIResponse.data[x]
   */
  constructor (response) {
    this._level = response.level
    this._message = response.message
  }

  // MARK: - Getter

  /** @return {int} */
  get level () {
    return this._level
  }

  /** @return {string} */
  get message () {
    return this._message
  }
}
