import PasswordUpdateAPIBody from '@/data/api/body/password/passwordUpdateAPIBody'

export default class PasswordUpdateDataEntry {
  // MARK: - Properties

  /** @type {string} */
  _current
  /** @type {string} */
  _new

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} currentPassword
   * @param {string} newPassword
   */
  constructor (currentPassword, newPassword) {
    this._current = currentPassword
    this._new = newPassword
  }

  // MARK: - Conversion

  /**
   * Convert object to API Body
   * @return {PasswordUpdateAPIBody}
   */
  get toAPIBody () {
    return new PasswordUpdateAPIBody(this._current, this._new)
  }
}
