export default class NotFoundController {
  // MARK: - Properties

  /** @type {NotFoundInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {NotFoundInteractor} interactor
   */
  constructor (interactor) {
    this._interactor = interactor
  }

  // MARK: - Life cycle vue

  vueMounted () {
    this._interactor.vueMounted()
  }

  // MARK: - Methods

  redirectTapped () {
    this._interactor.redirectTappedFromController()
  }
}
