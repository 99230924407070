import ContainerLayoutFlexViewModel from '@/presentation/view-model/container-layout-flex/containerLayoutFlexViewModel'
import ContainerViewModel from '@/presentation/view-model/container/containerViewModel'
import LayoutViewModel from '@/presentation/view-model/layout/layoutViewModel'
import FlexViewModel from '@/presentation/view-model/flex/flexViewModel'
import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static Container = {
    fluid: false,
    classes: String.empty,
    fillHeight: false
  }

  static Layout = {
    classes: 'justify-center',
    alignCenter: false,
    justifyCenter: false
  }

  static Flex = {
    classes: 'xs12 sm12 md10 lg8 xl6'
  }
}

// MARK: - Class

export default class ContainerLayoutFlexFullViewModel extends ContainerLayoutFlexViewModel {
  // MARK: - Constructor

  constructor () {
    const container = new ContainerViewModel(Constants.Container.fluid,
      Constants.Container.classes,
      Constants.Container.fillHeight)

    const layout = new LayoutViewModel(Constants.Layout.classes,
      Constants.Layout.alignCenter,
      Constants.Layout.justifyCenter)

    const flex = new FlexViewModel(Constants.Flex.classes)

    super(container, layout, flex)
  }
}
