// MARK: - Constants

class Constants {
  static floating = true
  static permanent = true
  static fixed = true
  static stateless = true
  static size = 'auto'
  static backgroundColor = 'transparent'
  static barColor = 'main'
  static selectedItemColor = 'secondary'
  static classes = 'hidden-sm-and-down'
  static paddingClasses = 'pt-16 mt-3 pl-xl-10 pl-lg-4 pl-md-8'
  static listDense = true
  static listRounded = true
  static listRole = 'menu'
  static listAriaLabel = 'Menu'
  static itemSelectedClasses = 'v-navigation-item-selected'
  static itemUnselectedClasses = 'v-navigation-item-unselected'
  static itemRole = 'menuitem'
}

// MARK: - Class

export default class NavigationFloatingViewModel {
  // MARK: - Properties

  /** @type {Framework} */
  _vuetify
  /** @type {boolean} */
  _floating
  /** @type {boolean} */
  _permanent
  /** @type {boolean} */
  _fixed
  /** @type {boolean} */
  _stateless
  /** @type {string} */
  _size
  /** @type {string} */
  _backgroundColor
  /** @type {string} */
  _barColor
  /** @type {string} */
  _selectedItemColor
  /** @type {string} */
  _classes
  /** @type {string} */
  _paddingClasses
  /** @type {boolean} */
  _listDense
  /** @type {boolean} */
  _listRounded
  /** @type {string} */
  _listRole
  /** @type {string} */
  _listAriaLabel
  /** @type {string} */
  _itemSelectedClasses
  /** @type {string} */
  _itemUnselectedClasses
  /** @type {string} */
  _itemRole

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._vuetify = vuetify
    this._floating = Constants.floating
    this._permanent = Constants.permanent
    this._fixed = Constants.fixed
    this._stateless = Constants.stateless
    this._size = Constants.size
    this._backgroundColor = Constants.backgroundColor
    this._barColor = Constants.barColor
    this._selectedItemColor = Constants.selectedItemColor
    this._classes = Constants.classes
    this._paddingClasses = Constants.paddingClasses
    this._listDense = Constants.listDense
    this._listRounded = Constants.listRounded
    this._listRole = Constants.listRole
    this._listAriaLabel = Constants.listAriaLabel
    this._itemRole = Constants.itemRole
    this._itemSelectedClasses = Constants.itemSelectedClasses
    this._itemUnselectedClasses = Constants.itemUnselectedClasses
  }

  // MARK: - Getter

  /** @return {boolean} */
  get floating () {
    return this._floating
  }

  /** @return {boolean} */
  get permanent () {
    return this._permanent
  }

  /** @return {boolean} */
  get fixed () {
    return this._fixed
  }

  /** @return {boolean} */
  get stateless () {
    return this._stateless
  }

  /** @return {string} */
  get height () {
    return this._size
  }

  /** @return {string} */
  get width () {
    return this._size
  }

  /** @return {string} */
  get backgroundColor () {
    return this._backgroundColor
  }

  /** @return {string} */
  get barColor () {
    return this._barColor
  }

  /** @return {string} */
  get selectedItemColor () {
    return this._selectedItemColor
  }

  /** @return {boolean} */
  get miniVariant () {
    return this._vuetify.breakpoint.lgAndDown
  }

  /** @return {string} */
  get miniVariantWidth () {
    return this._size
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {string} */
  get paddingClasses () {
    return this._paddingClasses
  }

  /** @return {boolean} */
  get listDense () {
    return this._listDense
  }

  /** @return {boolean} */
  get listRounded () {
    return this._listRounded
  }

  /** @return {string} */
  get listRole () {
    return this._listRole
  }

  /** @return {string} */
  get listAriaLabel () {
    return this._listAriaLabel
  }

  /** @return {string} */
  get itemRole () {
    return this._itemRole
  }

  /** @return {string} */
  getDrawerItemClasses (itemRoute, appRoute) {
    return (appRoute.path.startsWith(itemRoute.path)) ? this._itemSelectedClasses : this._itemUnselectedClasses
  }
}
