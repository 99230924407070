<template>
  <custom-dialog :viewModel="presenter.viewModel">
    <custom-card :view-model="presenter.viewModel.card">

      <!-- Title -->
      <v-card-title>
        {{ presenter.viewModel.title }}
      </v-card-title>

      <!-- Content -->
      <v-card-text
        :class="presenter.viewModel.contentClasses"
        v-html="presenter.viewModel.content"
      />

      <!-- Action -->
      <v-card-actions>
        <!-- Space -->
        <custom-space :view-model="presenter.viewModel.closeTopSpace"/>

        <!-- Close button -->
        <custom-button
          :view-model="presenter.viewModel.closeButton"
          @click="controller.closeTapped()"
        />
      </v-card-actions>
    </custom-card>
  </custom-dialog>
</template>

<script>
import DialogComponent from '@/app/common/component/dialog/DialogComponent'
import CardComponent from '@/app/common/component/card/CardComponent'
import SpaceComponent from '@/app/common/component/space/SpaceComponent'
import ButtonComponent from '@/app/common/component/button/ButtonComponent'
import PresentationInjection from '@/presentation/scene/legal-notices/legalNoticesPresentationInjection'

export default {
  name: 'LegalNoticesVue',

  data: () => ({
    /** @type LegalNoticesController */
    controller: null,
    /** @type LegalNoticesPresenter */
    presenter: null
  }),

  props: {
    show: {
      type: Boolean,
      required: true
    }
  },

  components: {
    customDialog: DialogComponent,
    customCard: CardComponent,
    customButton: ButtonComponent,
    customSpace: SpaceComponent
  },

  watch: {
    show () {
      this.controller.showCalled(this.show)
    }
  },

  beforeMount () {
    const presentationInjection = new PresentationInjection(this.$vuetify)
    this.presenter = presentationInjection.presenter
    this.controller = presentationInjection.controller
  },

  destroyed () {
    // Reset data
    this.presenter = null
    this.controller = null
  }
}
</script>

<style lang="sass" scoped>
@import 'legal-notices-style'
</style>
