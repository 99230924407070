import ButtonSimpleViewModel from '@/presentation/view-model/button/buttonSimpleViewModel'
import Color from '@/presentation/utils/color'
import String from '@/presentation/utils/string'
import FontWeight from '@/presentation/utils/fontWeight'

// MARK: - Constants

class Constants {
  static defaultShow = false
  static classes = 'ma-2 mx-auto'
  static width = '95%'
  static maxWidth = '1000px'
  static rounded = 'lg'
  static color = Color.greenMiDark
  static titleColor = Color.black + String.textColorSuffix
  static buttonsColor = Color.black
  static style = 'z-index:10;'
}

// MARK: - Class

export default class BannerViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _show
  /** @type {string} */
  _classes
  /** @type {string} */
  _width
  /** @type {string} */
  _maxWidth
  /** @type {string} */
  _rounded
  /** @type {string} */
  _style
  /** @type {string} */
  _color
  /** @type {string} */
  _title
  /** @type {string} */
  _titleClasses
  /** @type {[ButtonSimpleViewModel]} */
  _buttons

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Localization} localization
   * @param {string} titleKey
   * @param {[string]} buttonsTitleKeys
   */
  constructor (localization,
    titleKey,
    buttonsTitleKeys
  ) {
    this._show = Constants.defaultShow
    this._classes = Constants.classes
    this._width = Constants.width
    this._maxWidth = Constants.maxWidth
    this._rounded = Constants.rounded
    this._style = Constants.style
    this._color = Constants.color
    this._title = localization.getText(titleKey)
    this._titleClasses = Constants.titleColor

    const buttons = []
    buttonsTitleKeys.forEach((titleKey, index, array) => {
      const isLast = (index === array.length - 1)
      const button = new ButtonSimpleViewModel(
        localization,
        titleKey,
        Constants.buttonsColor,
        isLast ? FontWeight.bold : undefined
      )
      buttons.push(button)
    })
    this._buttons = buttons
  }

  // MARK: - Getter

  /** @return {boolean} */
  get show () {
    return this._show
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {string} */
  get width () {
    return this._width
  }

  /** @return {string} */
  get maxWidth () {
    return this._maxWidth
  }

  /** @return {string} */
  get rounded () {
    return this._rounded
  }

  /** @return {string} */
  get style () {
    return this._style
  }

  /** @return {string} */
  get color () {
    return this._color
  }

  /** @return {string} */
  get title () {
    return this._title
  }

  /** @return {string} */
  get titleClasses () {
    return this._titleClasses
  }

  /** @return {[ButtonSimpleViewModel]} */
  get buttons () {
    return this._buttons
  }

  // MARK: - Setter

  /** @param {boolean} value */
  set show (value) {
    this._show = value
  }
}
