import String from '@/presentation/utils/string'

// MARK: - Constants

class Constants {
  static app = true
  static fixed = true
  static grow = true
  static color = 'main'
  static classes = 'hidden-md-and-up'
  static itemClasses = 'v-navigation-item-button'
  static itemSelectedClasses = 'v-navigation-item-selected'
  static itemUnselectedClasses = 'v-navigation-item-unselected'
}

// MARK: - Class

export default class NavigationTabbarViewModel {
  // MARK: - Properties

  /** @type {boolean} */
  _app
  /** @type {boolean} */
  _fixed
  /** @type {boolean} */
  _grow
  /** @type {string} */
  _backgroundColor
  /** @type {string} */
  _selectedColor
  /** @type {string} */
  _classes
  /** @type {string} */
  _itemClasses
  /** @type {string} */
  _itemSelectedClasses
  /** @type {string} */
  _itemUnselectedClasses

  // MARK: - Constructor

  /**
   * @constructor
   */
  constructor () {
    this._app = Constants.app
    this._fixed = Constants.fixed
    this._grow = Constants.grow
    this._backgroundColor = Constants.color
    this._selectedColor = Constants.color
    this._classes = Constants.classes
    this._itemClasses = Constants.itemClasses
    this._itemSelectedClasses = Constants.itemSelectedClasses
    this._itemUnselectedClasses = Constants.itemUnselectedClasses
  }

  // MARK: - Getter

  /** @return {boolean} */
  get app () {
    return this._app
  }

  /** @return {boolean} */
  get fixed () {
    return this._fixed
  }

  /** @return {boolean} */
  get grow () {
    return this._grow
  }

  /** @return {string} */
  get backgroundColor () {
    return this._backgroundColor
  }

  /** @return {string} */
  get selectedColor () {
    return this._selectedColor
  }

  /** @return {string} */
  get classes () {
    return this._classes
  }

  /** @return {string} */
  getBottomItemButtonClasses (itemRoute, appRoute) {
    const selectedClasses = (appRoute.path.startsWith(itemRoute.path)) ? this._itemSelectedClasses : this._itemUnselectedClasses
    return this._itemClasses + String.space + selectedClasses
  }
}
