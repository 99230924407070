export default class Secure {
  // MARK: - Properties

  /**
   * @param {string} value
   * @return {string}
   **/
  static encode (value) {
    return window.btoa(encodeURIComponent(value))
  }

  /**
   * @param {string} value encoded
   * @return {string}
   **/
  static decode (value) {
    return decodeURIComponent(window.atob(value))
  }
}
