import SubscriptionsInteractor from '@/domain/scene/subscriptions/subscriptionsInteractor'
import SubscriptionsDataRepository from '@/data/repository/subscriptions/subscriptionsDataRepository'

export default class SubscriptionsDomainInjection {
  // MARK: - Properties

  /** @type {SubscriptionsInteractor} */
  _interactor

  // MARK: - Constructor

  /**
   * @constructor
   * @param {SubscriptionsPresenter} presenter
   */
  constructor (presenter) {
    const repository = new SubscriptionsDataRepository()
    this._interactor = new SubscriptionsInteractor(repository, presenter)
  }

  // MARK: - Getter

  /**
   * Get Interactor
   * @return {SubscriptionsInteractor}
   */
  get interactor () {
    return this._interactor
  }
}
