// MARK: - Constants

class Constants {
  static target = '_blank'
  static rel = 'noreferrer'
}

// MARK: - Class

export default class TextLinkViewModel {
  // MARK: - Properties

  /** @type {string} */
  _text
  /** @type {string} */
  _textClasses
  /** @type {any} */
  _options

  // MARK: - Constructor

  /**
   * @constructor
   * @param {string} text
   * @param {string} textClasses
   */
  constructor (text, textClasses) {
    this._text = text
    this._textClasses = textClasses
    this._options = {
      attributes: {
        rel: Constants.rel
      },
      target: Constants.target,
      className: textClasses
    }
  }

  // MARK: - Getter

  /** @return {string} */
  get text () {
    return this._text
  }

  /** @return {string} */
  get textClasses () {
    return this._textClasses
  }

  /** @return {string} */
  get options () {
    return this._options
  }

  // MARK: - Setter

  /** @param {string} text */
  set text (text) {
    this._text = text
  }
}
