import DashboardDomainInjection from '@/domain/scene/dashboard/dashboardDomainInjection'
import DashboardController from '@/presentation/scene/dashboard/dashboardController'
import DashboardPresenter from '@/presentation/scene/dashboard/dashboardPresenter'

export default class DashboardPresentationInjection {
  // MARK: - Properties

  /** @type {DashboardController} */
  _controller
  /** @type {DashboardPresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   */
  constructor (vuetify) {
    this._presenter = new DashboardPresenter(vuetify)

    // Domain
    const domainInjection = new DashboardDomainInjection(this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new DashboardController(interactor)
  }

  // MARK: - Getter

  /** @return {DashboardController} */
  get controller () {
    return this._controller
  }

  /** @return {DashboardPresenter} */
  get presenter () {
    return this._presenter
  }
}
