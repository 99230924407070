import ProfileDomainInjection from '@/domain/scene/profile/profileDomainInjection'
import ProfileController from '@/presentation/scene/profile/profileController'
import ProfilePresenter from '@/presentation/scene/profile/profilePresenter'

export default class ProfilePresentationInjection {
  // MARK: - Properties

  /** @type {ProfileController} */
  _controller
  /** @type {ProfilePresenter} */
  _presenter

  // MARK: - Constructor

  /**
   * @constructor
   * @param {Framework} vuetify
   * @param {any} validation
   * @param {VueRouter} router
   */
  constructor (vuetify, validation, router) {
    this._presenter = new ProfilePresenter(vuetify, validation, router)

    // Domain
    const domainInjection = new ProfileDomainInjection(validation, this._presenter)
    const interactor = domainInjection.interactor

    this._controller = new ProfileController(interactor)
  }

  // MARK: - Getter

  /** @return {ProfileController} */
  get controller () {
    return this._controller
  }

  /** @return {ProfilePresenter} */
  get presenter () {
    return this._presenter
  }
}
